export default {

info: `
<div>
    <h1>Merge Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'CPLUSPLUS'],

PYTHON: `
def merge_sort(nums):
    
    if len(nums) < 2:
        return nums
    
    result = []
    
    middle = int(len(nums) / 2)
    left_list = merge_sort(nums[0:middle])
    right_list = merge_sort(nums[middle:])
    left_index = 0
    right_index = 0
    
    while (left_index < len(left_list) and right_index < len(right_list)):
    
        if left_list[left_index] < right_list[right_index]:
            result.append(left_list[left_index])
            left_index += 1
        else:
            result.append(right_list[right_index])
            right_index += 1
            
    result += left_list[left_index:]
    result += right_list[right_index:]
    
    return result
`,

JAVA: `

`,

CPLUSCPLUS: `
#include <iostream>
#include <cmath>

std::vector<int> MergeSort(const std::vector<int> &prices)
{

    if (prices.size() < 2)
        return prices;

    std::vector<int> result;

    int middle = std::round(prices.size() / 2);
    
    std::vector<int> leftList = MergeSort(slice(prices, 0, middle - 1));
    std::vector<int> rightList = MergeSort(slice(prices, middle, prices.size() - 1));
    
    int leftIndex = 0;
    int rightIndex = 0;

    while (leftIndex < leftList.size() && rightIndex < rightList.size())
    {
        if (leftList[leftIndex] < rightList[rightIndex])
        {
            result.push_back(leftList[leftIndex]);
            leftIndex += 1;
        }
        else
        {
            result.push_back(rightList[rightIndex]);
            rightIndex += 1;
        }
    }

    std::vector<int> leftInsert = slice(leftList, leftIndex, leftList.size() - 1);
    result.insert(result.end(), leftInsert.begin(), leftInsert.end());

    std::vector<int> rightInsert = slice(rightList, rightIndex, rightList.size() - 1);
    result.insert(result.end(), rightInsert.begin(), rightInsert.end());

    return result;
}

template<typename T>
std::vector<T> slice(std::vector<T> const& vector, int startIndex, int endIndex)
{
    auto first = vector.cbegin() + startIndex;
    auto last = vector.cbegin() + endIndex + 1;

    std::vector<T> vectorResult(first, last);
    return vectorResult;
}
`
    
}