import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-info-pane',
  templateUrl: './info-pane.component.html',
  styleUrls: ['./info-pane.component.scss']
})
export class InfoPaneComponent implements OnInit {

    @Input() task: string = '';
    @Input() group: string = '';
    @Output() deletePane: EventEmitter<any> = new EventEmitter();
    @Input() data: any;

    mode: string = 'mode';

    constructor() { }

    ngOnInit() {
    }

    onDelete(key: string) {
        this.deletePane.emit({ key });
    }

}
