export default {

info: `
<div>
    <h1>Subarray Sums Divisible by K</h1>
</div>
<div>
    <p>
        Given an array A of integers, return the number of (contiguous, non-empty) 
        subarrays that have a sum divisible by K.
    </p>
</div>
<div>
    <code>
        Input: A = [4,5,0,-2,-3,1], K = 5
        Output: 7
        Explanation: There are 7 subarrays with a sum divisible by K = 5:
        [4, 5, 0, -2, -3, 1], [5], [5, 0], [5, 0, -2, -3], [0], [0, -2, -3], [-2, -3]
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def subarray_sums_divisible_by_k(nums, k):
    
    frequency_of_remainders = [0] * (k + 1)    
    cumulative_sum = 0
    for i in range(len(A)):
        cumulative_sum += nums[i]
        remainder = cumulative_sum % k
        frequency_of_remainders[remainder] += 1

    result = 0

    for i in range(k):
        remainder = frequency_of_remainders[i]
        if remainder > 1:
            result += ((remainder * (remainder - 1)) // 2)

    result += frequency_of_remainders[0]

    return result
`,

JAVA: `

`,
    
}