export default {

info: `
<div>
    <h1>Split Array Largest Sum</h1>
</div>
<div>
    <p>
        Given an array which consists of non-negative integers 
        and an integer m, you can split the array into m non-empty 
        continuous subarrays. Write an algorithm to minimize 
        the largest sum among these m subarrays.
    </p>
</div>
<div>
    <code>
        Input:
        
        nums = [7,2,5,10,8]
        m = 2
        
        Output:
        18
        
        Explanation:

        There are four ways to split nums into two subarrays.
        The best way is to split it into [7,2,5] and [10,8],
        where the largest sum among the two subarrays is only 18. 
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def split_array(nums, split_goal):
    start = max(nums)
    end = sum(nums)
    result = -1
    while start <= end:
        middle = start + (end - start) // 2
        if is_valid(nums, split_goal, middle):
            result = middle
            end = middle - 1 
        else:
            start = middle + 1
    return result

def is_valid(nums, split_goal, middle):
    cuts = 0
    curr_sum = 0
    for x in nums:
        curr_sum += x
        if curr_sum > middle:
            cuts += 1
            curr_sum = x
    subs = cuts + 1
    return (subs <= split_goal)
`,

JAVA: `

`,
    
}