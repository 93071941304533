export default {

info: `
<div>
    <h1>Zero Sum</h1>
</div>
<div>
    <p>
        Given an array, write a function to find any subarray that sums to zero,
        if one exists.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def zero_sum(arr):
    
    results = []
    cache = {}
    
    sum_so_far = 0
    for i in range(len(arr)):
        sum_so_far += arr[i]
        
        cache[i] = sum_so_far
        if sum_so_far in cache:
            results.append(list(arr[cache[i]:i + 1]))
            
    return results
`,

JAVA: `

`,
    
}