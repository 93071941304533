import a_star from './a_star';
import bellman_ford from './bellman_ford';
import binary_search from './binary_search';
import boruvkas_mst from './boruvkas_mst';
import bubble_sort from './bubble_sort';
import bucket_sort from './bucket_sort';
import counting_sort from './counting_sort';
import dfs_topological_sort from './dfs_topological_sort';
import dijkstras from './dijkstras';
import dinics from './dinics';
import floyd_warshall from './floyd_warshall';
import heap_sort from './heap_sort';
import insertion_sort from './insertion_sort';
import kadanes from './kadanes';
import khans from './khans';
import knuth_morris_pratt from './knuth_morris_pratt';
import kruskals_mst from './kruskals_mst';
import merge_sort from './merge_sort';
import prims_mst from './prims_mst';
import quick_sort from './quick_sort';
import radix_sort from './radix_sort';
import selection_sort from './selection_sort';
import shell_sort from './shell_sort';
import sm2 from './sm2';
import tarjans_sccs from './tarjans_sccs';
import tim_sort from './tim_sort';
import tree_sort from './tree_sort';

export default {
    a_star,
    bellman_ford,
    binary_search,
    boruvkas_mst,
    bubble_sort,
    bucket_sort,
    counting_sort,
    dfs_topological_sort,
    dijkstras,
    dinics,
    floyd_warshall,
    heap_sort,
    insertion_sort,
    kadanes,
    khans,
    knuth_morris_pratt,
    kruskals_mst,
    merge_sort,
    prims_mst,
    quick_sort,
    radix_sort,
    selection_sort,
    shell_sort,
    sm2,
    tarjans_sccs,
    tim_sort,
    tree_sort
}