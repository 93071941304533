export default {

info: `
<div>
    <h1>Shell Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def shell_sort(arr): 
  
    arr_len = len(arr) 
    gap = arr_len // 2
  
    while gap > 0: 
  
        for i in range(gap, arr_len): 
  
            temp = arr[i] 
  
            j = i 
            while  j >= gap and arr[j-gap] > temp:
                
                arr[j] = arr[j-gap] 
                j -= gap 
  
            arr[j] = temp 
        
        gap //= 2
        
    return arr
`,

JAVA: `

`,
    
}