export default {

info: `
<div>
    <h1>Generate All Valid IP Addresses from String</h1>
</div>
<div>
    <p>
        You are given a raw IP address string without period 
        separators between the digits. Return all valid IP addresses 
        that can be created from the raw IP string where it is your 
        job to place the periods.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def generate_ips(string):
    results = []
    segments = []
    generate_ips_recur(string, results, segments)
    
    return results

def generate_ips_recur(string, results, segments, prev_pos = -1, dots = 3):

    for curr_pos in range(prev_pos + 1, min(len(string) - 1, prev_pos + 4)):

        segment = string[prev_pos + 1:curr_pos + 1]

        if is_valid(segment):
            segments.append(segment)

            if dots - 1 == 0:
                segment = string[curr_pos + 1:len(string)]
                if is_valid(segment):
                    segments.append(segment)
                    results.append('.'.join(segments))
                    segments.pop()
            else:
                generate_ips_recur(string, results, segments, curr_pos, dots - 1)
                
            segments.pop()
        
def is_valid(segment):
    return int(segment) <= 255 if segment[0] != '0' else len(segment) == 1 
`,

JAVA: `

`,
    
}