export default {

info: `
<div>
    <h1>Search in Rotated Sorted Array</h1>
</div>
<div>
    <p>Suppose an array sorted in ascending order is rotated at some pivot unknown to you beforehand.</p>
    <p>(i.e., [0,1,2,4,5,6,7] might become [4,5,6,7,0,1,2]).</p>
    <p>You are given a target value to search. If found in the array return its index, otherwise return -1.</p>
    <p>You may assume no duplicate exists in the array.</p>
    <p>Your algorithm's runtime complexity must be in the order of O(log n).</p>
    <p>Input: nums = [4,5,6,7,0,1,2], target = 0</p>
    <p>Output: 4</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'GO', 'SCALA'],

PYTHON: `
def search_rotated_sorted_array(nums, target):
    
    nums_len = len(nums)
        
    if nums_len == 0:
        return -1
    if nums_len == 1:
        return 0 if nums[0] == target else -1 

    rotate_index = find_rotation_index(nums, 0, nums_len - 1)
    
    if nums[rotate_index] == target:
        return rotate_index
    
    if rotate_index == 0:
        return binary_search(nums, 0, nums_len - 1, target)

    if target < nums[0]:
        return binary_search(nums, rotate_index, nums_len - 1, target)
    else:
        return binary_search(nums, 0, rotate_index, target)
    

def find_rotation_index(nums, start, end):
    if nums[start] < nums[end]:
        return 0

    while start <= end:
        middle = start + (end - start) // 2
        if nums[middle] > nums[middle + 1]:
            return middle + 1
        else:
            if nums[middle] < nums[start]:
                end = middle - 1
            else:
                start = middle + 1

def binary_search(nums, start, end, target):
    
    while start <= end:
        middle = start + (end - start) // 2
        if nums[middle] == target:
            return middle
        else:
            if target < nums[middle]:
                end = middle - 1
            else:
                start = middle + 1
    return -1
`,

CPLUSPLUS: `
#include <vector>

class Solution {
public:
    int search(std::vector<int>& nums, int target) {
        
        int numsLen = nums.size();
        
        if (numsLen == 0)
            return -1;
        
        if (numsLen == 1)
        {
            if (nums[0] == target)
                return 0;
            else
                return -1;
        }
        
        int rotateIndex = findRotationIndex(nums, 0, numsLen - 1);
        
        if (nums[rotateIndex] == target)
            return rotateIndex;
        
        if (rotateIndex == 0)
            return binarySearch(nums, 0, numsLen - 1, target);
        
        if (target < nums[0])
        {
            return binarySearch(nums, rotateIndex, numsLen - 1, target);
        }
        else
        {
            return binarySearch(nums, 0, rotateIndex, target);
        }
        
        return 0;
    }
    
    int findRotationIndex(std::vector<int>& nums, int start, int end)
    {
        if (nums[start] < nums[end])
            return 0;
        
        while (start <= end)
        {
            int middle = start + (end - start) / 2;
            
            if (nums[middle] > nums[middle + 1])
            {
                return middle + 1;
            }
            else
            {
                if (nums[middle] < nums[start])
                {
                    end = middle - 1;
                }
                else
                {
                    start = middle + 1;
                }
            }
        }
        
        return -1;
    }
    
    int binarySearch(std::vector<int>& nums, int start, int end, int target)
    {
        while (start <= end)
        {
            int middle = start + (end - start) / 2;
            
            if (nums[middle] == target)
            {
                return middle;
            }
            else
            {
                if (nums[middle] > target)
                {
                    end = middle - 1;
                }
                else
                {
                    start = middle + 1;
                }
            }
        }
        
        return - 1;
    }
};
`,

GO: `
func search(nums []int, target int) int {
    
    start := 0
    end := len(nums) - 1
    
    for start <= end {
        
        mid := start + (end - start) / 2
        
        if nums[mid] == target {
            return mid
        } else if nums[mid] >= nums[start] {
            if target >= nums[start] && target < nums[mid] {
                end = mid - 1
            } else {
                start = mid + 1
            }
        } else {
            if target <= nums[end] && target > nums[mid] {
                start = mid + 1
            } else {
                end = mid - 1
            }
        }
    }
    
    return -1
}
`,

SCALA: `
object Solution {
    def search(nums: Array[Int], target: Int): Int = {
        
        var start = 0
        var end = nums.size - 1
        
        while (start <= end) {
            
            var mid = start + (end - start) / 2
            
            if (nums(mid) == target) {
                return mid
            } else if (nums(mid) >= nums(start)) {
                if (target >= nums(start) && target < nums(mid)) {
                    end = mid - 1
                } else {
                    start = mid + 1
                }
            } else {
                if (target <= nums(end) && target > nums(mid)) {
                    start = mid + 1
                } else {
                    end = mid - 1
                }
            }
        }
        
        return -1
    }
}
`
    
}