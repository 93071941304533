export default {

info: `
<div>
    <h1>Move Zeroes</h1>
</div>
<div>
    <p>
        Given an array nums, write a function to move all 0's 
        to the end of it while maintaining the relative order 
        of the non-zero elements.
    </p>
</div>
<div>
    <code>
        Input: [0,1,0,3,12]
        Output: [1,3,12,0,0]
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def move_zeroes(nums):
       
    index = 0
    
    for i in range(len(nums)):
        
        if nums[i] != 0:
            nums[index] = nums[i]
            index += 1
            
    for i in range(index, len(nums)):
        nums[i] = 0
`,

JAVA: `

`,
    
}