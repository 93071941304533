export default {

info: `
<div>
    <h1>Clone A Linked List</h1>
</div>
<div>
    <p>Clone a linked list where each node may have a random pointer to another node</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Key to this problem is creating sometype of hashmap data structure
            that allows quick lookup. Cloning a node is self explantorary but we
            need a way to re-create the relationships. We will be able to reference
            any source or clone using this data structure. The key's are the original
            nodes and the values will be the cloned versions.
        `,
    },
    {
        key: '2',
        description: `
            We will populate the data structure created above by iterating through
            the linked list and inserting the original node as a key and a cloned
            node as the value.
        `,
    },
    {
        key: '3',
        description: `
            Now we will make the important connections. Start iterating through
            the original linked list.
        `,
    },
    {
        key: '4',
        description: `
            If this current original node has a random pointer to another node,
            we need to find the clone of that random node. Thats where the
            our hash map data structure comes in that holds a reference to
            the source nodes and their clones. Not how I am using the information
            from the current node to access the cloned nodes.
        `,
    },
    {
        key: '5',
        description: `
            Then we go through the same process for the next pointer in each node.
        `,
    },
    {
        key: '6',
        description: `
            Then we just return out the cloned linked list head.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6,7,8,9],
        '3': [11,12,17],
        '4': [13,14],
        '5': [15,16],
        '6': [19]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def clone_linked_list(head):
    
    source_to_clone = {}
    
    current_node = head
    while current_node:
        source_to_clone[current_node] = Node(current_node.value)
        current_node = current_node.next
        
    current_node = head
    while current_node:
        if current_node.random:
            source_to_clone[current_node].random = source_to_clone[current_node.random]
        if current_node.next:
            source_to_clone[current_node].next = source_to_clone[current_node.next]
        current_node = current_node.next
        
    return source_to_clone[head]
`,

JAVA: `

`,
    
}