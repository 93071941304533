<div class="CardList">
    <ul>
        <li *ngFor="let task of taskList">
            <a [href]="'/' + group + '/' + task.url">
                {{ task.title }}
                <div class="CardList__icons">
                    <app-icon *ngFor="let lang of task.langs" [lang]="lang"></app-icon>
                </div>
            </a>
        </li>
    </ul>    
</div>
