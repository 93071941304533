export default {

info: `
<div>
    <h1>Chain of Responsibility Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc

class Handler(metaclass=abc.ABCMeta):

    def __init__(self, successor=None):
        self.successor = successor

    @abc.abstractmethod
    def handle_request(self, request_type):
        pass


class ConcreteHandlerOne(Handler):

    request_manifest = {}

    def handle_request(self, request_type):
        if request_type in request_manifest:
            pass
        elif self.successor is not None:
            self.successor.handle_request()


class ConcreteHandlerTwo(Handler):

    request_manifest = {}

    def handle_request(self, request_type):
        if request_type in request_manifest:
            pass
        elif self.successor is not None:
            self.successor.handle_request()


def main():
    concrete_handler_one = ConcreteHandlerOne()
    concrete_handler_two = ConcreteHandlerTwo(concrete_handler_one)
    concrete_handler_two.handle_request('request_type')
`,

JAVA: `

`,
    
}