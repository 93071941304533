export default {

info: `
<div>
    <h1>Floyd-Warshall Shortest Paths Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def floyd_warshall(adjacency_matrix):
    
    node_count = len(adjacency_matrix)
    
    dp = map(lambda row: map(lambda col: col, row), adjacency_matrix)
    
    for k in range(node_count):
        for from_node in range(node_count):
            for to_node in range(node_count):
                
                if dp[from_node][k] + dp[k][to_node] < dp[from_node][to_node]:    
                    dp[from_node][to_node] = dp[from_node][k] + dp[k][to_node]
                
                
                
    for k in range(node_count):
        for from_node in range(node_count):
            for to_node in range(node_count):
                
                if dp[from_node][k] + dp[k][to_node] < dp[from_node][to_node]: 
                    dp[from_node][to_node] = float('-inf')
                    
    return dp
`,

JAVA: `

`,
    
}