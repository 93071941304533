export default {

info: `
<div>
    <h1>Sort a sequence of objects</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['CPLUSPLUS', 'SCALA'],

CPLUSPLUS: `
#include <algorithm>
#include <vector>
#include <string>

struct Data
{
    std::string name;
    int amount;
    
    Data(std::string nameUpdate, int amountUpdate)
        : name(nameUpdate), amount(amountUpdate) {}
};

int main()
{
    std::vector<Data> sample{ Data("a", 2), Data("b", 1) };

    std::sort(sample.begin(), sample.end(), [](Data a, Data b){
        return a.amount < b.amount;	
    });
}
`,

SCALA: `
class Data(var name: String, var amount: Int) {}

object Sample {

    def main(args: Array[String]) {
        var sample: List[Data] = List(new Data("a", 2), new Data("b", 1))

        sample = sample sortBy (_.amount)
    }
}
`
    
}