export default {

info: `
<div>
    <h1>Find All Anagrams in a String</h1>
</div>
<div>
    <p>
        Given two strings s and p, return an array of all the start 
        indices of p's anagrams in s. You may return the answer in any order.
    </p>
    <p>
        An Anagram is a word or phrase formed by rearranging the letters of 
        a different word or phrase, typically using all the original letters exactly once.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['CPLUSPLUS'],

CPLUSPLUS: `
#include <unordered_map>
#include <vector>
#include <string>

class Solution {
public:
    std::vector<int> findAnagrams(std::string s, std::string p) {
        
        std::vector<int> result;
        
        int sLength = s.size();
        int pLength = p.size();
        
        if (sLength < pLength)
            return result;
        
        std::unordered_map<char, int> pCount;
        std::unordered_map<char, int> sCount;
        
        for (auto& ch : p)
        {
            if (pCount.find(ch) != pCount.end())
            {
                pCount[ch]++;
            }
            else
            {
                pCount[ch] = 1;
            }
        }
        
        for (std::pair<char, int> element : pCount)
        {
            std::cout << element.first << " :: " << element.second << std::endl;
        }
        
        for (int i = 0; i < sLength; i++)
        {
                        
            if (sCount.find(s[i]) != pCount.end())
            {
                sCount[s[i]]++;
            }
            else
            {
                sCount[s[i]] = 1;
            }
            
            if (i >= pLength)
            {
                if (sCount[s[i - pLength]] == 1)
                {
                    sCount.erase(s[i - pLength]);
                }
                else
                {
                    sCount[s[i - pLength]]--;
                }
            }
            
            if (pCount  == sCount)
                result.push_back(i - pLength + 1);
            
        }
        
        return result;
    }
};
`,

JAVA: `

`,
    
}