export default {

info: `
<div>
    <h1>Shortest Distance from All Buildings</h1>
</div>
<div>
    <p>
        You want to build a house on an empty land which reaches all 
        buildings in the shortest amount of distance. You can only move up, 
        down, left and right. You are given a 2D grid of values 0, 1 or 2, where: 
    </p>
    <p>Each 0 marks an empty land which you can pass by freely.</p>
    <p>Each 1 marks a building which you cannot pass through.</p>
    <p>Each 2 marks an obstacle which you cannot pass through.</p>
</div>
<div>
    <p>Input: [[1,0,2,0,1],[0,0,0,0,0],[0,0,1,0,0]]</p>
    <p>Output: 7</p>
    <p>
        Explanation: Given three buildings at (0,0), (0,4), (2,2), and an obstacle at (0,2),
        the point (1,2) is an ideal empty land to build a house, as the total 
        travel distance of 3+3+1=7 is minimal. So return 7.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def shortest_distance(land):
    
    row_len = len(land)
    col_len = len(land[0])
    bldg_total_count = sum(val for line in land for val in line if val == 1)
    
    empty_hits = [[0] * col_len for row in range(row_len)]
    target_hits =  [[0] * col_len for row in range(row_len)]
    
    for row in range(row_len):
        for col in range(col_len):
            if land[row][col] == 1:
                if not bfs_util(land, empty_hits, target_hits, bldg_total_count, row, col):
                    return -1
                
    possible_results = []
    for row in range(row_len):
        for col in range(col_len):
            if land[row][col] == 0 and empty_hits[row][col] == bldg_total_count:
                possible_results.append(target_hits[row][col])
                
    return min(possible_results) if possible_results else - 1    
    
def bfs_util(land, empty_hits, target_hits, bldg_total_count, start_row, start_col):
    
    visited = [[False] * len(land[0]) for k in range(len(land))]
    visited[start_row][start_col] = True
    bldg_progress_count = 1
    queue = [(start_row, start_col, 0)]
    
    while queue:
        prev_row, prev_col, dist = queue.pop()
        
        movements = [
            (prev_row + 1, prev_col), 
            (prev_row - 1, prev_col), 
            (prev_row, prev_col + 1), 
            (prev_row, prev_col - 1)
        ]
        
        for row, col in movements:
            if valid_exploration(land, visited, row, col):
                visited[row][col] = True
                if not land[row][col]:
                    queue.append((row, col, dist + 1))
                    empty_hits[row][col] += 1
                    target_hits[row][col] += dist + 1
                elif land[row][col] == 1:
                    bldg_progress_count += 1
                    
    return bldg_progress_count == bldg_total_count

def valid_exploration(land, visited, row, col):
    if row < 0 or row >= len(land):
        return False

    if col < 0 or col >= len(land[0]):
        return False

    if visited[row][col]:
        return False

    return True
`,

JAVA: `

`,
    
}