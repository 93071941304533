export default {

info: `
<div>
    <h1>Remove Invalid Parantheses</h1>
</div>
<div>
    <p>
        Remove the minimum number of invalid parentheses in order 
        to make the input string valid. Return all possible results.
    </p>
    <p>
        Note: The input string may contain letters other than the parentheses ( and ).  
    </p>
</div>
<div>
    <p>Input: "()())()"</p>
    <p>Output: ["()()()", "(())()"]</p>
</div>
<div>
    <p>Input: "(a)())()"</p>
    <p>Output: ["(a)()()", "(a())()"]</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def remove_invalid_parentheses(invalid_string):
    
    left = 0
    right = 0
    
    for char in invalid_string:
        
        if char == '(':
            left += 1
        elif char == ')':
            if left == 0:
                right += 1
            if left > 0:
                left -= 1
                
    starting_index = 0
    left_count = 0
    right_count = 0
    result = {}
                
    remove_util(invalid_string, starting_index, 
                left_count, right_count, left, right, [], result)
    
    return list(result.keys())
                
def remove_util(invalid_string, index, left_count, 
                right_count, left_rem, right_rem, expr, result):
    
    if index == len(invalid_string):
        if left_rem == 0 and right_rem == 0:
            ans = "".join(expr)
            result[ans] = 1
    else:
        
        if (
            (invalid_string[index] == '(' and left_rem > 0) or
            (invalid_string[index] == ')' and right_rem > 0)
        ):
            remove_util(
                invalid_string, index + 1, 
                left_count,
                right_count, 
                left_rem - (invalid_string[index] == '('), 
                right_rem - (invalid_string[index] == ')'), 
                expr, 
                result
            )
            
        expr.append(invalid_string[index])
        
        # Simply recurse one step further if the current character is not a parenthesis.
        if invalid_string[index] != '(' and invalid_string[index] != ')':
            remove_util(
                invalid_string, 
                index + 1,
                left_count,
                right_count,
                left_rem,
                right_rem, 
                expr,
                result
            )
        elif invalid_string[index] == '(':
        # Consider an opening bracket.
            remove_util(
                invalid_string, 
                index + 1,
                left_count + 1,
                right_count,
                left_rem,
                right_rem, 
                expr, 
                result
            )
        elif invalid_string[index] == ')' and left_count > right_count:
        # Consider a closing bracket.
            remove_util(
                invalid_string, 
                index + 1,
                left_count,
                right_count + 1,
                left_rem,
                right_rem, 
                expr, 
                result
            )
            
        expr.pop()
`,

JAVA: `

`,
    
}