export default {

info: `
<div>
    <h1>Boruvka's Minimum Spanning Tree Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def boruvkas_mst(graph):
    
    cheapest = {}
    mst_edges = []
    
    num_trees = len(graph)
    
    edge_list = []
    for from_node in graph:
        for to_node in graph[from_node]:
            edge_list.append((graph[from_node][to_node], from_node, to_node))
            
    union_find = UnionFind(graph)
    
    for node in graph:
        cheapest[node] = -1
        
    while num_trees > 1:
        
        for edge in edge_list:
            
            edge_cost, from_node, to_node = edge
            
            set_1 = union_find.find(from_node) 
            set_2 = union_find.find(to_node)
            
            if set_1 != set_2:
                      
                if cheapest[set_1] == -1 or cheapest[set_1][2] > edge_cost : 
                    cheapest[set_1] = (from_node, to_node, edge_cost)

                if cheapest[set_2] == -1 or cheapest[set_2][2] > edge_cost: 
                    cheapest[set_2] = (from_node, to_node, edge_cost)
                    
        for from_node in graph:
            
            if cheapest[from_node] != -1:
                from_node, to_node, edge_cost = cheapest[from_node]
                set_1 = union_find.find(from_node) 
                set_2 = union_find.find(to_node)
                
                if set_1 != set_2:
                    union_find.union(set_1, set_2)
                    mst_edges.append((from_node, to_node, edge_cost))
                    num_trees -= 1
  
            
        cheapest = {}
        for from_node in graph:
            cheapest[from_node] = -1
            
    return mst_edges

    
class UnionFind:
    
    def __init__(self, items):
        self.address = {}
        self.size = {}
        
        for i, item in enumerate(items):
            self.address[item] = item
            self.size[item] = 1
            
    def find(self, item):
        
        root = item
        
        while root != self.address[root]:
            root = self.address[root]
            
        while item != root:
            next_address = self.address[item]
            self.address[item] = root
            item = next_address
            
        return root
    
    def union(self, item_a, item_b):
        root_a = self.find(item_a)
        root_b = self.find(item_b)
        
        if root_a == root_b:
            return
        
        if self.size[root_a] < self.size[root_b]:
            self.size[root_b] += self.size[root_a]
            self.address[root_a] = root_b
        else:
            self.size[root_a] += self.size[root_b]
            self.address[root_b] = root_a
            
    def connected(self, item_a, item_b):
        return self.find(item_a) == self.find(item_b)
    
    def get_size(self, item):
        return self.size[item]
`,

JAVA: `

`,
    
}