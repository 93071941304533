export default {

info: `
<div>
    <h1>Trapping Rain Water</h1>
</div>
<div>
    <p>
        Given n non-negative integers representing an elevation map 
        where the width of each bar is 1, compute how much water it 
        is able to trap after raining.
    </p>
    <p>Input: [0,1,0,2,1,0,1,3,2,1,2,1]</p>
    <p>Output: 6</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def trapping_rain_water(heights):
    left = 0
    right = len(heights) - 1
    left_wall = 0
    right_wall = 0
    water = 0
    
    while left < right:
        if heights[left] < heights[right]:
            if heights[left] > left_wall:
                left_wall = heights[left]
            else:
                water += left_wall - heights[left]
            left += 1
        else:
            if heights[right] > right_wall:
                right_wall = heights[right]
            else:
                water += right_wall - heights[right]
            right -= 1
    
    return water
`,

JAVA: `

`,
    
}