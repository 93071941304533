export default {

info: `
<div>
    <h1>Union Find / Disjoint Set / Merge Find Set</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class UnionFind:
    
    def __init__(self, items):
        self.address = {}
        self.size = {}
        
        for i, item in enumerate(items):
            self.address[item] = item
            self.size[item] = 1
            
    def find(self, item):
        
        root = item
        
        while root != self.address[root]:
            root = self.address[root]
            
        while item != root:
            next_address = self.address[item]
            self.address[item] = root
            item = next_address
            
        return root
    
    def union(self, item_a, item_b):
        root_a = self.find(item_a)
        root_b = self.find(item_b)
        
        if root_a == root_b:
            return
        
        if self.size[root_a] < self.size[root_b]:
            self.size[root_b] += self.size[root_a]
            self.address[root_a] = root_b
        else:
            self.size[root_a] += self.size[root_b]
            self.address[root_b] = root_a
            
    def connected(self, item_a, item_b):
        return self.find(item_a) == self.find(item_b)
    
    def get_size(self, item):
        return self.size[item]
`,

JAVA: `

`,
    
}