export default {

info: `
<div>
    <h1>Factory Method Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
from abc import ABC, abstractmethod

class AbstractCreator(ABC):

    @abstractmethod
    def factory_method(self):
        pass

    def some_operation(self):
        factory_product = self.factory_method()

        factory_product.operation_dependent_on_product()

        return factory_product

class Creator:

    def factory_method(self):
        return FactoryProduct()

class FactoryProduct:

    def operation_dependent_on_product(self):
        pass
`,

JAVA: `

`,
    
}