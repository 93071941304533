export default {

info: `
<div>
    <h1>All Nodes Distance K in BST</h1>
</div>
<div>
    <p>
        We are given a binary tree (with root node root), a 
        target node, and an integer value K.
    </p>
    <p>
        Return a list of the values of all nodes that have a 
        distance K from the target node.  The answer can be returned in any order.
    </p>
</div>
<div>
    <code>
        Input: root = [3,5,1,6,2,0,8,null,null,7,4], target = 5, K = 2

        Output: [7,4,1]
        
        Explanation: 
        The nodes that are a distance 2 from the target node (with value 5)
        have values 7, 4, and 1.
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Because binary search trees are essentially directed graphs, we need a
            quick way to reference a child node's parent node. Quick access has us
            thinking some type of hashmap or object that will hold a child node as
            the key and a reference to the parent as its value.
        `,
    },
    {
        key: '2',
        description: `
            We will throw in the hashmap / object above into a utility function
            that recursively goes down the tree starting at the root. The utility
            function inserts each node into the hashmap / object and makes the node's
            parent the value.
        `,
    },
    {
        key: '3',
        description: `
            Because we are going down layer by layer until we match distance k, this search
            takes the form of a breadth first search. That means we need to make a queue and
            make a start point the initial value.
        `,
    },
    {
        key: '4',
        description: `
            To make sure we do not go backwards when we are searching, we will also create
            a hashmap / object to keep track of our progress and make sure we dont do 
            repeat work. We will also put our starting point in here.
        `,
    },
    {
        key: '5',
        description: `
            We will create a variable to keep track of which layer we have reached so far.
            When it matches our target distance, we have our answer.
        `,
    },
    {
        key: '6',
        description: `
            Like all breadth first searches, we continue doing stuff while there are items
            in our queue.
        `,
    },
    {
        key: '7',
        description: `
            We will start off by checking to see if the current layer equals the target distance,
            if so, we will send it to another until function that formats our answer for us.
            It iteratues through the nodes and extracts the node value into another list
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6,44,45,46,47,48,49,50,51,52],
        '3': [8,9],
        '4': [11,12],
        '5': [14],
        '6': [16],
        '7': [18,19,54,55,56,57,58],
        '8': []
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def distance_k(root, start_node, target_distance):
    
    node_to_parent_map = {}
    
    populate_node_to_parent_map(node_to_parent_map, root, None)
    
    queue = []
    queue.append(start_node)
    
    seen = {}
    seen[start_node] = True
    
    current_layer = 0
    
    while len(queue) > 0:
        
        if current_layer == target_distance:
            return extract_layer_from_queue(queue)
        
        layer_size = len(queue)
        
        for i in range(layer_size):
            
            current_node = queue.pop(0)
            
            if current_node.left is not None and current_node.left not in seen:
                seen[current_node.left] = True
                queue.append(current_node.left)
                
            if current_node.right != None and current_node.right not in seen:
                seen[current_node.right] = True
                queue.append(current_node.right)
                
            parent_of_current_node = node_to_parent_map[current_node]
            if parent_of_current_node != None and parent_of_current_node not in seen:
                seen[parent_of_current_node] = True
                queue.append(parent_of_current_node)
         
        current_layer += 1
    
    return []
    
def populate_node_to_parent_map(node_to_parent_map, root, parent):
    
    if root is None:
        return
    
    node_to_parent_map[root] = parent
    
    populate_node_to_parent_map(node_to_parent_map, root.left, root)
    populate_node_to_parent_map(node_to_parent_map, root.right, root)
    
def extract_layer_from_queue(queue):
    extracted_list = []
    for node in queue:
        extracted_list.append(node.value)
    return extracted_list
`,

JAVA: `

`,
    
}