export default {

info: `
<div>
    <h1>Letter Combinations</h1>
</div>
<div>
    <p>
    Each digit, apart from 0 and 1, in a phone keypad corresponds 
    to one of three or four letters of the alphabet. Write a program 
    which takes asinput a phone number (specified as a string of digits)
    and returns all possible character sequences that correspond to 
    the phone number.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
PHONE = {
    "2": ['a', 'b', 'c'],
    "3": ['d', 'e', 'f'],
    "4": ['g', 'h', 'i'],
    "5": ['j', 'k', 'l'],
    "6": ['m', 'n', 'o'],
    "7": ['p', 'q', 'r', 's'],
    "8": ['t', 'u', 'v'],
    "9": ['w', 'x', 'y', 'z'],
}

def letter_combinations(digits):
    
    results = []
    letter_combinations_recur(results, "", digits)

    return results

def letter_combinations_recur(results, progress, remaining_digits):
    if len(remaining_digits) == 0:
        results.append(progress)
    else:
        for letter in PHONE[remaining_digits[0]]:
            letter_combinations_recur(results,progress + letter, remaining_digits[1:])
`,

JAVA: `

`,
    
}