export default {

info: `
<div>
    <h1>Iterator Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import collections.abc


class ConcreteAggregate(collections.abc.Iterable):

    def __init__(self):
        self._data = None

    def __iter__(self):
        return ConcreteIterator(self)


class ConcreteIterator(collections.abc.Iterator):

    def __init__(self, concrete_aggregate):
        self.concrete_aggregate = concrete_aggregate

    def __next__(self):
        if True:  # if no_elements_to_traverse:
            raise StopIteration
        return None  # return element
`,

JAVA: `

`,
    
}