export default {

info: `
<div>
    <h1>Skyline</h1>
</div>
<div>
    <p>
        You are given a set of n rectangles in no particular order. 
        They have varying widths and heights, but their bottom edges 
        are collinear, so that they look like buildings on a skyline. 
        For each rectangle, you’re given the x position of the left edge, 
        the x position of the right edge, and the height. Your task is to 
        draw an outline around the set of rectangles so that you can see 
        what the skyline would look like when silhouetted at night.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class BuildingPoint(object):

    def __init__(self, point, is_start, height):
        self.point = point;
        self.is_start = is_start
        self.height = height
        
    def __lt__(self, other):
        if self.point != other.point:
            return self.point < other.point
        else:
            if self.is_start:
                h1 = -self.height
            else:
                h1 = self.height

            if other.is_start:
                h2 = -other.height;
            else:
                h2 = other.height

            return h1 < h2
    
def get_skyline(buildings):

    building_points = []
    for building in buildings:
        building_points.append(BuildingPoint(building[0], True, building[2]))
        building_points.append(BuildingPoint(building[1], False, building[2]))

    building_points = sorted(building_points)

    queue = {}
    queue[0] = 1
    prev_max_height = 0
    result = []
    for building_point in building_points:
        if building_point.is_start:
            if building_point.height in queue:
                queue[building_point.height] = queue[building_point.height] + 1
            else:
                queue[building_point.height] = 1
    
        else:
            if queue[building_point.height] == 1:
                del queue[building_point.height]
            else:
                queue[building_point.height] = queue[building_point.height] - 1

        current_max_height = max(queue.keys())

        if prev_max_height != current_max_height:
            result.append([building_point.point, current_max_height])
            prev_max_height = current_max_height
    return result
`,

JAVA: `

`,
    
}