export default {

info: `
<div>
    <h1>Monotonic Array</h1>
</div>
<div>
    <p>An array is monotonic if it is either monotone increasing or monotone decreasing.</p>
    <p>
        An array A is monotone increasing if for all i <= j, A[i] <= A[j].  
        An array A is monotone decreasing if for all i <= j, A[i] >= A[j].
    </p>
    <p>
        Return true if and only if the given array A is monotonic.
    </p>
</div>
<div>
    <code>
        Input: [1,2,2,3]
        Output: true
    </code>
</div>
<div>
    <code>
        Input: [1,3,2]
        Output: false
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def is_monotonic(A):
    is_increasing = True
    is_decreasing = True

    for i in range(len(A) - 1):
        if A[i] > A[i + 1]:
            is_increasing = False
        if A[i] < A[i + 1]:
            is_decreasing = False

    return (is_increasing or is_decreasing)
`,

JAVA: `

`,
    
}