export default {

info: `
<div>
    <h1>Adapter Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class Target(metaclass=abc.ABCMeta):

    def __init__(self):
        self.adaptee = Adaptee()

    @abc.abstractmethod
    def request(self):
        pass


class Adapter(Target):

    def request(self):
        self.adaptee.specific_request()


class Adaptee:

    def specific_request(self):
        pass


def main():
    adapter = Adapter()
    adapter.request()
`,

JAVA: `

`,
    
}