export default {

info: `
<div>
    <h1>Container With Most Water</h1>
</div>
<div>
    <p>
        Given n non-negative integers a1, a2, ..., an , where each 
        represents a point at coordinate (i, ai). n vertical lines 
        are drawn such that the two endpoints of line i is at (i, ai)
        and (i, 0). Find two lines, which together with x-axis forms a 
        container, such that the container contains the most water.
    </p>
    <p>Note: You may not slant the container and n is at least 2.</p>
</div>
<div>
    <code>
        Input: [1,8,6,2,5,4,8,3,7]
        Output: 49
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Overall, we will start a pointer on each side of the area and
            start to close in the pointers taking in the max area at each
            index of our two pointers. To start off, lets create the two
            pointers; one that starts on the left side and one that starts
            on the right side of the input.
        `,
    },
    {
        key: '2',
        description: `
            Like a lot of problems where we are looking for some type of max, we
            initialize a max variable to be the lowest number possible. This way,
            we know that the first number compared to this will for sure
            beat it.
        `,
    },
    {
        key: '3',
        description: `
            Start a loop that continues while our left pointer is less than the right
            pointer.
        `,
    },
    {
        key: '4',
        description: `
            Our height is restricted by the lowest height so take the minimum between
            the height at the left pointer and the height at the right pointer.
        `,
    },
    {
        key: '5',
        description: `
            To get our width, just subtract our right pointer from our left.
        `,
    },
    {
        key: '6',
        description: `
            Then just update our current max by taking the max of the current max
            and the current area calculation using the height and with we just
            calculated.
        `,
    },
    {
        key: '7',
        description: `
            We will adjust our window based on which side was smaller. If the left
            side was smaller, we can forget about it so close the left side by
            incrementing the left pointer. If the right side was smaller, close
            the right side by decrementing the right pointer.
        `,
    },
    {
        key: '8',
        description: `
            We kept on updating our max so by the time the loop finished, we should
            have an answer. Return it out.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4,5],
        '2': [7],
        '3': [9],
        '4': [11],
        '5': [12],
        '6': [14],
        '7': [16,17,18,19],
        '8': [21]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def container_with_most_water(height):
    
    left = 0
    right = len(height) - 1

    max_area = float('-inf')

    while left < right:

        area_height = min(height[left], height[right])
        area_width = right - left

        max_area = max(max_area, area_height * area_width)

        if height[left] < height[right]:
            left += 1
        else:
            right -= 1

    return max_area
`,

JAVA: `

`,
    
}