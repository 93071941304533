export default {

info: `
<div>
    <h1>Read N Characters Given read4</h1>
</div>
<div>
    <p>
        Given a file and assume that you can only read the 
        file using a given method read4, implement a method read 
        to read n characters. Your method read may be called multiple times.
    </p>
    <p>
        The API read4 reads 4 consecutive characters from the file, 
        then writes those characters into the buffer array buf.
        The return value is the number of actual characters read.
        Note that read4() has its own file pointer, much like FILE *fp in C.
    </p>
</div>
<div>
    <p>The read4 API is already defined for you.</p>
    <p>
        @param buf, a list of characters<br/>
        @return an integer<br/>
        function read4(buf):<br/>
    </p>
    <p>Below is an example of how the read4 API can be called.</p>
    <p>
        file = File("abcdefghijk") # File is "abcdefghijk", initially file pointer (fp) points to 'a'<br/>
        buf = [' '] * 4 # Create buffer with enough space to store characters<br/>
        read4(buf) # read4 returns 4. Now buf = ['a','b','c','d'], fp points to 'e'<br/>
        read4(buf) # read4 returns 4. Now buf = ['e','f','g','h'], fp points to 'i'<br/>
        read4(buf) # read4 returns 3. Now buf = ['i','j','k',...], fp points to end of file<br/>
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Solution(object):

    def __init__(self):
        self.queue = [] # global "buffer"

    def read(self, buffer, n):
        index = 0

        # if queue is large enough, read from queue
        while self.queue and n > 0:
            buffer[index] = self.queue.pop(0)
            index += 1
            n -= 1

        while n > 0:
            # read file to buf4
            temp_buffer = [""] * 4
            num_of_chars = read4(temp_buffer)

            if not num_of_chars:
                return index

            if num_of_chars > n:
                self.queue += temp_buffer[n:num_of_chars]

            for i in range(min(num_of_chars, n)):
                buffer[index] = temp_buffer[num_of_chars]
                index += 1
                n -= 1
                
        return index
`,

JAVA: `

`,
    
}