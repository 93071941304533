export default {

info: `
<div>
    <h1>State Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class Context:

    def __init__(self, state):
        self.state = state

    def request(self):
        self.state.handle()


class State(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def handle(self):
        pass


class ConcreteStateA(State):

    def handle(self):
        pass


class ConcreteStateB(State):

    def handle(self):
        pass


def main():
    concretestate_a = ConcreteStateA()
    context = Context(concretestate_a)
    context.request()

`,

JAVA: `

`,
    
}