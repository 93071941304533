export default {

info: `
<div>
    <h1>Total Occurrences of K in Sorted Array</h1>
</div>
<div>
    <p>
        Given a sorted array, find the total count of occurrences of a given 
        value K as efficiently as possible. (Hint: The best answer runs in O(log(n)) time)
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Create a helper function that will help us determine if the next
            index is in bounds for the array. We know that the next index will
            not be in bounds if it is less than 0 or greater than or equal to the length
            of the array (final element is at length minus one). 
        `,
    },
    {
        key: '2',
        description: `
            We will create another helper function that will perform the binary search for
            us. As usual, it takes in an array, the target value, a start index, and an end index.
            This time, however, it will also take in the search type. The search type will determine
            which direction we go in if we do hit the target. Remember, the two values we are looking
            for is K with no other K before it and then the last K with no other K after it.
        `,
    },
    {
        key: '3',
        description: `Simple edge cases to check for`,
    },
    {
        key: '4',
        description: `
            For binary searches, we need to find a middle index. Doing it like this ensures that
            you stay in bounds. Any other way can throw some errors depending on the array size.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [35,36,37,38],
        '2': [13],
        '3': [15,16],
        '4': [18]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def occurrences_of_k(arr, k):
    
    first_occurrence = binary_search(arr, k, 0, len(arr) - 1, 1)
    
    if first_occurrence == -1:
        return 0
    
    last_occurrence = binary_search(arr, k, 0, len(arr) - 1, 2)
    
    return last_occurrence - first_occurrence + 1

def binary_search(arr, k, left_index, right_index, search_type):
    
    if len(arr) == 0 or left_index > right_index:
        return -1
    
    mid_index = left_index + (right_index - left_index) // 2
    
    if arr[mid_index] == k:
        if search_type == 1:
            if in_bounds(arr, mid_index-1) and arr[mid_index] == arr[mid_index-1]:
                return binary_search(arr, k, left_index, mid_index - 1, search_type)
        if search_type == 2:
            if in_bounds(arr, mid_index+1) and arr[mid_index] == arr[mid_index+1]:
                return binary_search(arr, k, mid_index + 1, right_index, search_type)
        return mid_index
    elif arr[mid_index] < k:
        return binary_search(arr, k, mid_index + 1, right_index, search_type)
    else:
        return binary_search(arr, k, left_index, mid_index - 1, search_type)
    
    return -1

def in_bounds(arr, index):
    if index < 0 or index >= len(arr):
        return False
    return True
`,

JAVA: `

`,
    
}