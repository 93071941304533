import { LANG } from './lang';

export default [
    {
        title: 'BST Insert Node',
        url: 'bst_insert_node',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'BST Delete Node',
        url: 'bst_delete_node',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Union Find / Disjoint Set / Merge Find Set',
        url: 'union_find',
        langs: [
            LANG.PYTHON,
        ]
    },
]