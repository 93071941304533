import { Component, OnInit } from '@angular/core';

import PROBLEMS from '../constants/problems';
import ALGORITHMS from '../constants/algorithms';
import DESIGN_PATTERNS from '../constants/design-patterns';
import DATA_TRUCTURES from '../constants/data-structures';
import TASKS from '../constants/tasks';

@Component({
  selector: 'app-home-page',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {

    tasks: any[] = TASKS
    dataStructures: any[] = DATA_TRUCTURES;
    problems: any[] = PROBLEMS;
    algorithms: any[] = ALGORITHMS;
    designPatterns: any[] = DESIGN_PATTERNS;
    tech: any[] = [];

    sections: any[] = [
        { source: this.tasks, label: 'Tasks', group: 'tasks' },
        { source: this.algorithms, label: 'Algorithms', group: 'algorithms' },
        { source: this.designPatterns, label: 'Design Patterns', group: 'design-patterns' },
        { source: this.problems, label: 'Problems', group: 'problems' },
        { source: this.dataStructures, label: 'Data Structures', group: 'data-structures' }
    ]

    ngOnInit() {

    }

}