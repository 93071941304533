export default {

info: `
<div>
    <h1>Is Nth Bit Set</h1>
</div>
<div>
    <p>
        Write a program that takes an integer and tests whether the n-th bit in the
        the binary representation of that integer is set or not.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def is_nth_bit_set(num, nth_bit):
    if num & (1 << nth_bit):
        return True
    return False
`,

JAVA: `

`,
    
}