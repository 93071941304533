export default {

info: `
<div>
    <h1>Decode Ways</h1>
</div>
<div>
    <p>A message containing letters from A-Z is being encoded to numbers using the following mapping</p>
    <p>Given a non-empty string containing only message, determine the total number of ways to decode it.</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            An edge case if the first character is 0. There is no zero character.
        `,
    },
    {
        key: '2',
        description: `
            We will use the tabulation (bottom-up) approach in dynamic programming
            so create a table where each cell represents the total ways to decode up
            to that point. We make it the length of the message plus one to account
            for an empty message.
        `,
    },
    {
        key: '3',
        description: `
            The first cell in the table represents an empty message. An
            empty message is decoded as nothing which counts as a way so
            we put one here.
        `,
    },
    {
        key: '4',
        description: `
            The second cell represents the first character in the message.
            We already did the check to make sure this was not a zero so it must
            be a number from one to nine which means this is another way. 
        `,
    },
    {
        key: '5',
        description: `
            To fill out the rest of the table, start a loop that goes 
            from index two to the last element in the table. 
        `,
    },
    {
        key: '6',
        description: `
            If the message character at this corresponding index is 
            greater than zero, we accept this as a way and take the 
            value from the table cell before it.
        `,
    },
    {
        key: '7',
        description: `
            If the message character before the current one is a one, 
            we know that the previous character and the current character 
            must be any number from 10-19 so we can accept this value. If
            the previous character is two and the current character is less
            than 7, we know that the previous character plus the current
            character must be a number between 20-26 so we cal also accept this
            value. If any of the two conditions are true, we accept this pair and
            add the value from two cells ago.
        `,
    },
    {
        key: '8',
        description: `Return the last item in the table`,
    },
],

templates: {
    PYTHON: {
        '1': [4,5],
        '2': [9],
        '3': [11],
        '4': [12],
        '5': [14],
        '6': [16,17],
        '7': [19, 20],
        '8': [22]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def decode_ways(message):

    if message[0] == '0':
        return 0
    
    message_len = len(message)
    
    table = [0 for i in range(message_len + 1)]
    
    table[0] = 1
    table[1] = 1 

    for i in range(2, message_len + 1):  

        if (message[i - 1] > '0'):  
            table[i] = table[i - 1] 

        if (message[i - 2] == '1' or (message[i - 2] == '2' and message[i - 1] < '7')):  
            table[i] += table[i - 2]

    return count[-1];
`,

JAVA: `

`,
    
}