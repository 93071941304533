export default {

info: `
<div>
    <h1>Alien Dictionary</h1>
</div>
<div>
    <p>
        There is a new alien language which uses the latin alphabet. 
        However, the order among letters are unknown to you. You receive 
        a list of non-empty words from the dictionary, where words are sorted 
        lexicographically by the rules of this new language. Derive the order 
        of letters in this language.
    </p>
    <p>Input: ["wrt","wrf","er","ett","rftt"]</p>
    <p>Output: "wertf"</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            The nature of alphabetical ordering should make us think about some type
            of topological sort. In a topological sort, nodes in a directed graph
            are ordered linearly. To start out, we will create a node to represent
            each unique letter and keep track of edges coming into and out of the node. 
        `,
    },
    {
        key: '2',
        description: `
            We will create some type of hash map / object to serve as our adjacency
            list. We will also create another variable to store the results. 
        `,
    },
    {
        key: '3',
        description: `
            Create some type of collection that will hold all the unique letters. This
            will help us make sure we look at each unique letter and do stuff with it.
        `,
    },
    {
        key: '4',
        description: `
            Iterate through the collection of unique letters and insert them into
            the adjacency list with the letter being the key and the value being
            an initialized node.
        `,
    },
    {
        key: '5',
        description: `
            Now we are going to create the edge relationships for each node. Lets say we have two
            words: "ABC" and "BD." If we compare these two words together from left to 
            right by index, we will see an edge from "A" (in word one) to "B" (in word two).
            We would also see another one from "B" (in word one) to "D" (in word two). C (in word one).
            has no relationship so it will be ignored. In this scenario, it would end at the end of the
            topoogicial sort because nothing is dependent on it. To start
            building these edges to represent these types of inbound and outbound relationships, 
            start iterating over each word in the list of words coming in.  
        `,
    },
    {
        key: '6',
        description: `
            Create a collection that has the pairings between the characters in the current word
            to the characters in the next word. At this point keep in mind we are iterating through
            all the words. So if the current word is "ABC" and the next word is "BD", the collection
            looks like this: [(a, b), (b, d)]. We will then iterate through each of these pairs. 
        `,
    },
    {
        key: '7',
        description: `
            For readability, we will take a pair like (a,b) and put these two values into
            variables that represent the current word's character and the next word's character. 
        `,
    },
    {
        key: '8',
        description: `
            To make sure one of the characters do not create edges into themselves, we check
            to make sure that the current character does not equal to the next character. If not,
            build the inbound and outbound relationships between the current word's character
            and the next word's character. 
        `,
    },
    {
        key: '9',
        description: `
            TBD
        `,
    }
],

templates: {
    PYTHON: {
        '1': [2,3,4,5,6],
        '2': [10,11],
        '3': [12],
        '4': [14,15],
        '5': [17],
        '6': [18,43,44],
        '7': [19,20],
        '8': [21,22,23],
        '9': [24]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Node:
    
    def __init__(self):
        self.IN = set()
        self.OUT = set()

def alien_order(words):
    
    graph = {}
    result = ""
    all_nodes = set("".join(words))
    
    for node in all_nodes:
        graph[node] = Node()

    for word_index in range(len(words)-1):
        for char_pair in get_char_pairs(words[word_index], words[word_index+1]):
            curr_word_char = char_pair[0]
            next_word_char = char_pair[1]
            if curr_word_char != next_word_char:
                graph[curr_word_char].OUT.add(next_word_char)
                graph[next_word_char].IN.add(curr_word_char)
                break

    while all_nodes:
        buff = set([char for char in all_nodes if graph[char].OUT and not graph[char].IN])
        if not buff:
            if not [char for char in all_nodes if graph[char].IN]:
                result += "".join(all_nodes)
                break
            else:
                result = ""
                break
                
        result += "".join(buff)
        all_nodes -= buff
        for char in all_nodes:
            graph[char].IN -= buff
            
    return result

def get_char_pairs(curr_word, next_word):
    return zip(curr_word, next_word)
`,

JAVA: `

`,
    
}