export default {

info: `
<div>
    <h1>Total Unique Ways to Make Change</h1>
</div>
<div>
    <p>
        You are given coins of different denominations and a total 
        amount of money. Write a function to compute the number of 
        combinations that make up that amount. You may assume that 
        you have an infinite number of each kind of coin.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def total_unique_ways_to_make_change(coins, target_amount):
    
    cache = [[0 for col in range(0, target_amount + 1)] for row in range(0, len(coins) + 1)]
    
    for row in range(len(coins) + 1):
        cache[row][0] = 1

    for row in range(1,len(coins) + 1):

        for col in range(1, target_amount + 1):

            sum_with_coin = 0
            if coins[row - 1] <= col:
                sum_with_coin = cache[row][col - coins[row - 1]]

            cache[row][col] = cache[row-1][col] + sum_with_coin


    return cache[-1][-1]
`,

CPLUSPLUS: `
#include <iostream>
#include <vector>

class Solution {
public:
    int CoinChange(std::vector<int>& coins, int amount)
    {
        std::vector<std::vector<int>> table(coins.size() + 1, std::vector<int>(amount + 1, 0));
        
        for (int row = 0; row < coins.size() + 1; row++)
        {
            table[row][0] = 1;
        }
        
        for (int row = 1; row < coins.size() + 1; row++)
        {
            for (int col = 1; col < amount + 1; col++)
            {
                int sumWithCoin = 0;
                if (coins[row - 1] <= col)
                {
                    sumWithCoin = table[row][col - coins[row - 1]];
                }
                table[row][col] = table[row - 1][col] + sumWithCoin;
            }
        }
        
        return table[table.size() - 1][table[0].size() - 1];
    }
};
`,

C: `
int change(int amount, int* coins, int coinsSize) {
    
    int table[coinsSize + 1][amount + 1];
        
    for (int row = 0; row < coinsSize + 1; row++)
    {
        for (int col = 0; col < amount + 1; col++)
        {
            if (col == 0)
            {
                table[row][col] = 1;   
            }
            else
            {
                table[row][col] = 0;
            }
        }
    }
        
    for (int row = 1; row < coinsSize + 1; row++)
    {
        for (int col = 1; col < amount + 1; col++)
        {
            int sumWithCoin = 0;
            if (coins[row - 1] <= col)
            {
                sumWithCoin = table[row][col - coins[row - 1]];
            }
            table[row][col] = table[row - 1][col] + sumWithCoin;
        }
    }

    return table[coinsSize][amount];
}
`
    
}