export const environment = {
  production: false,
  firebase: {
      apiKey: "AIzaSyDPRimQVV0J_xE3iont3Z2cDcEofHz-qWI",
      authDomain: "codeterp.firebaseapp.com",
      databaseURL: "https://codeterp.firebaseio.com",
      projectId: "codeterp",
      storageBucket: "codeterp.appspot.com",
      messagingSenderId: "989880697372"
    }
};
