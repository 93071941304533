export default {

info: `
<div>
    <h1>Palindrome Partitioning</h1>
</div>
<div>
    <p>
        Given a string s, partition s such that every substring of the partition is a palindrome.
        Return all possible palindrome partitioning of s.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def partition(string):
    valid_decompositions = []
    decomp_in_progress = []
    decompose_string(string, 0, decomp_in_progress, valid_decompositions)
    return valid_decompositions

def decompose_string(string, build_pointer, decomp_in_progress, valid_decompositions):
    if build_pointer == len(string):
        valid_decompositions.append(list(decomp_in_progress))
    else:
        for i in range(build_pointer, len(string)):
            if is_palindrome(string, build_pointer, i):
                palindromic_snippet = string[build_pointer:i + 1]
                
                decomp_in_progress.append(palindromic_snippet)
                
                decompose_string(string, i + 1, decomp_in_progress, valid_decompositions)
                
                decomp_in_progress.pop()
            
def is_palindrome(string, low, high):
    while low < high:
        if string[low] != string[high]:
            return False
        low += 1
        high -= 1
    return True

`,

JAVA: `

`,
    
}