export default {

info: `
<div>
    <h1>Dijkstra's Shortest Path Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import heapq


def dijkstras(graph, start, end):
    
    min_dist_to_node = {}
    prev_visted = {}
    visited = {}
    
    for node in graph:
        min_dist_to_node[node] = float('inf')
        prev_visted[node] = None
        visited[node] = False

    min_dist_to_node[start] = 0
    queue = []
    
    heapq.heappush(queue,(0, start))
    
    while queue:
        
        node_dist, node = heapq.heappop(queue)
        
        visited[node] = True
        
        if min_dist_to_node[node] < node_dist:
            continue
        
        for to_node in graph[node]:
            
            if visited[to_node]:
                continue
                
            edge_dist = graph[node][to_node]
                
            new_dist = min_dist_to_node[node] + edge_dist
            
            if new_dist < min_dist_to_node[to_node]:
                prev_visted[to_node] = node
                min_dist_to_node[to_node] = new_dist
                heapq.heappush(queue, (min_dist_to_node[to_node], to_node))
                
        if node == end:
            
            distance = min_dist_to_node[end]
            path = []
            
            curr_node = end
            while curr_node != None:
                path.append(curr_node)
                curr_node = prev_visted[curr_node]
            
            return path[::-1], distance
            
    return [], float('inf')
`,

JAVA: `

`,
    
}