export default {

info: `
<div>
    <h1>Integer to English Words</h1>
</div>
<div>
    <p>
        You are given an array of strings transaction where transactions[i] consists of comma-separated values 
        representing the name, time (in minutes), amount, and city of the transaction.
    </p>
    <p>
        Return a list of transactions that are possibly invalid. You may return the answer in any order.
    </p>
    <p>
        A transaction is possibly invalid if:
    </p>
    <ul>
        <li>the amount exceeds $1000, or;</li>
        <li>if it occurs within (and including) 60 minutes of another transaction with the same name in a different city.</li>
    </ul>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['CPLUSPLUS'],

CPLUSPLUS: `
#include <iostream>
#include <string>
#include <vector>
#include <sstream>
#include <algorithm>
#include <cmath>
#include <Utils.h>

struct Transaction
{
	std::string name;
	int time;
	int amount;
	std::string city;
	bool isValid = true;
};

class Solution {
public:

	std::vector<std::string> InvalidTransactions(std::vector<std::string>& transactions)
	{
		std::string tempValue;

		std::vector<Transaction> processedTransactions;

		std::vector<std::string> invalidTransactions;

		// name, time, amount, city
		for (auto transaction : transactions)
		{
			std::stringstream splitTransaction(transaction);

			Transaction row;

			int index = 0;
			while (std::getline(splitTransaction, tempValue, ','))
			{
				switch (index) {
				case 0:
					row.name = tempValue;
					break;
				case 1:
					row.time = std::stoi(tempValue);
					break;
				case 2:
					row.amount = std::stoi(tempValue);
					break;
				case 3:
					row.city = tempValue;
					break;
				default:
					break;
				}
				index++;
			}

			if (row.amount > 1000)
			{
				row.isValid = false;
			}

			processedTransactions.push_back(row);

		}

		for (int currentIndex = 0; currentIndex < processedTransactions.size(); currentIndex++)
		{

			for (int targetIndex = currentIndex; targetIndex < processedTransactions.size(); targetIndex++)
			{

				if (currentIndex == targetIndex)
					continue;

				int timediff = std::abs(processedTransactions[targetIndex].time - processedTransactions[currentIndex].time);
				if (timediff <= 60)
				{
					if ((processedTransactions[targetIndex].name == processedTransactions[currentIndex].name) && (processedTransactions[targetIndex].city != processedTransactions[currentIndex].city))
					{
						processedTransactions[currentIndex].isValid = false;
						processedTransactions[targetIndex].isValid = false;
					}
				}
			}

			if (!processedTransactions[currentIndex].isValid)
			{
				invalidTransactions.push_back(transactions[currentIndex]);
			}

		}

		return invalidTransactions;
	}
}
`
    
}