export default {

info: `
<div>
    <h1>Ransom Note</h1>
</div>
<div>
    <p>
        Given an arbitrary ransom note string and another string 
        containing letters from all the magazines, write a function 
        that will return true if the ransom note can be constructed 
        from the magazines ; otherwise, it will return false.
    </p>
    <p>
        Each letter in the magazine string can only be used once in your ransom note.
    </p>
</div>
<div>
    <code>
        canConstruct("a", "b") -> false
        canConstruct("aa", "ab") -> false
        canConstruct("aa", "aab") -> true
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def can_construct_ransom_note(ransomNote, magazine):
        
    ransom_note_map = {}
    magazine_map = {}

    add_to_map(ransom_note_map, ransomNote)
    add_to_map(magazine_map, magazine)

    result = True

    for char in ransom_note_map:
        
        if char not in magazine_map:
            result = False
            break

        if magazine_map[char] < ransom_note_map[char]:
            result = False
            break
            
    return result

def add_to_map(dictionary, string):
    for char in string:
        if char not in dictionary:
            dictionary[char] = 1
        else:
            dictionary[char] += 1
`,

JAVA: `

`,
    
}