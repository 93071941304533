export default {

info: `
<div>
    <h1>Rotate Matrix</h1>
</div>
<div>
    <p>You are given an n x n 2D matrix representing an image.</p>
    <p>Rotate the image by 90 degrees (clockwise).</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def rotate(matrix):
    row_len = len(matrix)
    
    for i in range(0, row_len):
        for j in range(i, row_len):
            temp = matrix[j][i]
            matrix[j][i] = matrix[i][j]
            matrix[i][j] = temp
    
    for i in range(0, row_len):
        for j in range(0, (row_len//2)):
            tmp = matrix[i][j];
            matrix[i][j] = matrix[i][row_len - j - 1]
            matrix[i][row_len - j - 1] = tmp
    
    return matrix
`,

JAVA: `

`,
    
}