export default {

info: `
<div>
    <h1>Singleton Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'JAVA'],

PYTHON: `
class Singleton:

    class __Singleton:

        def __init__(self):
            pass

    instance = None

    def __init__(self):

        if not Singleton.instance:
            Singleton.instance = Singleton.__Singleton()
`,

JAVA: `
public final class Singleton {
    
    private static Singleton instance;

    private Singleton() {}

    public static Singleton getInstance() {
        if (instance == null) {
            instance = new Singleton();
        }
        return instance;
    }
}
`,
    
}