export default {

info: `
<div>
    <h1>Dinic's Maximum Flow Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def dinics(adjacency_list, start, end):
    
    graph = build_residual_graph(adjacency_list)
    
    max_flow = 0
        
    levels = {i:None for i in graph}
    
    while bfs(graph, start, end, levels):
            
        f = float('inf')
        while f != 0:
            f = dfs(graph, start, end, levels, start, float('inf'))
            max_flow += f
            
    return max_flow
    
        
def bfs(graph, start, end, levels):
    
    for node in levels:
        levels[node] = -1

    levels[start] = 0

    queue = [start]

    while queue:

        from_node = queue.pop(0)
        
        for to_node in graph[from_node]:
            
            edge_capacity, edge_flow = graph[from_node][to_node]
            capacity = edge_capacity - edge_flow

            if capacity > 0 and levels[to_node] == -1:
                levels[to_node] = levels[from_node] + 1
                queue.append(to_node)

    return levels[end] != -1


def dfs(graph, start, end, levels, from_node, flow):
    
    if from_node == end:
        return flow
    
    for to_node in graph[from_node]:
        
        edge_capacity, edge_flow = graph[from_node][to_node]
        capacity = edge_capacity - edge_flow
        
        if (capacity > 0) and (levels[to_node] == levels[from_node] + 1):
            bottleneck = dfs(graph, start, end, levels, to_node, min(flow, capacity))
            
            if bottleneck > 0:
                graph[from_node][to_node][1] += bottleneck
                graph[to_node][from_node][1] -= bottleneck
                return bottleneck
            
    return 0

            
def build_residual_graph(adjacency_list):
    
    for from_node in adjacency_list:    
        for to_node in adjacency_list[from_node]:
            adjacency_list[from_node][to_node] = [adjacency_list[from_node][to_node], 0]
            
    for from_node in adjacency_list:
        for to_node in adjacency_list[from_node]:
            if from_node not in adjacency_list[to_node]:
                adjacency_list[to_node][from_node] = [0, 0]
                
    return adjacency_list
`,

JAVA: `

`,
    
}