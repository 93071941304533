export default {

info: `
<div>
    <h1>Khan's Topological Sorting Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def khans_topological_sort(adjacency_list):
    
    incoming_edge_count = {}
    
    for node in adjacency_list:
        for edge in adjacency_list[node]:
            if edge not in incoming_edge_count:
                incoming_edge_count[edge] = 1
            else:
                incoming_edge_count[edge] += 1
                
    queue = []
    for node in adjacency_list:
        if node not in incoming_edge_count:
            queue.append(node)
            
    visited_node_count = 0
    result = []
    
    while queue:
        
        curr_node = queue.pop(0)
        result.append(curr_node)
        
        for node in adjacency_list[curr_node]:
            
            incoming_edge_count[node] -= 1
            
            if incoming_edge_count[node] == 0:
                queue.append(node)
                
        visited_node_count += 1
        
    if visited_node_count != len(adjacency_list):
        return None
    else:
        return result
`,

JAVA: `

`,
    
}