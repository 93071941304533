export default {

info: `
<div>
    <h1>Clone Graph</h1>
</div>
<div>
    <p>
        Given a reference of a node in a connected undirected graph, 
        return a deep copy (clone) of the graph. Each node in the graph 
        contains a val (int) and a list (List[Node]) of its neighbors.
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We know we want to traverse the entire original graph so we whould
            be thinking breadth first search. For a BFS we will make a queue and put
            our start node in it.
        `,
    },
    {
        key: '2',
        description: `
            Key to this problem is creating sometype of hashmap data structure
            that allows quick lookup. Cloning a node is self explantorary but we
            need a way to re-create the relationships. We will be able to reference
            any source or clone using this data structure. The key's are the original
            nodes and the values will be the cloned versions. Note that this stucture
            will also serve as a record of nodes that we have already visited. 
            We will also go ahead and clone the first node.
        `,
    },
    {
        key: '3',
        description: `
            Typical in any other BFS we do stuff so long as there are items in the queue.
        `,
    },
    {
        key: '4',
        description: `
            We will pop out the next item in the queue and start iterating over its neightbors.
        `,
    },
    {
        key: '5',
        description: `
            Before we do anything else, we want to check to see if we visited this node before.
            If it is not in that special source-to-clone hashmap, we have not been here so we
            can add it to the hashmap along with its clone as a value. Then we add this neighbor
            node to the queue so we can check all of its neighbors later.
        `,
    },
    {
        key: '6',
        description: `
            Here is where the magic happens after we finish cloning the current
            neighbor node and adding it to the queue, we find the current node's clone
            using the hashmap and inside the clone's neighbors list, we add the cloned
            neighbor to it.
        `,
    },
    {
        key: '7',
        description: `
            When there is nothing else in the queue, we should be done
            so just return the cloned version of the start node via that special
            source-to-clone data structure.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4,5],
        '2': [7,8],
        '3': [10],
        '4': [11,12],
        '5': [13,14,15],
        '6': [17],
        '7': [19]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def clone_graph(start_node):
    
    queue = []
    queue.append(start_node)

    source_to_clone = {}
    source_to_clone[start_node] = Node(start_node.value)
    
    while queue:
        current_node = queue.pop(0)
        for neighbor in current_node.neighbors:
            if neighbor not in source_to_clone:
                source_to_clone[neighbor] = Node(neighbor.value)
                queue.append(neighbor)
            
            source_to_clone[current_node].neighbors.append(source_to_clone[neighbor])
                
    return source_to_clone[start_node]
`,

JAVA: `

`,
    
}