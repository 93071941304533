export default {

info: `
<div>
    <h1>Bridge Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class Abstraction:

    def __init__(self, implementor):
        self.implementor = implementor

    def higher_level_operation(self):
        self.implementor.primitive_operation()


class Implementor(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def primitive_operation(self):
        pass


class ConcreteImplementorA(Implementor):

    def primitive_operation(self):
        pass


def main():
    concrete_implementor_a = ConcreteImplementorA()
    abstraction = Abstraction(concrete_implementor_a)
    abstraction.higher_level_operation()
`,

JAVA: `

`,
    
}