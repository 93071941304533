export default {

info: `
<div>
    <h1>Group Anagrams</h1>
</div>
<div>
    <p>
        Write a method to sort an array of strings so 
        that all the anagrams are next to each other
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: 'Declare a data structure that can hold key value pairs in the form of a string and list',
        subs: []
    },
    {
        key: '2',
        description: `
            Start a loop that iterates through the array / list of strings
        `
    },
    {
        key: '3',
        description: `
            Make a key that will be used in the map above that takes 
            the form of a string with sorted characters. Two strings 
            that are anagrams will be equal if their characters are
            sorted.
        `
    },
    {
        key: '4',
        title: '',
        description: `
            If the key is already in the map, add this new string to
            the array / list at that key in the map
        `
    },
    {
        key: '5',
        title: '',
        description: `
            If the key is not in the map, add the key to the map with
            the value being a new array / list with the current string
            inside.
        `
    },
    {
        key: '6',
        description: 'Return a array / list with just the values of the map',
        subs: []
    },
],

templates: {
    PYTHON: {
        '1': [5],
        '2': [6],
        '3': [7],
        '4': [8,9],
        '5': [10,11],
        '6': [13]
    },
    CSHARP: {
        '1': [13],
        '2': [17],
        '3': [19],
        '4': [20,21,22,23],
        '5': [24,25,26,27],
        '6': [30,31,32,33,34,35]
    }
},

languages: ['PYTHON', 'CSHARP'],

PYTHON: `
class Solution:

    def group_anagrams(self, array):
        char_group_map = {}
        for string in array:
            key = "".join(sorted(string))
            if key in char_group_map:
                char_group_map[key].append(string)
            else:
                char_group_map[key] = [string]
            
        return [char_group_map[key] for key in char_group_map]
`,

CSHARP: `
using System;
using System.Collections.Generic;
using System.Linq;

namespace GroupAnagrams
{
    class Solution
    {

        List<List<string>> GroupAnagrams(List<string> stringList)
        {
            Dictionary<string, List<string>> charGroupMap = new Dictionary<string, List<string>>();

            List<List<string>> result = new List<List<string>>();

            foreach (string word in stringList)
            {
                string key = String.Concat(word.OrderBy(c => c));
                if (charGroupMap.ContainsKey(key))
                {
                    charGroupMap[key].Add(word);
                }
                else
                {
                    charGroupMap.Add(key, new List<string> { word });
                }
            }

            foreach (KeyValuePair<string, List<string>> key in charGroupMap)
            {
                result.Add(key.Value);
            }

            return result;
        }
    }
}
`


    
}