<div class="home">

    <div class="home__siteTitle">
        <h1>CodeTerp</h1>
    </div>

    <div class="home__siteIntro">
        <h2>
            Just another tool trying to spread computing knowledge.
        </h2>
    </div>

    <div class="home__section" *ngFor="let section of sections">
        <h2 class="home__sectionTitle">{{ section.label }}</h2>
        <app-card-list [taskList]="section.source" [group]="section.group"></app-card-list>
    </div>
    
</div>

