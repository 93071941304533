export default {

info: `
<div>
    <h1>Divide Two Integers</h1>
</div>
<div>
    <p>
        Given two integers dividend and divisor, divide two integers 
        without using multiplication, division and mod operator.
    </p>
    <p>
        Return the quotient after dividing dividend by divisor.
    </p>
    <p>
        The integer division should truncate toward zero.
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            First check to see if the final result should be a negative. We will store
            this for later. If there is any positive or negative mis-match between
            the dividend and divisor, the result is going to be negative.
        `,
    },
    {
        key: '2',
        description: `
            Because we are about to do bit manipulation, get rid of any negative
            sign by taking the absolute value of the dividend and divisor.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6,7]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def divide(dividend, divisor):
    
    is_negative = (dividend < 0 and divisor > 0) or (dividend > 0 and divisor < 0)
    result = 0
    dividend = abs(dividend)
    divisor = abs(divisor)

    carry = 1
    sub = divisor

    while dividend >= divisor:
        
        if dividend >= sub:
            dividend -= sub
            result += carry
            sub = sub << 1
            carry = carry << 1 
        else:
            sub = sub >> 1
            carry = carry >> 1

    if is_negative:
        result = -result

    result = min(max(-2147483648,result),2147483647)

    return result
`,

JAVA: `

`,
    
}