export default {

info: `
<div>
    <h1>Visitor Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class Element(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def accept(self, visitor):
        pass


class ConcreteElementA(Element):

    def accept(self, visitor):
        visitor.visit_concrete_element_a(self)


class ConcreteElementB(Element):

    def accept(self, visitor):
        visitor.visit_concrete_element_b(self)


class Visitor(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def visit_concrete_element_a(self, concrete_element_a):
        pass

    @abc.abstractmethod
    def visit_concrete_element_b(self, concrete_element_b):
        pass


class ConcreteVisitorOne(Visitor):

    def visit_concrete_element_a(self, concrete_element_a):
        pass

    def visit_concrete_element_b(self, concrete_element_b):
        pass


class ConcreteVisitorTwo(Visitor):

    def visit_concrete_element_a(self, concrete_element_a):
        pass

    def visit_concrete_element_b(self, concrete_element_b):
        pass


def main():
    concrete_visitor_one = ConcreteVisitorOne()
    concrete_element_a = ConcreteElementA()
    concrete_element_a.accept(concrete_visitor_one)
`,

JAVA: `

`,
    
}