export default {

info: `
<div>
    <h1>Counting Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def counting_sort(nums):
    
    max_value = max(nums)

    counts = [0] * (max_value + 1)
    for item in nums:
        counts[item] += 1

    num_items_before = 0
    for i, count in enumerate(counts):
        counts[i] = num_items_before
        num_items_before += count

    sorted_list = [None] * len(nums)

    for item in nums:

        sorted_list[counts[item]] = item

        counts[item] += 1

    return sorted_list
`,

JAVA: `

`,
    
}