export default {

info: `
<div>
    <h1>Longest Common Sequence</h1>
</div>
<div>
    <p>
        You are given 2 strings. Return the length of the 
        longest subsequence that the 2 strings share.
    </p>
    <p>"ABAZDC", "BACBAD" => "ABAD"</p>
    <p>"AGGTAB", "GXTXAYB" => "GTAB"</p>
    <p>"aaaa", "aa" => "aa"</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Because we can solve this programming with dynamic programming, we will
            start off by creating our table where row indexes represent each
            character in one string and columns represent each character
            in the other string. We add one more character to each string to represent
            an empty character at the beginning of each string.
        `,
    },
    {
        key: '2',
        description: `
            To start iterating over this table (matrix), we will create an outer
            loop that represents our rows (first sequence) and then an inner
            loop that represents our columns (second sequence). Note that we added
            an index for the empty character in the strings so the loops 
            need to account for this.
        `,
    },
    {
        key: '3',
        description: `
            We are checking to see if the characters match. If we are in any row
            and column index that is 0, that means at least one of the characters is an empty
            string which automatically does not count as a match. We can just default the cell
            to zero.
        `,
    },
    {
        key: '4',
        description: `
            Now we handle if the characters do match. The indexes are subtracted by one
            because we added in another empty string. If they do match the current cell is equal
            to 1 plus the cell that was a row and column back. For this tabulation table, we essentially
            accept matches by go back rows and columns.
        `,
    },
    {
        key: '5',
        description: `
            If the condition above is not met, then  we just take the max of the cell above and the cell
            to the left.
        `,
    },
    {
        key: '6',
        description: `
            Now we can just return out the answer which is the last cell in our tabulation table.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6,7],
        '3': [9,10],
        '4': [11,12],
        '5': [14],
        '6': [16],
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def longest_common_subsequence(str_row, str_col):
    
    dp = [[None for col in range(len(str_col) + 1)] for row in range(len(str_row) + 1)]
    
    for row in range(0, len(str_row) + 1):
        for col in range(0, len(str_col) + 1):
            
            if row == 0 or col == 0:
                dp[row][col] = 0
            elif str_row[row-1] == str_col[col-1]:
                dp[row][col] = 1 + dp[row-1][col-1]
            else:
                dp[row][col] = max(dp[row][col-1],dp[row-1][col])
    
    return dp[len(str_row)][len(str_col)]
`,

JAVA: `

`,
    
}