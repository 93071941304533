export default {

info: `
<div>
    <h1>Maximal Rectangle</h1>
</div>
<div>
    <p>
        Given a 2D binary matrix filled with 0's and 1's, find the 
        largest rectangle containing only 1's and return its area.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def maximal_rectangle(matrix):

    if not matrix:
        return 0
    
    row_len = len(matrix)
    col_len = len(matrix[0])

    max_area = 0
    cache = [0] * col_len
    
    for row in range(row_len):
        for col in range(col_len):

            if matrix[row][col] == '1':
                cache[col] = cache[col] + 1
            else:
                cache[col] = 0

        max_area = max(max_area, max_area_histogram(cache))
        
    return max_area

def max_area_histogram(histogram):
    
    max_area = float('-inf')
    height_stack = []
    
    index = 0
    while index < len(histogram):
        
        if not height_stack or histogram[height_stack[-1]] <= histogram[index]:
            height_stack.append(index)
            index += 1
        else:
            height = histogram[height_stack.pop()]
            width = index
            
            if height_stack:
                width = index - height_stack[-1] - 1
            
            area = height * width
            max_area = max(max_area, area)
            
    while height_stack:
        height = histogram[height_stack.pop()]
        width = index

        if height_stack:
            width = index - height_stack[-1] - 1
            
        area = height * width
        max_area = max(max_area, area)
        
    return max_area
`,

JAVA: `

`,
    
}