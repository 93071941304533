export default {

info: `
<div>
    <h1>Nth Highest Salary</h1>
</div>
<div>
    <p>Write a SQL query to get the nth highest salary from the Employee table.</p>
</div>
<div>
    <code>
    +----+--------+
    | Id | Salary |
    +----+--------+
    | 1  | 100    |
    | 2  | 200    |
    | 3  | 300    |
    +----+--------+
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['SQL_ORACLE', 'SQL_MS', 'SQL_MYSQL'],

SQL_MS: `
CREATE FUNCTION getNthHighestSalary(@N INT) RETURNS INT
BEGIN
  RETURN (
	  SELECT DISTINCT E1.Salary
	  FROM Employee E1
	  LEFT JOIN (SELECT DISTINCT Salary FROM Employee) E2
	  ON E2.Salary > E1.Salary
	  GROUP BY E1.Id, E1.Salary
	  HAVING COUNT(E2.Salary) = @N - 1
  );
END
`,

SQL_MYSQL: `
CREATE FUNCTION getNthHighestSalary(N INT) RETURNS INT
BEGIN
  RETURN (
	  SELECT DISTINCT E1.Salary
	  FROM Employee E1
	  LEFT JOIN (SELECT DISTINCT Salary FROM Employee) E2
	  ON E2.Salary > E1.Salary
	  GROUP BY E1.Id, E1.Salary
	  HAVING COUNT(E2.Salary) = N - 1
  );
END
`,

SQL_ORACLE: `
CREATE FUNCTION getNthHighestSalary(N IN NUMBER) RETURN NUMBER IS
result NUMBER;
BEGIN
    SELECT DISTINCT SALARY INTO result
    FROM (
        SELECT SALARY, dense_rank() over(order by salary desc) as RANK 
        FROM Employee
    )
    WHERE RANK = N;
    RETURN result;
END;
`
   
}