export default {

info: `
<div>
    <h1>Word Search</h1>
</div>
<div>
    <p>Given a 2D board and a word, find if the word exists in the grid.</p>
    <p>
        The word can be constructed from letters of sequentially adjacent cell, 
        where "adjacent" cells are those horizontally or vertically neighboring. 
        The same letter cell may not be used more than once.
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Create a helper function that ensures we stay in bounds of the matrix
            as we try to traverse it. It will take in the board and the next row and
            next column index. If the row index is less than 0 or greater than or equal to
            the length of the matrix (the rows), we are out of bounds. If the col index
            is less than zero or greater than or equal to a row in the matrix (the columns),
            we are out of bounds.
        `,
    },
    {
        key: '2',
        description: `
            Create a loop that will iterate from index 0 to the last row index of the board.
            Inside this loop, create another loop that will iterate from index 0 to the
            last column index in each row of the board. This ensures that we go through each 
            cell in the matrix from left to right and top to bottom.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [35,36,37,38,39,40,41,42],
        '2': [4,5]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def word_search(board, word):
    
    for row in range(len(board)):
        for col in range(len(board[0])):
            if dfs(board, row, col, word, 0):
                return True
    
    return False
            
def dfs(board, row, col, word, word_index):
    if len(word) == word_index:
        return True
    
    if is_out_of_bounds(board, row, col):
        return False
    
    if board[row][col] != word[word_index]:
        return False
    
    temp = board[row][col]
    board[row][col] = '#'
    
    result = (
        dfs(board, row + 1, col, word, word_index + 1) or
        dfs(board, row - 1, col, word, word_index + 1) or
        dfs(board, row, col + 1, word, word_index + 1) or
        dfs(board, row, col - 1, word, word_index + 1)
    )
    
    board[row][col] = temp
    
    return result
    
def is_out_of_bounds(board, row, col):
    if row < 0 or row >= len(board):
        return True
    
    if col < 0 or col >= len(board[0]):
        return True
    
    return False
`,

JAVA: `

`,
    
}