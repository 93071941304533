import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pseudocoder',
  templateUrl: './pseudocoder.component.html',
  styleUrls: ['./pseudocoder.component.scss']
})
export class PseudocoderComponent implements OnInit {

    @Input() stepList: any = null;
    @Output() moveToStep: EventEmitter<any> = new EventEmitter();

    step: String = '';

    constructor() { }

    ngOnInit() {
        this.step = '';
    }

    onMoveToStep(stepItemKey: string) {
        if (stepItemKey == this.step) {
            this.step = '';
            this.moveToStep.emit('')
        } else {
            this.step = stepItemKey;
            this.moveToStep.emit(stepItemKey)
        }
    }

    onHoverMoveToStep(stepItemKey: string) {
        if (this.step == '') {
            this.moveToStep.emit(stepItemKey)
        }
    }

}
