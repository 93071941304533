export default {

info: `
<div>
    <h1>First Bad Version</h1>
</div>
<div>
    <p>
        You are a product manager and currently leading a team 
        to develop a new product. Unfortunately, the latest version 
        of your product fails the quality check. Since each version is 
        developed based on the previous version, all the versions after a 
        bad version are also bad.
    </p>
    <p>
        Suppose you have n versions [1, 2, ..., n] and you want to find out the 
        first bad one, which causes all the following ones to be bad.
    </p>
    <p>
        You are given an API bool isBadVersion(version) which will return whether version is bad. Implement 
        a function to find the first bad version. You should minimize the number of calls to the API.
    </p>
</div>
<div>
    <code>
        Given n = 5, and version = 4 is the first bad version.

        call isBadVersion(3) -> false
        call isBadVersion(5) -> true
        call isBadVersion(4) -> true

        Then 4 is the first bad version. 
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def first_bad_version(n):

    start = 1
    end = n

    while start <= end:
        middle = start + (end-start) // 2

        if not isBadVersion(middle):
            start = middle + 1
        else:
            end = middle - 1

    return start
`,

JAVA: `

`,
    
}