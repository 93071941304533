export default {

info: `
<div>
    <h1>Zig Zag Conversion</h1>
</div>
<div>
    <p>
        The string "PAYPALISHIRING" is written in a zigzag pattern on a 
        given number of rows like this: (you may want to display 
        this pattern in a fixed font for better legibility)
    </p>
    <p>
        And then read line by line: "PAHNAPLSIIGYIR"
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def zigzag_convert(string, num_rows):
    
    progress = ["" for row in range(num_rows)]
    
    row_index = 0
    origin = 'CEILING'
    
    for char in string:
        
        progress[row_index] += char
        
        if origin == 'CEILING':
            if (row_index + 1) >= num_rows:
                origin = 'FLOOR'
                row_index -= 1
            else:
                row_index += 1
        else:
            if (row_index - 1) < 0:
                origin = 'CEILING'
                row_index += 1
            else:
                row_index -= 1
    
    
    result = "" 
    for row in progress:
        result += row
   
    return result
`,

JAVA: `

`,
    
}