export default {

info: `
<div>
    <h1>Selection Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def selection_sort(arr):
    
    arr_len = len(arr)
    
    for sorted_index in range(arr_len):
        
        min_index = sorted_index
        
        for scan_index in range(sorted_index, arr_len):
        
           if arr[scan_index] < arr[min_index]:
                min_index = scan_index
                
        swap(arr, min_index, sorted_index)
        
    return arr

def swap(arr, index_one, index_two):
    arr[index_one], arr[index_two] = arr[index_two], arr[index_one]
`,

JAVA: `

`,
    
}