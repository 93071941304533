export default {

info: `
<div>
    <h1>Decorator Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc

class Component(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def operation(self):
        pass


class Decorator(Component, metaclass=abc.ABCMeta):

    def __init__(self, component):
        self._component = component

    @abc.abstractmethod
    def operation(self):
        pass

class ConcreteDecoratorA(Decorator):

    def operation(self):
        self._component.operation()


class ConcreteDecoratorB(Decorator):

    def operation(self):
        self._component.operation()

class ConcreteComponent(Component):

    def operation(self):
        pass

def main():
    concrete_component = ConcreteComponent()
    concrete_component = ConcreteDecoratorA(concrete_component)
    concrete_component = ConcreteDecoratorB(concrete_component)
`,

JAVA: `

`,
    
}