export default {

info: `
<div>
    <h1>Convert BST to Sorted Doubly Linked List</h1>
</div>
<div>
    <p>
        Convert a BST to a sorted circular doubly-linked list in-place. 
        Think of the left and right pointers as synonymous to the 
        previous and next pointers in a doubly-linked list.
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We will start off by doing an edge case to see if a root node
            was even given to the function.
        `,
    },
    {
        key: '2',
        description: `
            We will call our recursive utility function passing in the root.
            This will be explained later but the utility function should
            return back the last element on the right side of the linked list
            and the first element on the right side of the linked list.
        `,
    },
    {
        key: '3',
        description: `
            Lets start on the recursive function. Remember, this is essentially a question
            that we are asking each node. 
        `,
    },
    {
        key: '4',
        description: `
            We will say we hit a base case if we are at the bottom of the BST
            so the node does not have a left or right child. If this is the case
            just return out the node twice so it is the head and a tail.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4,5],
        '2': [7],
        '3': [13],
        '4': [15,16]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def tree_to_doubly_list(root):
    
    if not root:
        return None
    
    head_left, tail_right = tree_util(root)
    head_left.left = tail_right
    tail_right.right = head_left
    
    return head_left

def tree_util(node):
    
    if not node.left and not node.right:
        return node, node
    
    if node.left:
        head_left, tail_left = tree_util(node.left)
        tail_left.right = node
        node.left = tail_left
    else:
        head_left, tail_left = node, node
        
    if node.right:
        head_right, tail_right = tree_util(node.right)
        head_right.left = node
        node.right = head_right
    else:
        head_right, tail_right = node, node
        
    return head_left, tail_right
`,

JAVA: `

`,
    
}