export const LANG = {
    PYTHON:     'PYTHON',
    JAVASCRIPT: 'JAVASCRIPT',
    CPLUSPLUS:  'CPLUSPLUS',
    JAVA:       'JAVA',
    SWIFT:      'SWIFT',
    KOTLIN:     'KOTLIN',
    CSHARP:     'CSHARP',
    GO:         'GO',
    C:          'C',
    DOCKER:     'DOCKER',
    YAML:       'YAML',
    JSON:       'JSON',
    SCALA:      'SCALA',
    SQL_MYSQL:  'SQL_MYSQL',
    SQL_ORACLE: 'SQL_ORACLE',
    SQL_MS:     'SQL_MS'
}

export const LANG_MAP = {
    [LANG.PYTHON]: {
        LABEL: 'Python',
        EDITOR: 'python'
    },
    [LANG.JAVASCRIPT]: {
        LABEL: 'JavaScript',
        EDITOR: 'javascript'
    },
    [LANG.CPLUSPLUS]: {
        LABEL: 'C++',
        EDITOR: 'text/x-c++src'
    },
    [LANG.JAVA]: {
        LABEL: 'Java',
        EDITOR: 'text/x-java'
    },
    [LANG.SWIFT]: {
        LABEL: 'Swift',
        EDITOR: 'text/x-swift'
    },
    [LANG.KOTLIN]: {
        LABEL: 'Kotlin',
        EDITOR: 'text/x-java'
    },
    [LANG.CSHARP]: {
        LABEL: 'C#',
        EDITOR: 'text/x-csharp'
    },
    [LANG.GO]: {
        LABEL: 'Go',
        EDITOR: 'text/x-go'
    },
    [LANG.C]: {
        LABEL: 'C',
        EDITOR: 'text/x-csrc'
    },
    [LANG.SCALA]: {
        LABEL: 'Scala',
        EDITOR: 'text/x-scala'
    },
    [LANG.DOCKER]: {
        LABEL: 'Dockerfile',
        EDITOR: 'text/x-dockerfile'
    },
    [LANG.YAML]: {
        LABEL: 'Yaml',
        EDITOR: 'text/x-yaml'
    },
    [LANG.SQL_MYSQL]: {
        LABEL: 'MySQL',
        EDITOR: 'text/x-sql'
    },
    [LANG.SQL_ORACLE]: {
        LABEL: 'Oracle',
        EDITOR: 'text/x-sql'
    },
    [LANG.SQL_MS]: {
        LABEL: 'SQL Server',
        EDITOR: 'text/x-sql'
    },

}
