export default {

info: `
<div>
    <h1>Next Closest Time</h1>
</div>
<div>
    <p>
        Given a time represented in the format "HH:MM", form the next closest 
        time by reusing the current digits. There is no limit on how many 
        times a digit can be reused.Given a time represented in the format 
        "HH:MM", form the next closest time by reusing the current digits. 
        There is no limit on how many times a digit can be reused.
    </p>
    <p>
        You may assume the given input string is always valid. For example, "01:34", 
        "12:09" are all valid. "1:34", "12:9" are all invalid.
    </p>
</div>
<div>
    <code>
        Input: "19:34"
        Output: "19:39"
        Explanation: The next closest time choosing from digits 1, 9, 3, 4, is 19:39,
        which occurs 5 minutes later.  It is not 19:33, because this occurs 23 hours 
        and 59 minutes later.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def next_closest_time(time):
    
    hrs_to_minutes = int(time[0:2]) * 60
    min_to_minutes = int(time[3:])

    time_to_minutes = hrs_to_minutes + min_to_minutes

    usable_digits = set()
    for char in time:
        if char != ':':
            digits.add(int(char))

    while True:
        time_to_minutes = (time_to_minutes + 1) % (24 * 60)

        hrs_tens_place = time_to_minutes // 60 // 10
        hrs_ones_place = time_to_minutes // 60 % 10
        min_tens_place = time_to_minutes % 60 // 10
        min_ones_place = time_to_minutes % 60 % 10

        is_valid = True
        for digit in [hrs_tens_place, hrs_ones_place, min_tens_place, min_ones_place]:
            if digit not in usable_digits:
                is_valid = False

        hh = time_to_minutes // 60
        mm = time_to_minutes % 60

        if is_valid:
            return "{:0>2d}:{:0>2d}".format(hh, mm)
`,

JAVA: `

`,
    
}