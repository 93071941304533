export default {

info: `
<div>
    <h1>Best Time to Buy and Sell Stock</h1>
</div>
<div>
    <p>Say you have an array for which the ith element is the price of a given stock on day i.</p>
    <p>
        If you were only permitted to complete at most one transaction 
        (i.e., buy one and sell one share of the stock), design an algorithm to find the maximum profit.
    </p>
    <p>Note that you cannot sell a stock before you buy one.</p>
</div>
<div>
    <code>
        Input: [7,1,5,3,6,4] <br/>
        Output: 5 <br/>
        <br/>
        Explanation: Buy on day 2 (price = 1) and sell on day 5 (price = 6), profit = 6-1 = 5.
                        Not 7-1 = 6, as selling price needs to be larger than buying price.
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Keep a variable that holds our max profit. It will be the answer
            that we return out 
        `,
    },
    {
        key: '2',
        description: `
            We want to find the lowest price to buy at so create another variable
            that is initialized to the systems highest integer value.
            As we iterate through the list of prices, this value will be updated with
            any price that is lower.
        `,
    },
    {
        key: '3',
        description: `
            Loop through the list of prices.
        `,
    },
    {
        key: '4',
        description: `
            We only need one conditional statement. Is the price at the current
            index less than the lowest price to buy?
        `,
    },
    {
        key: '5',
        description: `
            If it is, update the lowest price we can buy at.
        `,
    },
    {
        key: '6',
        description: `
            If it is not, update our maximum profit so far by taking the max
            of the max profit so far and the price at the current index minus
            the lowest buying price so far.
        `,
    },
    {
        key: '7',
        description: `
            By the time we get to this point, we have our max profit. Return it.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6],
        '3': [8],
        '4': [10],
        '5': [11],
        '6': [13],
        '7': [15],
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def max_profit(prices):
    
    max_profit = 0
    
    min_buy_price = float('inf')

    for i in range(len(prices)):

        if prices[i] < min_buy_price:
            min_buy_price = prices[i]
        else:
            max_profit = max(max_profit, prices[i] - min_buy_price)

    return max_profit
`,

JAVA: `

`,

CPLUSPLUS: `
int maxProfit(const std::vector<int>& prices)
{
    int maxProfit = 0;
    int minBuyPrice = std::numeric_limits<int>::max();

    for (auto price : prices)
    {
        if (price < minBuyPrice)
        {
            minBuyPrice = price;
        }
        else
        {
            maxProfit = std::max(maxProfit, price - minBuyPrice);
        }
    }

    return maxProfit;
}
`
    
}