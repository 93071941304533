export default {

info: `
<div>
    <h1>Three Sum</h1>
</div>
<div>
    <p>
        Given a list of integers, write a function that returns 
        all sets of 3 numbers in the list, a, b, and c, 
        so that a + b + c == 0.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'CPLUSPLUS', 'CSHARP'],

PYTHON: `
class Solution(object):
    def threeSum(self, nums):
        res, dups = set(), set()
        seen = {}
        for i, val1 in enumerate(nums):
            if val1 not in dups:
                dups.add(val1)
                for j, val2 in enumerate(nums[i+1:]):
                    complement = -val1 - val2
                    if complement in seen and seen[complement] == i:
                        res.add(tuple(sorted((val1, val2, complement))))
                    seen[val2] = i
        return res
`,

JAVA: `

`,

CPLUSPLUS: `
#include <vector>
#include <set>

class Solution {
    public:
        std::vector<std::vector<int>> threeSum(std::vector<int>& nums) {
            std::set<std::vector<int>> results;
            std::unordered_set<int> duplicates;
            std::unordered_map<int, int> seen;
            
            for (int i = 0; i < nums.size(); i++)
            {
                if (duplicates.find(nums[i]) == duplicates.end())
                {
                    duplicates.insert(nums[i]);
                    for (int j = i + 1; j < nums.size(); j++)
                    {
                        int complement = -nums[i] - nums[j];
                        auto it = seen.find(complement);
                        if (it != seen.end() && seen[complement] == i)
                        {
                            std::vector<int> triplet = {nums[i], nums[j], complement};
                            sort(triplet.begin(), triplet.end());
                            results.insert(triplet);
                        }
                        seen[nums[j]] = i
                    }
                }
            }
        }
    };
`,

CSHARP: `
using System.Collections.Generic;

namespace ThreeSum
{
    class Solution
    {
        List<List<int>> ThreeSum(List<int> numbers)
        {
            int numbersLength = numbers.Count;

            List<List<int>> results = new List<List<int>>();

            if (numbersLength < 3)
            {
                return results;
            }

            numbers.Sort();

            int iterationRange = numbersLength - 3;
            if (numbersLength <= 3)
            {
                iterationRange = 3;
            }

            for (int i = 0; i < iterationRange; i++)
            {
                if (i == 0 || numbers[i] > numbers[i - 1])
                {
                    int startIndex = i + 1;
                    int endIndex = numbersLength - 1;

                    while (startIndex < endIndex)
                    {
                        int currentSum = numbers[i] + numbers[startIndex] + numbers[endIndex];
                        if (currentSum == 0)
                        {
                            results.Add(new List<int> { numbers[i], numbers[startIndex], numbers[endIndex] });
                        }

                        if (currentSum < 0)
                        {
                            int currentStart = startIndex;
                            while (numbers[startIndex] == numbers[currentStart] && startIndex < endIndex)
                            {
                                startIndex++;
                            }
                        }
                        else
                        {
                            int currentEnd = endIndex;
                            while (numbers[endIndex] == numbers[currentEnd] && startIndex < endIndex)
                            {
                                endIndex--;
                            }
                        }
                    }
                }
            }

            return results;

        }
    }
}
`
    
}