export default {

info: `
<div>
    <h1>Longest Substring Without Repeating Characters</h1>
</div>
<div>
    <p>Given a string, find the length of the longest substring without repeating characters.</p>
</div>
<div>
    <p>Input: "abcabcbb"</p>
    <p>Output: 3</p>
    <p>Explanation: The answer is "abc", with the length of 3.</p>
</div>
<div>
    <p>Input: "abcabcbb"</p>
    <p>Output: 1</p>
    <p>Explanation: The answer is "b", with the length of 1.</p>
</div>
<div>
    <p>Input: "abcabcbb"</p>
    <p>Output: 3</p>
    <p>Explanation: The answer is "wke", with the length of 3.</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: 'Declare a variable that holds the length of the string',
        subs: []
    },
    {
        key: '2',
        description: 'Declare a data structure that keeps track of unique values',
        subs: []
    },
    {
        key: '3',
        description: 'Declare variable that hold current answer (ans), and pointers to beginning and end of window (i & j)',
        subs: []
    },
    {
        key: '4',
        description: `
             Create a while loop that continues while the points to the 
             begnning and end of the window are less than the variable that 
             holds the length of the string
        `,
        subs: []
    },
    {
        key: '5',
        description: `
             Check if the character at the end of the window pointer 
             is in the set. If it is not, add the character at that 
             current index to the set. Increment the end window pointer. 
             And update the answer to be the max of the current answer and 
             the value of the end window index minus the beginning window index
        `,
        subs: []

    },
    {
        key: '6',
        description: `
             If the character at the end of the window pointer is in the set,
             remove the string character at the beginning window pointer index
             from the set and then increment it.
        `,
        subs: []
    },
    {
        key: '7',
        description: 'Return answer',
        subs: []
    }
],

templates: {
    PYTHON: {
        "1": [1,2,3],
        "2": [1,2],
        "3": [1,2],
        "4": [1,2],
        "5": [1,2],
        "6": [1,2],
        "7": [1,2],
    },
    JAVA: {
        "1": [1,2,3],
        "2": [5,6,7],
        "3": [1,2],
        "4": [1,2],
        "5": [1,2],
        "6": [1,2],
        "7": [1,2],
    }
},

languages: [
    'PYTHON',
    'JAVA'
],

PYTHON: `
def len_of_longest_substring(string):
    n = len(string)
    record_set = set()
    ans = 0
    i = 0
    j = 0
    
    while (i < n) and (j < n):  
        if string[j] not in record_set:
            record_set.add(string[j])
            j += 1
            ans = max(ans, j - i)
        else:
            record_set.remove(string[i])
            i += 1
        
    return ans
`,

JAVA: `
public int lengthOfLongestSubstring(String s) {
    int n = s.length();
    Set<Character> set = new HashSet<>();
    int ans = 0, i = 0, j = 0;
    while (i < n && j < n) {
        if (!set.contains(s.charAt(j))) {
            set.add(s.charAt(j));
            j++;
            ans = Math.max(ans, j - i);
        } else {
            set.remove(s.charAt(i));
            i++;
        }
    }
    return ans;
}
`,

}