export default {

info: `
<div>
    <h1>Next Permutation</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def next_permutation(nums):
    
    i = len(nums) - 2
    
    while i >= 0 and nums[i + 1] <= nums[i]:
        i -= 1
        
    if i >= 0:
        j = len(nums) - 1
        while j >= 0 and nums[j] <= nums[i]:
            j -= 1
            
        swap(nums, i, j)
        
    reverse(nums, i + 1)
        
def swap(nums, i, j):
    temp = nums[i]
    nums[i] = nums[j]
    nums[j] = temp
    
def reverse(nums, start):
    left = start
    right = len(nums) - 1
    
    while left < right:
        swap(nums, left, right)
        left += 1
        right -= 1
`,

JAVA: `

`,
    
}