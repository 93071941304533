export default {

info: `
<div>
    <h1>Radix Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def radix_sort(arr, num_of_unique_digits=10): 
  
    num_of_unique_digits = max(arr) 
  
    exponent = 1
    while num_of_unique_digits // exponent > 0: 
        counting_sort(arr, exponent) 
        exponent *= 10
        
    return arr

def counting_sort(arr, exponentonent): 
  
    n = len(arr) 
  
    output = [0] * (n) 
  
    count = [0] * (10) 
  
    for i in range(0, n): 
        index = arr[i] // exponentonent 
        count[(index) % 10] += 1
  
    for i in range(1,10): 
        count[i] += count[i-1] 
  
    i = n - 1
    while i >= 0: 
        index = arr[i] // exponentonent 
        output[count[index % 10 ] - 1] = arr[i] 
        count[index % 10] -= 1
        i -= 1
  
    i = 0
    for i in range(0, len(arr)): 
        arr[i] = output[i] 
`,

JAVA: `

`,
    
}