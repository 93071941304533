<div class="pane-manager">

    <div class="pane-manager__body">

        <div [ngClass]="explorerClass">
            <!-- <div class="pane-manager__explorerTitle">
                Views
            </div> -->
            <ul>
                <li (click)="toggleOverview()" [ngClass]="overviewOpen ? 'selected' : 'not-selected'" style="width: 40px; height: 40px;">
                    <i style="font-size: 20px; color: #ccc;" class="fas fa-info-circle"></i>
                </li>
                <li *ngFor="let lang of langs" (click)="launchCodePane(lang)" [ngClass]="openPanes[lang.key] ? 'selected' : 'not-selected'">
                    <app-icon [lang]="lang.key"></app-icon>
                    <!-- <span>{{ lang.label }}</span> -->
                </li>
            </ul>
        </div>

        <div class="pane-manager__scrollBox" [ngStyle]="scrollBoxWidth">
            <app-info-pane 
                *ngIf="overviewOpen" 
                [task]="subtask"
                [data]="info"
                (deletePane)="deletePane($event)"
            ></app-info-pane>

            <app-code-pane 
                *ngFor="let pane of panes"
                [pane]="pane" 
                [task]="subtask" 
                [step]="step"
                [content]="pane.content"
                [templates]="pane.templates"
                (deletePane)="deletePane($event)"
            ></app-code-pane>
        </div>

    </div>

    <div [ngClass]="pseudocoderClass">
        <app-pseudocoder *ngIf="stepList.length > 0" [stepList]="stepList" (moveToStep)="moveToStep($event)"></app-pseudocoder>
        <div *ngIf="stepList.length == 0" class="empty-pseudocoder">
            <i class="far fa-file"></i>
        </div>
    </div>

    <button [ngClass]="explorerButtonClass" (click)="toggleExplorer()">
        <i *ngIf="explorerButtonClass == 'pane-manager__explorerButton--alt'" class="fas fa-angle-right"></i>
        <i *ngIf="explorerButtonClass == 'pane-manager__explorerButton'" class="fas fa-angle-left"></i>
    </button>

    <button [ngClass]="pseudocoderButtonClass" (click)="togglePseudocoder()">
        <i *ngIf="pseudocoderButtonClass == 'pane-manager__pseudocoderButton'" class="fas fa-angle-right"></i>
        <i *ngIf="pseudocoderButtonClass == 'pane-manager__pseudocoderButton--alt'" class="fas fa-angle-left"></i>
    </button>

</div>
