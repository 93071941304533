export default {

info: `
<div>
    <h1>Expression Add Operators</h1>
</div>
<div>
    <p>
        Given a string that contains only digits 0-9 and a target value, 
        return all possibilities to add binary operators (not unary) +, -, or * between 
        the digits so they evaluate to the target value.
    </p>
    <p>Input: num = "123", target = 6</p>
    <p>Output: ["1+2+3", "1*2*3"]</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def add_operators(num, target):
    results = []
    add_operators_recur(num, target, results, 0, 0, 0, [])
    return results

def add_operators_recur(num, target, results, index, curr_value, prev_value, progress):
    
    if index == len(num):
        if curr_value == target:
            results.append("".join(progress))
    else:
        new_curr_value = 0
        
        for i in range(index, len(num)):
            new_curr_value = (10 * new_curr_value) + int(num[i])
            
            if index == 0:
                add_operators_recur(num, target, results, i + 1, new_curr_value, 
                                    new_curr_value, progress + [str(new_curr_value)])
            else:
                
                # Multiplication
                temp_value = curr_value - prev_value
                
                next_curr_value = temp_value + (prev_value * new_curr_value)
                next_prev_value = prev_value * new_curr_value
                next_progress = progress + ["*" + str(new_curr_value)]
                
                add_operators_recur(num, target, results, i + 1, next_curr_value, 
                                    next_prev_value, next_progress)
                
                # Addition
                next_curr_value = curr_value - new_curr_value
                next_prev_value = -new_curr_value
                next_progress = progress + ["+" + str(new_curr_value)]
                
                add_operators_recur(num, target, results, i + 1, next_curr_value, 
                                    next_prev_value, next_progress)
                
                # Subtraction
                next_curr_value = curr_value + new_curr_value
                next_prev_value = new_curr_value
                next_progress = progress + ["-" + str(new_curr_value)]
                
                add_operators_recur(num, target, results, i + 1, next_curr_value, 
                                    next_prev_value, next_progress)
                
            if num[index] == "0":
                break
`,

JAVA: `

`,
    
}