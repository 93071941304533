export default {

info: `
<div>
    <h1>BST Insert Node</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Node:

    def __init__(self, value):
        self.value = value
        self.left = None
        self.right = None

def insert(node, value):

    if node is None:
        return Node(value)

    if value < node.value:
        node.left = insert(node.left, value)
    else:
        node.right = insert(node.right, value)

    return node
`,

JAVA: `

`,
    
}