export default {

info: `
<div>
    <h1>Add Binary</h1>
</div>
<div>
    <p>Given two binary strings, return their sum (also a binary string).</p>
    <p>The input strings are both non-empty and contains only characters 1 or 0.</p>
</div>
<div>
    <code>
        Input: a = "11", b = "1"
        Output: "100"
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Create an empty string to store the result.
        `,
    },
    {
        key: '2',
        description: `
            Create two variables that will represent the index in each
            string we are traversing. The indexes will start at the end.
            Like regular addition, when you add two numbers on paper, you compare
            the digits from right to left and decide if those digits create a number that needs
            to be carried over to the next set of digits to the left.
        `,
    },
    {
        key: '3',
        description: `
            Create a vairable to hold the carry and initialize it to zero
            because we have not done anything yet.
        `,
    },
    {
        key: '4',
        description: `
            Start a loop the continues while any of the indexes created above
            are still in their respective strings. We know we will be done when each hits
            -1. 
        `,
    },
    {
        key: '5',
        description: `
            Inside the loop, create a variable that will represent a running sum.
            At the beggining, it will just equal be the carry at 0. Because the function
            will only get two binary strings, we know that this running sum will only ever
            equal 0(0 + 0), 1(1 + 0), and 2(1 + 1).  
        `,
    },
    {
        key: '6',
        description: `
            Because we are only adding two strings, we just have to worry about
            two indexes. Similar to the check at the beginning of the loop, while
            each index is still valid in the respective string, add a the character at
            the index to the running sum as an integer.
        `,
    },
    {
        key: '7',
        description: `
            Then be sure the decrement the respective indexes.
        `,
    },
    {
        key: '8',
        description: `
            When dealing with binary numbers, modulo operations 
            have special characteristics. A modulo by 2 operation gives you the
            digit that will be put into the result. When we add the result of the modulo 2 operation, 
            note that we are appending it to the beginning fo the result because we 
            are calculating from right to left. 
        `,
    },
    {
        key: '9',
        description: `
            When dealing with binary numbers, division operations that
            round down have special characteristics. A division operation that rounds down will
            give you the value to carry over which will be put into the temporary sum when the
            loop starts over.
        `,
    },
    {
        key: '10',
        description: `
            Because of the nature of binary addition, we know that any type of carry will be a 1
            so add it at the begininng of the result.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6,7],
        '3': [9],
        '4': [11],
        '5': [12],
        '6': [14,15,18,19],
        '7': [16, 20],
        '8': [22],
        '9': [23],
        '10': [25,26]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def add_binary(a, b):
    
    result = ""
    
    a_index = len(a) - 1
    b_index = len(b) - 1
    
    carry = 0
    
    while a_index >= 0 or b_index >= 0:
        temp_sum = carry
        
        if a_index >= 0:
            temp_sum += int(a[a_index])
            a_index -= 1
            
        if b_index >= 0:
            temp_sum += int(b[b_index])
            b_index -= 1
            
        result = str(temp_sum % 2) + result
        carry = temp_sum // 2
        
    if carry > 0:
        result = "1" + result
        
    return result
`,

JAVA: `

`,
    
}