export default {

info: `
<div>
    <h1>Composite Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class Component(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def operation(self):
        pass


class Composite(Component):

    def __init__(self):
        self.children = set()

    def operation(self):
        for child in self.children:
            child.operation()

    def add(self, component):
        self.children.add(component)

    def remove(self, component):
        self.children.discard(component)


class Leaf(Component):

    def operation(self):
        pass


def main():
    leaf = Leaf()
    composite = Composite()
    composite.add(leaf)
    composite.operation()
`,

JAVA: `

`,
    
}