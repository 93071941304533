export default {

info: `
<div>
    <h1>Fewest Coins to Make Change</h1>
</div>
<div>
    <p>
        You are given coins of different denominations and a 
        total amount of money amount. Write a function to compute 
        the fewest number of coins that you need to make up that amount. 
        If that amount of money cannot be made up by any combination of 
        the coins, return -1.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def fewest_coins_to_make_change(coins, target_amount):
    
    dp = [(target_amount + 1) for i in range(target_amount + 1)]
    dp[0] = 0

    for amount in range(target_amount + 1):
        for coin in coins:
            if coin <= amount:
                dp[amount] = min(1 + dp[amount-coin], dp[amount])

    return dp[-1] if dp[-1] <= target_amount else -1
`,

JAVA: `

`,
    
}