export default {

info: `
<div>
    <h1>Builder Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class ComplexObject:

    def set_attribute_one(self):
        pass

    def set_attribute_two(self):
        pass

class Builder:

    def __init__(self, complex_object):
        self.complex_object = ComplexObject()

    def build_attribute_one(self):
        self.complex_object.set_attribute_one()

    def build_attribute_two(self):
        self.complex_object.set_attribute_two()

    def get_complex_object(self):
        return self.complex_object

class Director:

    def __init__(self, builder):
        self.builder = builder

    def build_complex_object(self):
        self.builder.build_attribute_one()
        self.builder.build_attribute_two()

    def get_complex_object(self):
        self.builder.get_complex_object()
`,

JAVA: `

`,
    
}