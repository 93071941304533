export default {

info: `
<div>
    <h1>Longest Palindromic Substring</h1>
</div>
<div>
    <p>
        Given a string s, find the longest palindromic 
        substring in s. You may assume that the maximum length 
        of s is 1000
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def longest_palindromic_substring(string):
    
    str_len = len(string)
    
    cache = [[0 for col in range(str_len)] for row in range(str_len)]
    
    # Process substrings for length 1
    max_length = 1
    for i in range(str_len):
        cache[i][i] = True
        
    # Process substrings for length 2
    start = 0
    for i in range(str_len - 1):
        if string[i] == string[i+1]:
            cache[i][i+1] = True
            start = i
            max_length = 2
            
    # Process substrings greater than length 2
    for sub_len in range(3, str_len + 1):
        for i in range(str_len-sub_len+1):
            sub_end_index = i + sub_len - 1
            
            if cache[i+1][sub_end_index-1] and string[i] == string[sub_end_index]:
                cache[i][sub_end_index] = True
                if sub_len > max_length:
                    start = i
                    max_length = sub_len
                
    return string[start:start+max_length]
`,

JAVA: `

`,
    
}