export default {

info: `
<div>
    <h1>Interpreter Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class AbstractExpression(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def interpret(self):
        pass


class NonterminalExpression(AbstractExpression):

    def __init__(self, expression):
        self.expression = expression

    def interpret(self):
        self.expression.interpret()


class TerminalExpression(AbstractExpression):

    def interpret(self):
        pass


def main():
    abstract_syntax_tree = NonterminalExpression(TerminalExpression())
    abstract_syntax_tree.interpret()

`,

JAVA: `

`,
    
}