export default {

info: `
<div>
    <h1>Composite Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class Invoker:

    def __init__(self):
        self.commands = []

    def store_command(self, command):
        self.commands.append(command)

    def executecommands(self):
        for command in self.commands:
            command.execute()


class Command(metaclass=abc.ABCMeta):

    def __init__(self, receiver):
        self.receiver = receiver

    @abc.abstractmethod
    def execute(self):
        pass


class ConcreteCommand(Command):

    def execute(self):
        self.receiver.action()


class Receiver:

    def action(self):
        pass


def main():
    receiver = Receiver()
    concrete_command = ConcreteCommand(receiver)
    invoker = Invoker()
    invoker.store_command(concrete_command)
    invoker.executecommands()
`,

JAVA: `

`,
    
}