export default {

info: `
<div>
    <h1>Path Sum II</h1>
</div>
<div>
    <p>
        Given a binary tree and a sum, find all root-to-leaf 
        paths where each path's sum equals the given sum.
    </p>
</div>
<div>
    <code>
         5
        / \
       4   8
      /   / \
     11  13  4
    /  \    / \
   7    2  5   1     
    </code>
    <code>
        [
            [5,4,11,2],
            [5,8,4,5]
        ]
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def path_sum(root, target):
    
    results = []
    
    sum_util(root, target, [], results)
    
    return results

def sum_util(node, target, progress, results):
    
    if node is None or target < 0:
        return
    
    progress.append(node.value)
    
    if node.value == target and (node.left is None and node.right is None):
        results.append(progress)
        return
        
    sum_util(node.left, target - node.value, list(progress), results)
    sum_util(node.right, target - node.value, list(progress), results)
`,

JAVA: `

`,
    
}