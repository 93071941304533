export default {

info: `
<div>
    <h1>Bubble Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def bubble_sort(arr):

    arr_len = len(arr) - 1
    is_sorted = False

    while not is_sorted:
        is_sorted = True

        for i in range(arr_len):

            if arr[i] > arr[i+1]:
                is_sorted = False
                swap(arr, i, i + 1)
                
    return arr

def swap(arr, index_one, index_two):
    arr[index_one], arr[index_two] = arr[index_two], arr[index_one]
`,

JAVA: `

`,
    
}