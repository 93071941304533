export default {

info: `
<div>
    <h1>String Edit Distance</h1>
</div>
<div>
    <p>
        Given two words word1 and word2, find the minimum number 
        of operations required to convert word1 to word2.
    </p>
</div>
<div>
    <p>You have the following 3 operations permitted on a word:</p>
    <p>1. Insert a character</p>
    <p>2. Delete a character</p>
    <p>3. Replace a character</p>
    <p>Input: word1 = "horse", word2 = "ros"</p>
    <p>Output: 3</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Because we can solve this programming with dynamic programming, we will
            start off by creating our table where row indexes represent each
            character in the target string and columns represent each character
            in the starting string. We add one more character to each to represent
            an empty character at the beginning of each string.
        `,
    },
    {
        key: '2',
        description: `
            To start iterating over this table (matrix), we will create an outer
            loop that represents our rows (the target string) and then an inner
            loop that represents our columns (the starting string). Note that we added
            an index for the empty character in the strings so the loops 
            need to account for this.
        `,
    },
    {
        key: '3',
        description: `
            Now we can decide how we want to fill out each cell in the tabulation
            table for this dynamic programming problem. If the row is zero, that means we
            are comparing each character in the starting string to an empty string character
            for the target string. This tells us all we have to do is delete at this point
            so each cell reprents how many characters to delete at this point which should
            conveniately is just the column index.
        `,
    },
    {
        key: '4',
        description: `
            If the column index is zero, that means we are comparing an empty character 
            in the starting string to each character in the target string. This tells us all we have 
            to do is add a character at this point so each cell reprents how many characters 
            to add at this point. This time, we can conveniently put row indexes at each cell
        `,
    },
    {
        key: '5',
        description: `
            Now we will do the more complicated tabulations. The first check is to see
            if the starting string letter is equal to the target string letter
            at the same index. The indexes are subtracted by one because we added
            a empty character to both strings.
        `,
    },
    {
        key: '6',
        description: `
            If the condition is met, then the we take no action so the current cell just equals the
            cell at the point if no operation was done (one row and column back).
        `,
    },
    {
        key: '7',
        description: `
            If we hit this else condition, that means we will take some type of action. Because this
            is a problem where we are trying to find the minimum, we take the minimum between three cells;
            the one a row back, a column back, and a row and column back. We made another opation so add
            one to this minimum.
        `,
    },
    {
        key: '8',
        description: `
            Now we can just return out the answer which is the last cell in our tabulation table.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6,7],
        '3': [8,9],
        '4': [10,11],
        '5': [13],
        '6': [14],
        '7': [16],
        '8': [18]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def string_edit_distance(str_start, str_end):
    
    dp = [[0 for col in range(len(str_start) + 1)] for row in range(len(str_end) + 1)]
    
    for row in range(len(str_end) + 1):
        for col in range(len(str_start) + 1):
            if row == 0:
                dp[row][col] = col
            elif col == 0:
                dp[row][col] = row
            else:
                if str_start[col-1] == str_end[row-1]:
                    dp[row][col] = dp[row-1][col-1]
                else:
                    dp[row][col] = min(dp[row-1][col-1],dp[row][col-1], dp[row-1][col]) + 1
    
    return dp[len(str_end)][len(str_start)]
`,

JAVA: `

`,
    
}