export default {

info: `
<div>
    <h1>Partition Labels</h1>
</div>
<div>
    <p>
        A string S of lowercase letters is given. We want to partition 
        this string into as many parts as possible so that each letter 
        appears in at most one part, and return a list of integers 
        representing the size of these parts.
    </p>
</div>
<div>
    <code>
        Input: S = "ababcbacadefegdehijhklij"
        Output: [9,7,8]
        Explanation:
        The partition is "ababcbaca", "defegde", "hijhklij".
        This is a partition so that each letter appears in at most one part.
        A partition like "ababcbacadefegde", "hijhklij" is incorrect, because it splits S into less parts.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def partition_labels(S):
    
    last_indexes = {}
    for i, c in enumerate(S):
        last_indexes[c] = i

    curr_max = 0
    result = []
    count = 0

    for i, char in enumerate(S):
        count += 1
        curr_max = max(curr_max, last_indexes[char])

        if curr_max == i:
            result.append(count)
            count = 0
            
    return result
`,

JAVA: `

`,
    
}