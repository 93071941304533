export default {

info: `
<div>
    <h1>Find First and Last Position of Element in Sorted Array</h1>
</div>
<div>
    <p>Given an array of integers nums sorted in ascending order, find the starting and ending position of a given target value.</p>
    <p>If target is not found in the array, return [-1, -1].</p>
    <p>You must write an algorithm with O(log n) runtime complexity.</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['CPLUSPLUS', 'SCALA'],

CPLUSPLUS: `
#include <vector>

class Solution {
public:
    
    int findBound(std::vector<int>&nums, int target, bool isFirst)
    {
        int start = 0;
        int end = nums.size() - 1;
        
        while (start <= end)
        {
            int middle = start + (end - start) / 2;
            
            if (nums[middle] == target)
            {
                if (isFirst)
                {
                    if (middle == start || nums[middle - 1] < target)
                        return middle;

                    end = middle - 1;
                }
                else
                {
                    if (middle == end || nums[middle + 1] > target)
                        return middle;

                    start = middle + 1;
                }    
            }
            else if (nums[middle] > target)
            {
                end = middle - 1;
            }
            else
            {
                start = middle + 1;
            }
        }
        return -1;
    }
    
    std::vector<int> searchRange(std::vector<int>& nums, int target) {
        
        int lowerBound = findBound(nums, target, true);
        
        if (lowerBound == -1) 
        {
            std::vector<int> result(2, -1);    
        }
        
        int upperBound = findBound(nums, target, false);
        
        std::vector<int> finalResult = { lowerBound, upperBound };  
        
        return finalResult;
    }
};
`,

SCALA: `
object Solution {
    
    def searchRange(nums: Array[Int], target: Int): Array[Int] = {
       
        var lowerBound = findBound(nums, target, true)
       
        if (lowerBound == - 1) {
            return Array(-1, -1)
        }
       
        var upperBound = findBound(nums, target, false)
       
        return Array(lowerBound, upperBound)
    }
   
    def findBound(nums: Array[Int], target: Int, isFirst: Boolean): Int = {
       
        var begin = 0
        var end = nums.size - 1
       
        while (begin <= end) {
           
            var middle = begin + (end - begin) / 2
           
            if (nums(middle) == target) {
               
                if (isFirst) {
                    if (middle == begin || nums(middle - 1) < target) {
                        return middle
                    }
                   
                    end = middle - 1
                } else {
                    if (middle == end || nums(middle + 1) > target) {
                        return middle
                    }
                   
                    begin = middle + 1
                }
               
            } else if (nums(middle) > target) {
                end = middle - 1
            } else {
                begin = middle + 1
            }
           
        }
       
        return -1
    }
}
`,
    
}