import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-code-pane',
  templateUrl: './code-pane.component.html',
  styleUrls: ['./code-pane.component.scss']
})
export class CodePaneComponent implements OnInit {

    @Input() task: string = '';
    @Input() step: string = '';
    @Input() pane: any;
    @Output() deletePane: EventEmitter<any> = new EventEmitter();

    @Input() content: string = '';
    @Input() templates: any;
    options: any;
    screenOpen: boolean = false;

    constructor(
    ) { }

    ngOnInit() {
        this.options = {
            lineNumbers: true,
            theme: 'material',
            mode: this.pane.editor,
            readOnly: true
        }
        //this.codeMaster.codeMasterRequester(this.pane.key, this.task, this.callback());
    }

    ngOnChanges() {

        if (this.pane.editor === 'OVERVIEW') return;

        const container = document.getElementById(this.pane.editor);

        if (this.step !== '') {
            const highlights = this.templates[this.pane.key][this.step];
            if (container) {
                let codeBox = container.getElementsByClassName("CodeMirror-code")[0];
                for (let i = 1; i < codeBox.children.length; i++) {
                    if (highlights.includes(i)) {
                        codeBox.children[i-1].className = 'app-highlight';
                    } else {
                        codeBox.children[i-1].className = '';
                    }
                }
            }
        } else {
            if (container) {
                let codeBox = container.getElementsByClassName("CodeMirror-code")[0];
                for (let i = 1; i < codeBox.children.length; i++) {
                    codeBox.children[i-1].className = '';
                }
            }
        }
    }

    onDelete(key: string) {
        this.deletePane.emit({ key });
    }

}
