export default {

info: `
<div>
    <h1>Odd Even Jump</h1>
</div>
<div>
    <p>
        You are given an integer array A. From some starting index, 
        you can make a series of jumps.  The (1st, 3rd, 5th, ...) jumps 
        in the series are called odd numbered jumps, and the (2nd, 4th, 6th, ...) 
        jumps in the series are called even numbered jumps.
    </p>
    <p>
        You may from index i jump forward to index j (with i < j) in the following way:
    </p>
    <ul>
        <li>
            During odd numbered jumps (ie. jumps 1, 3, 5, ...), you jump 
            to the index j such that A[i] <= A[j] and A[j] is the smallest 
            possible value.  If there are multiple such indexes j, you can 
            only jump to the smallest such index j.
        </li>
        <li>
            During even numbered jumps (ie. jumps 2, 4, 6, ...), you jump 
            to the index j such that A[i] >= A[j] and A[j] is the largest 
            possible value.  If there are multiple such indexes j, you can 
            only jump to the smallest such index j.
        </li>
        <li>(It may be the case that for some index i, there are no legal jumps.)</li>
    </ul>
    <p>
        A starting index is good if, starting from that index, you can reach 
        the end of the array (index A.length - 1) by jumping some number 
        of times (possibly 0 or more than once.)
    </p>
    <p>Return the number of good starting indexes.</p>
</div>
<div>
    <code>
        Input: [10,13,12,14,15]
        Output: 2
        Explanation:

        From starting index i = 0, we can jump to i = 2 (since A[2] is the smallest among A[1], A[2], A[3], A[4] 
        that is greater or equal to A[0]), then we can't jump any more.

        From starting index i = 1 and i = 2, we can jump to i = 3, then we can't jump any more.

        From starting index i = 3, we can jump to i = 4, so we've reached the end.

        From starting index i = 4, we've reached the end already.

        In total, there are 2 different starting indexes (i = 3, i = 4) where 
        we can reach the end with some number of jumps.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def odd_even_jumps(nums):

    nums_len = len(nums)

    sorted_indexes = sorted(range(nums_len), key = lambda i: nums[i])
    odd_next = make_monotonic_stack(sorted_indexes)

    sorted_indexes.sort(key = lambda i: nums[i], reverse = True)
    even_next = make_monotonic_stack(sorted_indexes)

    odd = [False] * nums_len
    even = [False] * nums_len

    odd[nums_len-1] = even[nums_len-1] = True

    for i in range(nums_len-2, -1, -1):

        if odd_next[i] is not None:
            odd[i] = even[odd_next[i]]

        if even_next[i] is not None:
            even[i] = odd[even_next[i]]

    return sum(odd)


def make_monotonic_stack(sorted_indexes):
    result = [None] * len(sorted_indexes)
    stack = []
    
    for i in sorted_indexes:
        while stack and i > stack[-1]:
            result[stack.pop()] = i
        stack.append(i)

    del stack
    
    return result
`,

JAVA: `

`,
    
}