export default {

info: `
<div>
    <h1>Tim Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def tim_sort(arr, RUN = 32):
    
    for x in range(0, len(arr), RUN):
        arr[x : x + RUN] = insertion_sort(arr[x : x + RUN])
        
    RUN_LEN = RUN
    
    while RUN_LEN < len(arr):
        
        for x in range(0, len(arr), 2 * RUN_LEN):
            arr[x : x + 2 * RUN_LEN] = merge(arr[x : x + RUN_LEN], arr[x + RUN_LEN: x + 2 * RUN_LEN])
            
        RUN_LEN = RUN_LEN * 2
        
    return arr

def insertion_sort(arr):

    for x in range (1, len(arr)):
        for i in range(x, 0, -1):
            if arr[i] < arr[i - 1]:
                t = arr[i]
                arr[i] = arr[i - 1]
                arr[i - 1] = t
            else:
                break
            i = i - 1
    return arr

def merge(a_arr, b_arr):
    
    a_index = 0
    b_index = 0
    result_arr = []

    while a_index < len(a_arr) and b_index < len(b_arr):
    
        if a_arr[a_index] < b_arr[b_index]:
        
            result_arr.append(a_arr[a_index])
            a_index += 1

        elif a_arr[a_index] > b_arr[b_index]:

            result_arr.append(b_arr[b_index])
            b_index += 1
            
        else:

            result_arr.append(a_arr[a_index])
            result_arr.append(b_arr[b_index])
            a_index += 1
            b_index += 1

    while a_index < len(a_arr):
        result_arr.append(a_arr[a_index])
        a_index += 1

    while b_index < len(b_arr):
        result_arr.append(b_arr[b_index])
        b_index += 1

    return result_arr
`,

JAVA: `

`,
    
}