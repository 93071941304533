export default {

info: `
<div>
    <h1>SM-2 Spaced Repetition Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import datetime

def get_interval(card, quality):
    
    repetitions = card.get_repetitions()
    easiness_factor = card.get_easiness_factor()
    interval_in_days = card.get_interval()
    
    if quality < 3:
        repetitions = 0
    
    easiness_factor = easiness_factor + (0.1 - (5.0 - quality) * (0.08 + (5.0 - quality) * 0.02))
    
    if easiness_factor < 1.3:
        easiness_factor = 1.3
        
    if repetitions <= 1:
        interval_in_days = 1
    elif repetitions == 2:
        interval_in_days = 6
    else:
        interval_in_days = round(interval_in_days * easiness_factor)
        
    todays_date = datetime.datetime.now()    
    next_review_date = todays_date += datetime.timedelta(days=interval_in_days)
    
    card.set_repetitions(repetitions)
    card.set_easiness_factor(easiness_factor)
    card.set_interval(interval_in_days)
    card.set_next_review_date(next_review_date)
    
    return next_review_date
`,

JAVA: `

`,
    
}