export default {

info: `
<div>
    <h1>Remove Duplicates of Sorted Array</h1>
</div>
<div>
    <p>
        Given a sorted array nums, remove the duplicates in-place 
        such that each element appear only once and return the new length
    </p>
    <p>
        Do not allocate extra space for another array, you must do 
        this by modifying the input array in-place with O(1) extra memory.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def remove_duplicates(nums):
    
    head = 0
    
    for i in range(1, len(nums)):
        if nums[i] != nums[head]:
            head += 1
            nums[head] = nums[i]
            
    return head + 1
`,

JAVA: `

`,
    
}