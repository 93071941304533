export default {

info: `
<div>
    <h1>Pow(x, n)</h1>
</div>
<div>
    <p>Implement pow(x, n), which calculates x raised to the power n (xn).</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def power(base, power):

    adj_power = power

    if adj_power < 0:
        base = 1 / base
        adj_power = -adj_power
        
    return fast_power(base, adj_power)

def fast_power(base, power):
    
    if power == 0:
        return 1.0
    
    half = fast_power(base, power // 2)
    
    if (power % 2) == 0:
        return half * half
    else:
        return half * half * base
`,

JAVA: `

`,
    
}