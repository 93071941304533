export default {

info: `
<div>
    <h1>Binary Tree Level Order Traversal</h1>
</div>
<div>
    <p>Given a binary tree, return the level order traversal of its nodes' values. (ie, from left to right, level by level).</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def level_order_traversal(root):
    
    if root is None:
        return list()
    
    levels_list = []
    
    queue = []
    queue.append(root)
    
    while queue:
        
        current_layer = []
        
        layer_size = len(queue)
        
        for i in range(layer_size):
            current_node = queue.pop(0)
            
            current_layer.append(current_node.value)
            
            if current_node.left is not None:
                queue.append(current_node.left)
                
            if current_node.right is not None:
                queue.append(current_node.right)
                
        levels_list.append(current_layer)
        
    return levels_list
`,

JAVA: `

`,
    
}