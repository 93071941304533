export default {

info: `
<div>
    <h1>Kth Largest or Smallest Element</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def kth_largest(arr, kth_largest):
    
    target_index = len(arr) - kth_largest
    
    return quickish_sort(arr, target_index)

def quickish_sort(arr, target_index, lo=None, hi=None):
    if lo is None:
        lo = 0
    if hi is None:
        hi = len(arr) - 1
    
    if lo < hi:
        p = partition(arr, lo, hi)
        if p == target_index:
            return arr[p]
        elif p > target_index:
            hi = p - 1
        else:
            low = p + 1
            
        return quickish_sort(arr, target_index, lo, hi)
    
        
def partition(arr, lo=None, hi=None):
    pivot = arr[hi]
    pivot_loc = lo
    
    for i in range(lo,hi):
        if arr[i] <= pivot:
            swap(arr, pivot_loc, i)
            pivot_loc += 1
            
    swap(arr, pivot_loc, hi)
    
    return pivot_loc

def swap(arr, index_one, index_two):
    temp = arr[index_one]
    arr[index_one] = arr[index_two]
    arr[index_two] = temp
`,

JAVA: `

`,
    
}