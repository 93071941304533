export default {

info: `
<div>
    <h1>Linked List Cycle</h1>
</div>
<div>
    <p>Given a linked list, determine if it has a cycle in it.</p>
    <p>
        To represent a cycle in the given linked list, we use an integer pos 
        which represents the position (0-indexed) in the linked list where 
        tail connects to. If pos is -1, then there is no cycle in the linked list.
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We will first do a quick edge case test to see if a linked list was
            even passed in.
        `,
    },
    {
        key: '2',
        description: `
            Then we will create our fast and slow points at their starting positions.
            The slow pointer will be at the head and the fast will be at one link
            ahead of the head.
        `,
    },
    {
        key: '3',
        description: `
            Start a loop that goes until both pointers reach the end of the linked list.
            Inside, if there is a cycle, we will have it terminate.
        `,
    },
    {
        key: '4',
        description: `
            The terminating condition will be if our fast pointer ever equals our
            slow pointer.
        `,
    },
    {
        key: '5',
        description: `
            Then we increment the fast pointer making sure it jumps ahead by two links.
        `,
    },
    {
        key: '6',
        description: `
            Then we make sure we increment the slow pointer by just one link.
        `,
    },
    {
        key: '7',
        description: `
            If we made it two this point, then the answer is just false.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4,5],
        '2': [7,8],
        '3': [10],
        '4': [11,12],
        '5': [13],
        '6': [14],
        '7': [16],
    },
    JAVA: {
        '1': [4,5,6],
        '2': [8,9],
        '3': [11],
        '4': [12,13,14],
        '5': [15],
        '6': [16],
        '7': [18],
    }
},

languages: ['PYTHON', 'JAVA'],

PYTHON: `
def has_cycle(head):

    if head == None:
        return False
    
    fast = head.next
    slow = head
    
    while ((fast != None and fast.next != None) and (slow != None)):
        if fast == slow:
            return True
        fast = fast.next.next
        slow = slow.next
        
    return False
`,

JAVA: `
public boolean hasCycle(Node head) {

    if (head == null) {
        return false;
    }

    Node fast = head.next;
    Node slow = head;

    while (fast != null && fast.next != null && slow != null) {
        if (fast == slow) {
            return true;
        }
        fast = fast.next.next;
        slow = slow.next;
    }
    return false;
}
`,
    
}