export default {

info: `
<div>
    <h1>Multi-threading</h1>
</div>
<div>
    <p>
        The purpose of this program is to show that several long running
        processes can run currently and that we make sure our program
        does something once complete
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['CSHARP', 'CPLUSPLUS', 'SWIFT', 'KOTLIN'],

CPLUSPLUS: `
#include <iostream>
#include <chrono>
#include <windows.h>
#include <vector>
#include <thread>

void ProcessA() {
    Sleep(3000);
    std::cout << "Start Process A Complete..." << std::endl;
}

void ProcessB() {
    Sleep(6000);
    std::cout << "Start Process B Complete..." << std::endl;
}

int main()
{

    std::vector<std::thread> threadList{};

    threadList.push_back(std::thread(ProcessA));
    threadList.push_back(std::thread(ProcessB));

    auto start = std::chrono::high_resolution_clock::now();

    for (auto& thread : threadList)
    {
        thread.join();
    }

    auto stop = std::chrono::high_resolution_clock::now();

    auto duration = std::chrono::duration_cast<std::chrono::milliseconds>(stop - start);

    std::cout << "Work Complete in " << duration.count() << " milliseconds" << std::endl;

    std::cin.get();
}
`,

CSHARP: `
using System;
using System.Threading;
using System.Threading.Tasks;

namespace MultiThreading
{
    class Program
    {
        static async Task ProcessA()
        {
            Thread.Sleep(3000);
            Console.WriteLine("Start Process A Complete...");
        }

        static async Task ProcessB()
        {
            Thread.Sleep(6000);
            Console.WriteLine("Start Process B Complete...");
        }

        static void Main(string[] args)
        {

            var watch = System.Diagnostics.Stopwatch.StartNew();

            var b = Task.Run(() => ProcessA());

            var a = Task.Run(() => ProcessB());

            Task.WaitAll(a, b);

            watch.Stop();

            Console.WriteLine($"Work Complete in {watch.ElapsedMilliseconds} milliseconds");

            Console.ReadLine();
        }
    }
}
`,

SWIFT: `
import UIKit
import PlaygroundSupport

class PlaygroundViewController: UIViewController {

    let dispatchGroup = DispatchGroup()

    override func viewDidLoad() {
        main()
    }

    func processA() {
        print("Process A Complete...")
    }

    func processB() {
        print("Process B Complete...")
    }

    func main() {

        let start = DispatchTime.now()

        self.dispatchGroup.enter()
        DispatchQueue.global().asyncAfter(deadline: .now() + 3) {
            self.processA()
            self.dispatchGroup.leave()
        }

        self.dispatchGroup.enter()
        DispatchQueue.global().asyncAfter(deadline: .now() + 6) {
            self.processB()
            self.dispatchGroup.leave()
        }

        dispatchGroup.notify(queue: .main) {
            let end = DispatchTime.now()
            let time = (end.uptimeNanoseconds - start.uptimeNanoseconds) / 1000000
            print("Work complete in \\(time) milliseconds")
        }
    }
}

PlaygroundPage.current.liveView = PlaygroundViewController()
`,

KOTLIN: `
import kotlinx.coroutines.*

suspend fun processA() {
    delay(3000)
    println("Process A Complete...")
}

suspend fun processB() {
    delay(6000)
    println("Process B Complete...")
}

fun main() = runBlocking {
	val begin = System.currentTimeMillis()

    launch {
        processA()
    }	
    launch {
        processB()
    }
	
    val end = System.currentTimeMillis()
    
    println("Work Complete in \${end-begin} milliseconds")
}
`
    
}