export default {

info: `
<div>
    <h1>Longest Increasing Subsequence</h1>
</div>
<div>
    <p>
        Given an array of all integers that may or may not be sorted, 
        determine the length of the longest subsequence that is strictly non-decreasing
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def longest_increasing_subsequence(nums):
    
    dp = [1 for i in range(0, len(nums))]
    
    max_so_far = 1
    
    for i in range(1, len(nums)):
        
        for j in range(0, i):
            
            if (nums[i] > nums[j]):
                dp[i] = max(dp[i], dp[j] + 1);
                
        max_so_far = max(max_so_far, dp[i]);
        
    return max_so_far
`,

JAVA: `

`,
    
}