export default {

info: `
<div>
    <h1>Bucket Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def bucket_sort(arr, bucket_size=5):

    if len(arr) == 0:
        return arr

    min_value = arr[0]
    max_value = arr[0]
    for i in range(1, len(arr)):
        if arr[i] < min_value:
            min_value = arr[i]
        elif arr[i] > max_value:
            max_value = arr[i]

    bucket_count = ((max_value - min_value) // bucket_size) + 1
    buckets = []
    for i in range(0, bucket_count):
        buckets.append([])

    for i in range(0, len(arr)):
        buckets[((max_value - min_value) // bucket_size)].append(arr[i])

    sorted_arr = []
    for i in range(0, len(buckets)):
        insertion_sort(buckets[i])
        for j in range(0, len(buckets[i])):
            sorted_arr.append(buckets[i][j])

    return sorted_arr
 
def insertion_sort(arr):
    for i in range(1, len(arr)):
        temp = arr[i]
        j = i - 1
        while (j >= 0 and temp < arr[j]):
            arr[j + 1] = arr[j]
            j = j - 1
        arr[j + 1] = temp
`,

JAVA: `

`,
    
}