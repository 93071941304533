export default {

info: `
<div>
    <h1>Sudoku Solver</h1>
</div>
<div>
    <p>Implement a Sudoku solver. (a classic Sudoku board is 9x9)</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import math 

EMPTY_ENTRY = '.'

def solve_sudoku(board):
    if solve_sudoku_cell(0, 0, board):
        return board

def solve_sudoku_cell(row, col, board):
    
    if col == len(board[row]):
        col = 0
        row += 1
        
        if row == len(board):
            return True
        
    if board[row][col] != EMPTY_ENTRY:
        return solve_sudoku_cell(row, col + 1, board)
    
    for value in ["1","2","3","4","5","6","7","8","9"]:        
        if can_place_value(board, row, col, value):
            board[row][col] = value
            if solve_sudoku_cell(row, col + 1, board):
                return True
            
    board[row][col] = EMPTY_ENTRY
    return False

def can_place_value(board, row, col, char_to_place):
    
    for row_index in range(9):
        if board[row_index][col] == char_to_place:
            return False
        
    for col_index in range(9):
        if board[row][col_index] == char_to_place:
            return False
        
    region_size = int(math.sqrt(len(board)))
    
    nth_sub_box_row = row // region_size
    nth_sub_box_col = col // region_size
    
    top_left_of_sub_box_row = region_size * nth_sub_box_row
    top_left_of_sub_box_col = region_size * nth_sub_box_col
    
    for i in range(region_size):
        for j in range(region_size):
            if char_to_place == board[top_left_of_sub_box_row + i][top_left_of_sub_box_col + j]:
                return False
            
    return True
`,

JAVA: `

`,
    
}