export default {

info: `
<div>
    <h1>Generate Paranthesis</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def generate_parenthesis(num_pairs):
    
    result = []

    recur(num_pairs, num_pairs, '', result)

    return result

def recur(num_left_parens_needed, num_right_parens_needed, progress, result):

    if (num_left_parens_needed == 0 and num_right_parens_needed == 0):
            result.append(progress)
            return
        
    if num_left_parens_needed > 0:
        
        recur(num_left_parens_needed - 1, num_right_parens_needed, progress + '(', result)
        
    if num_left_parens_needed < num_right_parens_needed:
        
        recur(num_left_parens_needed, num_right_parens_needed - 1, progress + ')', result)
`,

JAVA: `

`,
    
}