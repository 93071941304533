export default {

info: `
<div>
    <h1>Minimum Window Substring</h1>
</div>
<div>
    <p>
        Given a string S and a string T, find the minimum window in S which will 
        contain all the characters in T (matching or exceeding in frequency) in 
        complexity O(n).
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def minimum_window_substring(search_str, required_str):
    
    required_characters = {}
    for char in required_str:
        increment_char_map(required_characters, char)
        
    window_character_mapping = {}
    
    left = 0
    right = 0
    
    total_char_frequencies_to_match = len(required_characters)
    char_frequencies_in_window_that_match = 0
    
    min_window_len_sen_so_far = float('inf')
    min_window = ''
    
    while right < len(search_str):
        
        character_at_right_pointer = search_str[right]
        
        increment_char_map(window_character_mapping, character_at_right_pointer)
        
        right_char_is_a_requirement = True if character_at_right_pointer in required_characters else False
        
        if right_char_is_a_requirement:
            
            requirement_for_character_met = False
            if window_character_mapping[character_at_right_pointer] == required_characters[character_at_right_pointer]:
                requirement_for_character_met = True
                
            if requirement_for_character_met:
                char_frequencies_in_window_that_match += 1
            
        while char_frequencies_in_window_that_match == total_char_frequencies_to_match and left <= right:
            
            character_at_left_pointer = search_str[left]
            window_size = right - left + 1
            
            if window_size < min_window_len_sen_so_far:
                min_window_len_sen_so_far = window_size
                min_window = search_str[left: right + 1]
                
            decrement_char_map(window_character_mapping, character_at_left_pointer)
            
            left_char_is_a_requirement = True if character_at_left_pointer in required_characters else False
            
            if left_char_is_a_requirement:
                
                character_fails_requirement = False
                if window_character_mapping[character_at_left_pointer] < required_characters[character_at_left_pointer]:
                    character_fails_requirement = True
                    
                if character_fails_requirement:
                    char_frequencies_in_window_that_match -= 1
                    
            left += 1
            
        right += 1
        
    return min_window

def decrement_char_map(char_map, char):
    if char in char_map:
        char_map[char] -= 1

def increment_char_map(char_map, char):
    if char in char_map:
        char_map[char] += 1
    else:
        char_map[char] = 1
`,

JAVA: `

`,
    
}