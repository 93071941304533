export default {

info: `
<div>
    <h1>Max Sub Array</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def max_sum(num_list, window_length):
    if window_length > len(num_list):
        return None
    
    max_sum = 0
    
    for index, value in enumerate(num_list):
        if index == window_length:
            break
        max_sum += num_list[index]
        
    temp_sum = max_sum
    
    index = window_length
    while index < len(num_list):
        temp_sum = temp_sum - num_list[index - window_length] + num_list[index]
        max_sum = max([max_sum, temp_sum])
        index += 1
    
    return max_sum
`,

JAVA: `

`,
    
}