export default {

info: `
<div>
    <h1>Serialize / Deserialize BST</h1>
</div>
<div>
    <p>
        Serialization is the process of converting a data structure or 
        object into a sequence of bits so that it can be stored in a 
        file or memory buffer, or transmitted across a network connection 
        link to be reconstructed later in the same or another computer environment.
    </p>
    <p>
        Design an algorithm to serialize and deserialize a binary search tree. 
        There is no restriction on how your serialization/deserialization 
        algorithm should work. You just need to ensure that a binary search 
        tree can be serialized to a string and this string can be deserialized 
        to the original tree structure.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def serialize_bst(bst):
    
    def recur(node):
        
        if node == None:
            return 'x,'
        
        left_serialized = recur(node.left)
        
        right_serialized = recur(node.right)
        
        return str(node.value) + ',' + left_serialized + right_serialized
    
    return recur(bst.root)

def deserialize_bst(bst_str):
        
    nodes_left = bst_str.split(",")[:-1]
    
    def recur(nodes_left):
        
        new_value = nodes_left.pop(0)
        
        if new_value == 'x':
            return None
        
        new_node = Node(new_value)
        new_node.left = recur(nodes_left)
        new_node.right = recur(nodes_left)
        
        return new_node
    
    root = recur(nodes_left)
    
    return root
`,

JAVA: `

`,
    
}