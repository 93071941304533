export default {

info: `
<div>
    <h1>Combination Sum</h1>
</div>
<div>
    <p>
        Given a collection of candidate numbers (candidates) and a 
        target number (target), find all unique combinations in 
        candidates where the candidate numbers sums to target.
    </p>
    <p>
        Each number in candidates may only be used once in the combination. 
    </p>
    <p>Note</p>
    <ul>
        <li>All numbers (including target) will be positive integers.</li>
        <li>The solution set must not contain duplicate combinations.</li>
    </ul>
</div>
<div>
    <code>
        Input: candidates = [10,1,2,7,6,1,5], target = 8,
        A solution set is:
        [
            [1, 7],
            [1, 2, 5],
            [2, 6],
            [1, 1, 6]
        ]
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We will first intialize a results array / list to hold our answers.
        `,
    },
    {
        key: '2',
        description: `
            As a way to make sure we do not do extra work, we can sort the
            incoming array to make things more predictable. If we know
            we are already over the target and every following number
            to add is even larger, there is no point going forward.
        `,
    },
    {
        key: '3',
        description: `
            We will then execute our recursive utitliy function sending in
            the sorted numbers, our target, the final results array / list,
            a temporary list that will hold one of the sub answers, and the current index.
            Once again, because we are already sorted, we can make it predicable where we should
            explore from via this index.
        `,
    },
    {
        key: '4',
        description: `
            As usual, with any recursive operation, we whould think about how we want to
            terminate the operation given certain conditions. First, if our target that was
            passed in as a parameter is 0, we know we hit it and can append the results so far
            to the final results array / list. If the language passes things in my reference,
            make sure a cloned copy of the temporary results is appended. We can also stop
            exploring because all the next numbers will push us over the target (because of sorting)
            so return.
        `,
    },
    {
        key: '5',
        description: `
            If we are passed our target. Stop exploring and return.
        `,
    },
    {
        key: '6',
        description: `
            If we made it to this point, we can starting iterating from our current
            index to the end of the array.
        `,
    },
    {
        key: '7',
        description: `
            To make sure we do not explore a number that we have already already explored, we
            do a check to make sure that the number at the current index does not equal the number
            that came before it. We check to see if it might also be equal to the index (the beginning)
            of the loop just in case we are dealing with the first element which would not be able to
            see whats before it.
        `,
    },
    {
        key: '8',
        description: `
            Add the number at the current index into our temporary array. Then we continue exploring
            down this path make sure we subtract the number at this current index from the target value.
            Also note that we increment the index (i + 1)
        `,
    },
    {
        key: '9',
        description: `
            For most backtracking type problems, you always go through a process of exploring a certain
            path and then restoring the condition that you just explored. Here we just pop the value that
            we appended just a few steps before.
        `,
    },
    {
        key: '10',
        description: `
            By the time we get here, the recursive function would have already self terminated and finished
            exploring everything that it could explore. Return out the result.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6],
        '3': [8],
        '4': [14,15,16],
        '5': [17,18],
        '6': [20],
        '7': [21],
        '8': [22,23],
        '9': [24],
        '10': [10]

    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def combination_sum(candidates, target):
    
    results = []
    
    sorted_candidates = sorted(candidates)
    
    combination_util(sorted_candidates, target, results, [], 0)
    
    return results

def combination_util(candidates, target, results, temp_list, index):
    
    if target == 0:
        results.append(list(temp_list))
        return
    elif target < 0:
        return
    else:
        for i in range(index, len(candidates)):
            if i == index or candidates[i] != candidates[i - 1]:
                temp_list.append(candidates[i])
                combination_util(candidates, target - candidates[i], results, temp_list, i + 1)
                temp_list.pop()
`,

JAVA: `

`,
    
}