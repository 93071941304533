export default {

info: `
<div>
    <h1>Unique Paths</h1>
</div>
<div>
    <p>A robot is located at the top-left corner of a m x n grid.</p>
    <p>
        The robot can only move either down or right at any point in time. 
        The robot is trying to reach the bottom-right corner of the grid.
    </p>
    <p>How many possible unique paths are there?</p>
</div>
<div>
    <code>
        Input: m = 3, n = 2
        Output: 3
        Explanation:
        From the top-left corner, there are a total of 3 ways to reach the bottom-right corner:
        1. Right -> Right -> Down
        2. Right -> Down -> Right
        3. Down -> Right -> Right    
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def unique_paths(m, n):
        
    if not m or not n:
        return 0
    
    dp = [[1] * n] * m

    for row in range(1, m):
        for col in range(1, n):

            dp[row][col] = dp[row-1][col] + dp[row][col-1]

    return dp[-1][-1]
`,

JAVA: `

`,
    
}