export default {

info: `
<div>
    <h1>Valid Parantheses</h1>
</div>
<div>
    <p>
        Given a string containing just the characters '(', ')', '{', '}', '[' and ']', determine if the input string is valid.
    </p>
    <p>
        An input string is valid if:
    </p>
    <p>
        1. Open brackets must be closed by the same type of brackets.
    </p>
    <p>
        2. Open brackets must be closed in the correct order.
    </p>
    <p>
        Note that an empty string is also considered valid.
    </p>
    <p>Input: "()[]{}"</p>
    <p>Output: true</p>
    <p>Input: "([)]"</p>
    <p>Output: false</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def valid_parentheses(string):
    
    stack = []
    
    pairs = { '{':'}', '[':']','(':')' }
    
    for char in string:
        if char in pairs:
            stack.append(pairs[char])
        else:
            if not stack or (stack.pop() != char):
                return False
            
    return len(stack) == 0
`,

JAVA: `

`,
    
}