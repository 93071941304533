import abstract_factory_design_pattern from './abstract_factory_design_pattern';
import adapter_design_pattern from './adapter_design_pattern';
import bridge_design_pattern from './bridge_design_pattern';
import builder_design_pattern from './builder_design_pattern';
import chain_of_responsibility_design_pattern from './chain_of_responsibility_design_pattern';
import command_design_pattern from './command_design_pattern';
import composite_design_pattern from './composite_design_pattern';
import decorator_design_pattern from './decorator_design_pattern';
import facade_design_pattern from './facade_design_pattern';
import factory_method_design_pattern from './factory_method_design_pattern';
import flyweight_design_pattern from './flyweight_design_pattern';
import interpreter_design_pattern from './interpreter_design_pattern';
import iterator_design_pattern from './iterator_design_pattern';
import mediator_design_pattern from './mediator_design_pattern';
import memento_design_pattern from './memento_design_pattern';
import observer_design_pattern from './observer_design_pattern';
import prototype_design_pattern from './prototype_design_pattern';
import proxy_design_pattern from './proxy_design_pattern';
import singleton_design_pattern from './singleton_design_pattern';
import state_design_pattern from './state_design_pattern';
import strategy_design_pattern from './strategy_design_pattern';
import template_method_design_pattern from './template_method_design_pattern';
import visitor_design_pattern from './visitor_design_pattern';

export default {
    abstract_factory_design_pattern,
    adapter_design_pattern,
    bridge_design_pattern,
    builder_design_pattern,
    chain_of_responsibility_design_pattern,
    command_design_pattern,
    composite_design_pattern,
    decorator_design_pattern,
    facade_design_pattern,
    factory_method_design_pattern,
    flyweight_design_pattern,
    interpreter_design_pattern,
    iterator_design_pattern,
    mediator_design_pattern,
    memento_design_pattern,
    observer_design_pattern,
    prototype_design_pattern,
    proxy_design_pattern,
    singleton_design_pattern,
    state_design_pattern,
    strategy_design_pattern,
    template_method_design_pattern,
    visitor_design_pattern
}