export default {

info: `
<div>
    <h1>K Closest Points</h1>
</div>
<div>
    <p>We have a list of points on the plane.  Find the K closest points to the origin (0, 0).</p>
    <p>(Here, the distance between two points on a plane is the Euclidean distance.)</p>
    <p>You may return the answer in any order.  The answer is guaranteed to be unique (except for the order that it is in.)</p>
    <p>Input: points = [[3,3],[5,-1],[-2,4]], K = 2</p>
    <p>Output: [[3,3],[-2,4]]</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def k_closest_points(points, k):
    points.sort(key = lambda point: point[0]**2 + point[1]**2)
    return points[:k]
`,

JAVA: `

`,
    
}