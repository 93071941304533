export default {

info: `
<div>
    <h1>Lowest Common Ancestor</h1>
</div>
<div>
    <p>Given a binary tree, find the lowest common ancestor (LCA) of two given nodes in the tree.</p>
    <p>
        According to the definition of LCA on Wikipedia: “The lowest 
        common ancestor is defined between two nodes p and q as the lowest 
        node in T that has both p and q as descendants (where we allow a node 
        to be a descendant of itself).”
    </p>
</div>
<div>
    <code>
        Input: root = [3,5,1,6,2,0,8,null,null,7,4], p = 5, q = 1
        Output: 3
        Explanation: The LCA of nodes 5 and 1 is 3.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def lowest_common_ancestor(root, node_a_value, node_b_value):

    return lca_recur(root, node_a_value, node_b_value)

def lca_recur(node, node_a_value, node_b_value):

    if node == None:
        return None

    if node.value == node_a_value or node.value == node_b_value:
        return node

    left_search_result = lca_recur(node.left, node_a_value, node_b_value)
    right_search_result = lca_recur(node.right, node_a_value, node_b_value)

    if left_search_result and right_search_result:
        return node

    if left_search_result == None:
        return right_search_result
    if right_search_result == None:
        return left_search_result
`,

JAVA: `

`,
    
}