export default {

info: `
<div>
    <h1>Find Missing</h1>
</div>
<div>
    <p>Find the missing number in the array.</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def find_missing(full_set, partial_set):

    xor_sum = 0

    for num in full_set:
        xor_sum ^= num

    for num in partial_set:
        xor_sum ^= num

    return xor_sum
`,

JAVA: `

`,
    
}