export default {

info: `
<div>
    <h1>Backspace String Compare</h1>
</div>
<div>
    <p>
    Given two strings s and t, return true if they are equal when both are 
    typed into empty text editors. '#' means a backspace character.
    </p>
    <p>
    Note that after backspacing an empty text, the text will continue empty.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Solution(object):
    def backspace_compare(self, s, t):
        
        s_index = len(s) - 1
        t_index = len(t) - 1
        
        s_skip = 0
        t_skip = 0
        
        while s_index >= 0 or t_index >= 0:
            
            while s_index >= 0:
                if s[s_index] == '#':
                    s_skip += 1
                    s_index -= 1
                elif s_skip > 0:
                    s_skip -= 1;
                    s_index -= 1
                else:
                    break
            
            while t_index >= 0:
                if t[t_index] == '#':
                    t_skip += 1
                    t_index -= 1
                elif t_skip > 0:
                    t_skip -= 1;
                    t_index -= 1
                else:
                    break
            
            if s_index >= 0 and t_index >= 0 and s[s_index] != t[t_index]:
                return False
            
            if (s_index >= 0) != (t_index >= 0):
                return False
            
            s_index -= 1
            t_index -= 1
            
        return True
`,

CPLUSPLUS: `
class Solution {
public:
    bool backspaceCompare(std::string s, std::string t) {
        
        int sIndex = s.size() - 1;
        int tIndex = t.size() - 1;
        
        int skipS = 0;
        int skipT = 0;
        
        while (sIndex >= 0 || tIndex >= 0)
        {
            while (sIndex >= 0)
            {
                if (s[sIndex] == '#')
                {
                    skipS++;
                    sIndex--;
                }
                else if (skipS > 0)
                {
                    skipS--;
                    sIndex--;
                }
                else
                {
                    break;
                }   
            }
            
            while (tIndex >= 0)
            {
                if (t[tIndex] == '#')
                {
                    skipT++;
                    tIndex--;
                }
                else if (skipT > 0)
                {
                    skipT--;
                    tIndex--;
                }
                else
                {
                    break;
                }   
            }
            
            if (sIndex >= 0 && tIndex >= 0 && s[sIndex] != t[tIndex])
                return false;
            
            if ((sIndex >= 0) != (tIndex >= 0))
                return false;
                
            sIndex--;
            tIndex--;
        }
        
        return true;
    }
};
`,
    
}