<div class="code-pane" [id]="pane.editor">

    <div class="code-pane__tabBar">
        <button class="code-pane__tab">
            {{ pane.label }} 
            <button (click)="onDelete(pane.key)">&times;</button> 
        </button>
        <!--
        <div>
            <button class="code-pane__playTab" (click)="playAnimation('-')">
                <i class="fas fa-backward"></i>
            </button>
            <button class="code-pane__playTab" (click)="playAnimation('+')">
                <i class="fas fa-forward"></i>
            </button>
        </div>
        -->
    </div>

    <div class="code-pane__ide">
        <ngx-codemirror 
            [(ngModel)]="content" 
            [options]="options"
        ></ngx-codemirror>
    </div>

</div>
