export default {

info: `
<div>
    <h1>Subsets</h1>
</div>
<div>
    <p>Given a set of distinct integers, nums, return all possible subsets (the power set)</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def generate_subset(nums):
    results = []
    generate_subset_recur(nums, [], results, 0)
    return results
    
def generate_subset_recur(nums, subset, subsets, index):
    
    if index == len(nums):
        subsets.append(list(subset))
    else:
        subset.append(nums[index])
        generate_subset_recur(nums, subset, subsets, index + 1)
        subset.pop()
        generate_subset_recur(nums, subset, subsets, index + 1)
`,

JAVA: `

`,
    
}