export default {

info: `
<div>
    <h1>Quick Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def quick_sort(arr, left=None, right=None):
    if left is None:
        left = 0
    if right is None:
        right = len(arr) -1
        
    if left < right:
        partition_index = partition(arr, left, right)
        quick_sort(arr, left, partition_index - 1)
        quick_sort(arr, partition_index + 1, right)
        
    if (right - left) == (len(arr) - 1):
        return arr
    
def partition(arr, left=None, right=None):
    pivot = arr[right]
    pivot_index = left
    
    for scan_index in range(left, right):
        if arr[scan_index] <= pivot:
            swap(arr, pivot_index, scan_index)
            pivot_index += 1
            
    swap(arr, pivot_index, right)
    
    return pivot_index

def swap(arr, index_one, index_two):
    arr[index_one], arr[index_two] = arr[index_two], arr[index_one]
`,

JAVA: `

`,
    
}