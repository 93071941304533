export default {

info: `
<div>
    <h1>Binary Tree Longest Consecutive Sequence</h1>
</div>
<div>
    <p>
        Given a binary tree, find the length of the longest consecutive sequence path.
    </p>
    <p>
        The path refers to any sequence of nodes from some starting node to any node in 
        the tree along the parent-child connections. The longest consecutive path need 
        to be from parent to child (cannot be the reverse).
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We will do a a edge case check to make sure something was passed in.
        `,
    },
    {
        key: '2',
        description: `
            Ultimately, we are passing question down to each node. We have a recursive
            utility function to handle most of the work for us but to start off, we
            need to make sure that this question gets passed down to the root's left and
            right child.
        `,
    },
    {
        key: '3',
        description: `
            This recursive utility function is what we essentially want
            to ask each node. As a node, we want it to decide which question
            to send down to its children, get the answers, and pass the answer
            up to the parent. To be able to get our questions and answers, we need
            to pass in the next node that will receive the question, 
            the current node's value, and the length at this point in time.
        `,
    },
    {
        key: '4',
        description: `
            First we know we want to have a way to terminate the recursive operation.
            In this case it will be once node that is passed in is null. If this is the case,
            we will just pass up our answer, the length, at this point. 
        `,
    },
    {
        key: '5',
        description: `
            To decide which question we want to pass down to the children, we need to see
            if the current node is is actually one larger than its parent (consecutive).
            Note that in this recursive function, we are given the parent node's value.
            We will do quick check to see if the current node's value is one greater than the
            parent node's value.
        `,
    },
    {
        key: '6',
        description: `
            If it is, we will throw the same question to our left and right children ensuring we increment
            the length because we are counting ourselves. Node in the reursive function, we pass in
            the child (left or right), the current node's value, and the length that was passed in plus one.
        `,
    },
    {
        key: '7',
        description: `
            Once the answers propogate back to us in the form of the left and right length variables,
            we just give our parent the max of these two.
        `,
    },
    {
        key: '8',
        description: `
            Now going back to the main conditional in this recursive function, if the current node's value
            is not equal to the parent node's value plus one (not consecutive), then we essentialy start
            restart our length back to 1. There is aways a chance one of the sub trees in the entire tree
            holds the correct answer.
        `,
    },
    {
        key: '9',
        description: `
            Now when we return the max at this point, we want to make sure
            we also take into acount the length (at this point) just in case
            the current node is not consecutive but we have still reached 
            some sort of max length.
        `,
    },
    {
        key: '10',
        description: `
            By the time we get here, we can be confidant that we have recieved
            an aggregated answer from all the root's children so we just need to return
            the max from the left and right child.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [3,4],
        '2': [6,7],
        '3': [9],
        '4': [12,13],
        '5': [15],
        '6': [16,17],
        '7': [18],
        '8': [20,21],
        '9': [22],
        '10': [9]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def consecutive_branch(root):
    if node is None:
        return 0

    left_length = max_child_length(root.left, root.value, 1)
    right_length = max_child_length(root.right, root.value, 1)
    
    return max(left_length, right_length)
    
def max_child_length(node, parent_node_value, length):
    if node is None:
        return length
    
    if node.value == parent_node_value + 1:
        left_length = max_child_length(node.left, node.value, length + 1)
        right_length = max_child_length(node.right, node.value, length + 1)
        return max(left_length, right_length)
    else:
        left_length = max_child_length(node.left, node.value, 1)
        right_length = max_child_length(node.right, node.value, 1)
        return max(left_length, right_length, length)
`,

JAVA: `

`,
    
}