export default {

info: `
<div>
    <h1>Knapsack Problem</h1>
</div>
<div>
    <p>
        Write a program for the knapsack problem that selects a subset 
        of items that has maximum value and satisfies the weight constraint. 
        All items have integer weights and values. Return the value of the subset.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def knapsack_problem(max_capacity, weights, weight_values):
    
    dp = [[0 for col in range(0, max_capacity + 1)] 
                for row in range(0, len(weights) + 1)]
    
    for row in range(len(weights) + 1):
        for col in range(max_capacity + 1):
            if row == 0 or col == 0:
                dp[row][col] = 0
            elif weights[row - 1] <= col:
                dp[row][col] = max(
                    weight_values[row - 1] + dp[row - 1][col - weights[row-1]], 
                    dp[row - 1][col])
            else:
                dp[row][col] = dp[row - 1][col]

    return dp[-1][-1]
`,

JAVA: `

`,
    
}