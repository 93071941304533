export default {

info: `
<div>
    <h1>Facade Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Facade:

    def __init__(self):
        self.subsystem_one = SubsystemOne()
        self.subsystem_two = SubsystemTwo()

    def operation(self):
        self.subsystem_one.lower_level_operation_one()
        self.subsystem_one.lower_level_operation_two()
        self.subsystem_two.lower_level_operation_one()
        self.subsystem_two.lower_level_operation_two()


class SubsystemOne:

    def lower_level_operation_one(self):
        pass

    def lower_level_operation_two(self):
        pass


class SubsystemTwo:

    def lower_level_operation_one(self):
        pass

    def lower_level_operation_two(self):
        pass


def main():
    facade = Facade()
    facade.operation()
`,

JAVA: `

`,
    
}