export default {

info: `
<div>
    <h1>Memento Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
from datetime import datime

class Memento:

    def __init__(self):
        self.state = state

    def get_state(self):
        return self.state

class Originator:

    def __init__(self):
        self.state = datetime.now()

    def save_state(self):
        self.state = datetime.now()

        return Memento(self.state)

    def restore_state(self, state):
        self.state = state

class Caretaker:

    def __init__(self):
        self.memento_history = []

        self.originator = Originator()

    def do_something(self):
        next_memento = self.originator.save_state()

        self.memento_history.append(next_memento)

    def undo(self):
        self.memento_history.pop()

        prev_memento = self.memento_history[-1]

        self.originator.restore_state(prev_memento.get_state())
`,

JAVA: `

`,
    
}