export default {

info: `
<div>
    <h1>Topological Sort</h1>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Create a data structure that will give us constant access to
            nodes that we have already visited. We will use this to make sure
            we do not process nodes that have already been vistied
        `,
    },
    {
        key: '2',
        description: `
            Create a data structure that similar to a stack (Last In First Out)
            that will be our result. It is like a stack because we are starting
            from the ending of the ordering and building to the front.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [5]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def topological_sort(adjacency_list):
        
    visited = {}
    stack = []
    
    for node in adjacency_list:
        if node not in visited:
            topological_sort_recur(node, visited, stack) 

    return stack

def topological_sort_recur(adjacency_list, node, visited, stack):
    
    visited[node] = True
    
    for neighbor in adjacency_list[node]: 
        if neighbor not in visited: 
            topological_sort_recur(neighbor, visited, stack)
            
    stack.insert(0,node)
`,

JAVA: `

`,
    
}