export default {

info: `
<div>
    <h1>Sorted Merge</h1>
</div>
<div>
    <p>
    Given two sorted arrays where A is large enough to 
    hold B, write a method to merge B into A 
    in sorted order.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'CPLUSPLUS'],

PYTHON: `
def sorted_merge(list_a, list_b, last_a, last_b):
    index_a = last_a
    index_b = last_b
    index_merged = last_b + last_a + 1
    
    while index_b >= 0:
        if (index_a >= 0) and (list_a[index_a] > list_b[index_b]):
            list_a[index_merged] = list_a[index_a]
            index_a -= 1
        else:
            list_a[index_merged] = list_b[index_b]
            index_b -= 1
        index_merged -= 1
    return list_a
`,

CPLUSPLUS: `
class Solution {
public:

    void SortedMerge(int listA[], int listB[], int lastAIndex, int lastBIndex)
    {
        int indexA = lastAIndex;
        int indexB = lastBIndex;

        int indexMerged = lastBIndex + lastAIndex + 1;

        while (indexB >= 0)
        {
            if (indexA >= 0 && listA[indexA] > listB[indexB])
            {
                listA[indexMerged] = listA[indexA];
                indexA--;
            }
            else
            {
                listA[indexMerged] = listB[indexB];
                indexB--;
            }

            indexMerged--;
        }
    }
}
`,

JAVA: `

`,
    
}