export default {

info: `
<div>
    <h1>LRU Cache</h1>
</div>
<div>
    <p>Implement an LRU Cache.</p>
    <p>
        It is a cache replacement policy that says to 
        evict the least recently used item in the cache.
    </p>
    <p>
        Every time an item is used it goes to the "front" 
        of the cache since it has been used and has priority.
    </p>
    <p>
        Items that are not used will go to the "end" of the cache 
        eventually and get evicted since they are the least used 
        items, they never got a bump up by being used.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class LRUCache(object):
    
    def __init__(self, capacity = 5):
        self.capacity = capacity
        self.node_count = 0
        self.map = {}
        self.head = LRUNode('HEAD','HEAD')
        self.tail = LRUNode('TAIL','TAIL')
        self.head.next = self.tail
        self.tail.prev = self.head
        
    def put(self, key, value):
        new_node = LRUNode(key, value)
        if self.node_count == self.capacity:
            self._remove()
        self._add(new_node)
    
    def get(self, key):
        if key in self.map:
            target_node = self.map[key]
            self._promote(target_node)
            return target_node.value
        else:
            return -1
        
    def _add(self, new_node):
        self.map[new_node.key] = new_node
        previous_top_node = self.head.next
        
        self.head.next = new_node
        new_node.prev = self.head
        new_node.next = previous_top_node
        previous_top_node.prev = new_node
        
        self.node_count += 1
        
    def _remove(self):
        node_to_remove = self.tail.prev
        node_left_of_node_to_remove = node_to_remove.prev
        
        node_left_of_node_to_remove.next = self.tail
        self.tail.prev = node_left_of_node_to_remove
        del self.map[node_to_remove.key]
        self.node_count -= 1
        
        
    def _promote(self, node):
        left_node = node.prev
        right_node = node.next
        left_node.next = right_node
        right_node.prev = left_node

        self.head.next.prev = node
        node.prev = self.head
        self.head.next = node
`,

JAVA: `

`,
    
}