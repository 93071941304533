export default {

info: `
<div>
    <h1>Tarjan's Strongly Connected Components Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def tarjans_sccs(adjacency_list):
    
    group_id = [0]
    
    group_ids = {}
    groups = {}
    on_stack = {}
    stack = []
    
    for node in adjacency_list:
        group_ids[node] = -1
        groups[node] = 0
        on_stack[node] = None
        
    for node in adjacency_list:
        if group_ids[node] == -1:
            dfs(adjacency_list, stack, on_stack, group_ids, groups, group_id, node)
            
    return groups

    
def dfs(adjacency_list, stack, on_stack, group_ids, groups, group_id, at_node):
    
    stack.append(at_node)
    on_stack[at_node] = True
    group_ids[at_node] = groups[at_node] = group_id[0]
    group_id[0] += 1
    
    for to_node in adjacency_list[at_node]:

        if group_ids[to_node] == -1:
            dfs(adjacency_list, stack, on_stack, group_ids, groups, group_id, to_node)

        if on_stack[to_node]:
            groups[at_node] = min(groups[at_node], groups[to_node])
            
    if group_ids[at_node] == groups[at_node]:

        while stack:
            stack_node = stack.pop()
            on_stack[stack_node] = False
            groups[stack_node] = group_ids[at_node]

            if stack_node == at_node:
                break
`,

JAVA: `

`,
    
}