export default {

info: `
<div>
    <h1>Is Number Even or Odd</h1>
</div>
<div>
    <p>
        Write a program to determine if a given number is even or odd.
        Do not make use of the modulus operator
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def is_even_odd(num):
    if num & 1 == 0:
        return 'Even'
    return 'Odd'
`,

JAVA: `

`,
    
}