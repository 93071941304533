export default {

info: `
<div>
    <h1>Kruskal's Minimum Spanning Tree Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def kruskals_mst(graph):
    
    node_count = len(graph)
    edge_count = 0
    target_edge_count = node_count - 1
    mst_edges = [None] * target_edge_count
    
    edge_list = []
    for node in graph:
        for edge in graph[node]:
            edge_list.append((graph[node][edge], node, edge))
            
    edge_list.sort()
    union_find = UnionFind(edge_list)
    
    for edge in edge_list:
        
        edge_cost, from_node, to_node = edge
        
        if union_find.connected(from_node, to_node):
            continue
            
        union_find.union(from_node, to_node)
        
        mst_edges[edge_count] = (from_node, to_node, edge_cost)
        edge_count += 1
        
        if edge_count == target_edge_count:
            break
            
    if edge_count != target_edge_count:
        return None
    
    return mst_edges
    

class UnionFind:
    
    def __init__(self, items):
        self.address = {}
        self.size = {}
        
        for i, item in enumerate(items):
            self.address[item] = item
            self.size[item] = 1
            
    def find(self, item):
        
        root = item
        
        while root != self.address[root]:
            root = self.address[root]
            
        while item != root:
            next_address = self.address[item]
            self.address[item] = root
            item = next_address
            
        return root
    
    def union(self, item_a, item_b):
        root_a = self.find(item_a)
        root_b = self.find(item_b)
        
        if root_a == root_b:
            return
        
        if self.size[root_a] < self.size[root_b]:
            self.size[root_b] += self.size[root_a]
            self.address[root_a] = root_b
        else:
            self.size[root_a] += self.size[root_b]
            self.address[root_b] = root_a
            
    def connected(self, item_a, item_b):
        return self.find(item_a) == self.find(item_b)
    
    def get_size(self, item):
        return self.size[item]
`,

JAVA: `

`,
    
}