export default {

info: `
<div>
    <h1>Mediator Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Mediator:

    def __init__(self):
        self.colleague_a = ColleagueA(self)
        self.colleague_b = ColleagueB(self)

    def notify(self):
        pass


class ColleagueA:

    def __init__(self, mediator):
        self.mediator = mediator

    def some_operation(self):
        self.mediator.notify()


class ColleagueB:

    def __init__(self, mediator):
        self.mediator = mediator

    def some_operation(self):
        self.mediator.notify()
`,

JAVA: `

`,
    
}