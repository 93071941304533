export default {

info: `
<div>
    <h1>Template Method Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
from abc import ABC, abstractmethod

class AbstractClass(ABC):

    def template_method(self):
        self.base_operation()
        self.required_operation_in_subclass()
        self.optional_operation()

    def base_operation(self):
        pass

    @abstractmethod
    def required_operation_in_subclass(self):
        pass

    def optional_operation(self):
        pass

class ConcreteClass(AbstractClass):

    def required_operation_in_subclass(self):
        pass
    
    def optional_operation(self):
        pass
`,

JAVA: `

`,
    
}