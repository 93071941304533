export default {

info: `
<div>
    <h1>Reverse Integer</h1>
</div>
<div>
    <p>
        Write a program which takes an integer and returns the 
        integer corresponding to the digits of the input 
        written in reverse order.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'JAVA'],

PYTHON: `
def reverse(x):
    result = 0
    remaining = abs(x)
    while remaining != 0:
        result *= 10
        result += remaining % 10
        remaining //= 10
    return -result if x < 0 else result
`,

JAVA: `
public static long reverse(int x) {
    long result = 0;
    long remaning = Math.abs(x);
    while(remaing != 0) {
        result *= 10;
        result += remaining % 10;
        remaining /= 10;
    }
    return x < 0 ? -result : result;
}
`,
    
}