export default {

info: `
<div>
    <h1>Tree Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def tree_sort(arr):

    tree_root = Node(arr[0])

    for i in range(1, len(arr)):
        insert(tree_root, arr[i])

    sorted_arr = []

    traverse_in_order(tree_root, sorted_arr)

    return sorted_arr


class Node:
    
    def __init__(self, value):
        self.value = value
        self.left = None
        self.right = None


def insert(node, value):

    if value < node.value:
        if node.left is None:
            node.left = Node(value)
        else:
            insert(node.left, value)
    else:
        if node.right is None:
            node.right = Node(value)
        else:
            insert(node.right, value)
            

def traverse_in_order(node, sorted_arr):
    if node.left is not None:
        traverse_in_order(node.left, sorted_arr)
        
    sorted_arr.append(node.value)
    
    if node.right is not None:
        traverse_in_order(node.right, sorted_arr)
        
    return None
`,

JAVA: `

`,
    
}