import { LANG } from './lang';

export default [
    {
        title: 'Loop through array/list with index',
        url: 'loop_with_index',
        langs: [
            LANG.PYTHON,
            LANG.SWIFT,
            LANG.KOTLIN,
            LANG.JAVA,
            LANG.GO,
            LANG.CSHARP
        ]
    },
    {
        title: 'Multi-threading',
        url: 'multi_threading',
        langs: [
            LANG.CSHARP,
            LANG.CPLUSPLUS,
            LANG.SWIFT,
            LANG.KOTLIN
        ]
    },
    {
        title: 'Print Matrix',
        url: 'print_matrix',
        langs: [
            LANG.CPLUSPLUS
        ]
    },
    {
        title: 'Sort Sequence of Objects',
        url: 'sort_sequence_of_objects',
        langs: [
            LANG.CPLUSPLUS,
            LANG.SCALA
        ]
    }
]