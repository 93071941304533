export default {

info: `
<div>
    <h1>Generate All Permutations of an array</h1>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Create a data structure that will hold our final results.
        `,
    },
    {
        key: '2',
        description: `
            Call our recursive utility function that. It takes in the
            array / list we are working with, the current index that
            we are exploring from, the the results variable that stores everything.
        `,
    },
    {
        key: '3',
        description: `
            The base case for our recursive function will be if the index that
            gets passed in is at the end of the array. If this is the case
            we can just append the current sub results to final results. If be sure
            to copy these answers if arrays / lists are passed in as references.
        `,
    },
    {
        key: '4',
        description: `
            We are exploring scenarios based on our current index that gets passed
            into the recursive function. Start a loop that goes from this current
            index to the end of the array.
        `,
    },
    {
        key: '5',
        description: `
            This is a back tracking problem so we want to set some type of condition
            and explore down this path. The condition is set by swapping the values
            at the index that was passed into the recursive function and the index
            inside the for loop
        `,
    },
    {
        key: '6',
        description: `
            Then we start the exploration making sure we increment the index variable
            going in.
        `,
    },
    {
        key: '7',
        description: `
            Once our exploration is finished, we need to undo the condition that we
            set before the exploration so we just re-swap the same indexes.
        `,
    },
    {
        key: '8',
        description: `
            As long as our base case is set, the explorations should eventually terminate
            so by the time we are done, we have an answer and can just return it out.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [3],
        '2': [4],
        '3': [9,10],
        '4': [12],
        '5': [13,17,18],
        '6': [14],
        '7': [15,17,18],
        '8': [5],
    },
    JAVA: {
        '1': [5],
        '2': [6],
        '3': [12,13],
        '4': [15],
        '5': [16,23,24,25,26,27],
        '6': [17],
        '7': [18,23,24,25,26,27],
        '8': [7],
    }
},

languages: ['PYTHON', 'JAVA'],

PYTHON: `
def permute(arr):
    results = []
    recur(arr, 0, results)
    return results

def recur(arr, start, results):

    if start >= len(arr):
        results.append(list(arr))
    else:
        for i in range(start, len(arr)):
            swap(arr, start, i)
            recur(arr, start + 1, results)
            swap(arr, start, i)

def swap(arr, i, j):
    arr[i], arr[j] = arr[j], arr[i]
`,

JAVA: `
import java.util.ArrayList;

public ArrayList<int[]> permute(int[] arr) {
    ArrayList<int[]> results = new ArrayList<int[]>();
    recur(arr, 0, results);
    return results;
}

private void recur(int[] arr, int start, ArrayList<int[]> results) {

    if (start >= arr.length) {
        results.add(arr.clone());
    } else {
        for (int i = start; i < arr.length; i++) {
            swap(arr, start, i);
            recur(arr, start + 1, results);
            swap(arr, start, i);
        }
    }
}

private void swap(int[] arr, int i, int j) {
    int temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
}
`,
    
}