export default {

info: `
<div>
    <h1>Prim's Minimum Spanning Tree Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import heapq


def prims_mst(graph):
    
    queue = []
    visited = {}
    
    node_count = len(graph)
    edge_count = 0
    target_edge_count = node_count - 1
    mst_edges = [None] * target_edge_count
    
    add_edges(graph, visited, queue, '0')
    
    while queue and edge_count != target_edge_count:
        
        weight, from_node, to_node = heapq.heappop(queue)
        
        if to_node in visited:
            continue
            
        mst_edges[edge_count] = (from_node, to_node, weight)
        edge_count += 1
        
        add_edges(graph, visited, queue, to_node)
        
    return mst_edges
    

def add_edges(graph, visited, queue, from_node):
    
    visited[from_node] = True
    edges = graph[from_node]
    
    for to_node in edges:
        if to_node not in visited:
            weight = edges[edge]
            heapq.heappush(queue, (weight, from_node, to_node))
`,

JAVA: `

`,
    
}