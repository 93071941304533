export default {

info: `
<div>
    <h1>Combination Sum</h1>
</div>
<div>
    <p>
        Given a set of candidate numbers (candidates) (without duplicates) and a 
        target number (target), find all unique combinations in candidates 
        where the candidate numbers sums to target.
    </p>
    <p>
        The same repeated number may be chosen from candidates unlimited number of times. 
    </p>
    <p>Note</p>
    <ul>
        <li>All numbers (including target) will be positive integers.</li>
        <li>The solution set must not contain duplicate combinations.</li>
    </ul>
</div>
<div>
    <code>
        Input: candidates = [2,3,6,7], target = 7,
        A solution set is:
        [
            [7],
            [2,2,3]
        ]
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We will first intialize a results array / list to hold our answers.
        `,
    },
    {
        key: '2',
        description: `
            We will then execute our recursive utitliy function sending in
            the canidates, our target, a tempory list that will hold sub answers,
            and the final results array / list.
        `,
    },
    {
        key: '3',
        description: `
            Its always a good idea to start off a recursive function with how its
            going to be terminated. In this case, if the incoming target is equal
            to zero, we know that we have a valid sub answer. If the language passes
            in arrays / lists as references, be sure to clone it before putting it in
            the final results. We return right after to stop this exploration.
        `,
    },
    {
        key: '4',
        description: `
            Now we want to actually set up how we want to explore. The process will be
            to start looping through the canidates. Be sure to have an easy way to get the current
            index as well as the current value. 
        `,
    },
    {
        key: '5',
        description: `
            Another area, where we can kill the recursion is if the current number in the
            iteration is pass the target.
        `,
    },
    {
        key: '6',
        description: `
            Like most backtracking problems, we want to set some condition, continue exploring
            based on this condition, and then undo the condition that we set. The condition in
            this case is exploring a possible result set with the current number so we add
            the current number to the possible sub-results.
        `,
    },
    {
        key: '7',
        description: `
            Then we explore. Note that because we can have duplicate numbers we are exploring
            all posibilities at this index so the possible list of candidates remains the
            same at this index. Note that at this point we are simulating accepting this number 
            so subtract it from the target.
        `,
    },
    {
        key: '8',
        description: `
            Again, this is a backtracking problem so this is the back tracking part. We essentialy
            undo what we did before exploring down this path.
        `,
    },
    {
        key: '9',
        description: `
            We should have an answer by the time all the recursive explorations have terminated so
            return it out.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6],
        '3': [13,14,15],
        '4': [17],
        '5': [19,20],
        '6': [22],
        '7': [23],
        '8': [22,24],
        '9': [8]

    },
    JAVA: {}
},

languages: ['PYTHON', 'CPLUSPLUS'],

PYTHON: `
def combination_sum(candidates, target):
    
        results = []
        
        combination_util(sorted_candidates, target, [], results)
        
        return results
    
    
def combination_util(candidates, target, progress, results):
    
    if target == 0:
        results.append(list(progress))
        return 

    for index, num in enumerate(candidates):
        
        if num > target:
            return
        else:
            progress.append(num)
            combination_util(candidates[index:], target - num, progress, results)
            progress.pop()
`,

CPLUSPLUS: `
#include <vector>

template<typename T>
std::vector<T> Slice(const std::vector<T>& vector, int startIndex)
{
    auto first = vector.cbegin() + startIndex;
    auto last = vector.cbegin() + vector.size();
    
    std::vector<T> vectorResult(first, last);
    return vectorResult;
}

class Solution {
public:

    void recurse(const std::vector<int>& canidates, int target, std::vector<int>& progress, std::vector<std::vector<int>>& results)
    {
        
        if (target == 0)
        {
            results.push_back(std::vector<int>(progress));
            return;
        }
        
        for (int i = 0; i < canidates.size(); i++)
        {
            if (canidates[i] > target) 
            {
                return;
            }
            else
            {
                progress.push_back(canidates[i]);
                recurse(Slice(canidates, i), target - canidates[i], progress, results);
                progress.pop_back();
            }
        }
    }

    std::vector<std::vector<int>> CombinationSum(std::vector<int>& canidates, int target)
    {
        std::vector<std::vector<int>> results;
        
        std::vector<int> progress;
        
        recurse(canidates, target, progress, results);
        
        return results;
    }
}
`,
    
}