import { LANG } from './lang';

export default [
    {
        title: 'Abstract Factory Design Pattern',
        url: 'abstract_factory_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Adapter Design Pattern',
        url: 'adapter_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Bridge Design Pattern',
        url: 'bridge_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Builder Design Pattern',
        url: 'builder_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Chain of Responsibility Design Pattern',
        url: 'chain_of_responsibility_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Command Design Pattern',
        url: 'command_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Composite Design Pattern',
        url: 'composite_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Decorator Design Pattern',
        url: 'decorator_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Facade Design Pattern',
        url: 'facade_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Chain of Responsiblity Design Pattern',
        url: 'chain_of_responsibility_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Decorator Design Pattern',
        url: 'decorator_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Facade Design Pattern',
        url: 'facade_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Factory Method Design Pattern',
        url: 'factory_method_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Flyweight Design Pattern',
        url: 'flyweight_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Interpreter Design Pattern',
        url: 'interpreter_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Iterator Design Pattern',
        url: 'iterator_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Mediator Design Pattern',
        url: 'mediator_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Memento Design Pattern',
        url: 'memento_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Observer Design Pattern',
        url: 'observer_design_pattern',
        langs: [
            LANG.PYTHON,
            LANG.JAVA
        ]
    },
    {
        title: 'Prototype Design Pattern',
        url: 'prototype_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Proxy Design Pattern',
        url: 'proxy_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Singleton Design Pattern',
        url: 'singleton_design_pattern',
        langs: [
            LANG.PYTHON,
            LANG.JAVA
        ]
    },
    {
        title: 'State Design Pattern',
        url: 'state_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Strategy Design Pattern',
        url: 'strategy_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Template Method Design Pattern',
        url: 'template_method_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Visitor Design Pattern',
        url: 'visitor_design_pattern',
        langs: [
            LANG.PYTHON,
        ]
    },
]