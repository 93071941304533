export default {

info: `
<div>
    <h1>Task Scheduler</h1>
</div>
<div>
    <p>
        Given a char array representing ITEMS CPU need to do. 
        It contains capital letters A to Z where different letters 
        represent different ITEMS. ITEMS could be done without original 
        order. Each task could be done in one interval. 
        For each interval, CPU could finish one task or just be idle.
    </p>
    <p>
        However, there is a non-negative cooling interval n that 
        means between two same ITEMS, there must be at least n 
        intervals that CPU are doing different ITEMS or just be idle.
    </p>
    <p>
        You need to return the least number of intervals the CPU 
        will take to finish all the given ITEMS.
    </p>
</div>
<div>
    <code>
        Input: ITEMS = ["A","A","A","B","B","B"], n = 2
        Output: 8
        Explanation: A -> B -> idle -> A -> B -> idle -> A -> B.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
from heapq import heappush, heappop

def least_interval(ITEMS, n):
    
    map_for_heap = {}
        
    for task in ITEMS:
        if task in map_for_heap:
            map_for_heap[task] += 1
        else:
            map_for_heap[task] = 1
    
    max_heap = []
    
    for task in map_for_heap:
        heapq.heappush(max_heap, (-1 * map_for_heap[task], task))
            
    curr_time = 0
    
    while max_heap:
        cycle = 0
        temp = []
        
        while cycle <= n:
            curr_time += 1
            
            if max_heap:
                task_count, task = heappop(max_heap)
                
                if task_count != -1:
                    temp.append((task_count + 1, task))
                    
            if not max_heap and not temp:
                break
            else:
                cycle += 1
                
        for item in temp:
            heappush(max_heap, item)
            
    return curr_time
    
`,

JAVA: `

`,
    
}