export default {

info: `
<div>
    <h1>Median of Two Sorted Arrays</h1>
</div>
<div>
    <p>There are two sorted arrays nums1 and nums2 of size m and n respectively.</p>
    <p>Find the median of the two sorted arrays. The overall run time complexity should be O(log (m+n)).</p>
    <p>You may assume nums1 and nums2 cannot be both empty.</p>
</div>
<div>
    <p>Example 1:</p>
    <p>nums1 = [1, 3]</p>
    <p>nums2 = [2]</p>
    <p>The median is 2.0</p>
</div>
<div>
    <p>Example 2:</p>
    <p>nums1 = [1, 2]</p>
    <p>nums2 = [3, 4]</p>
    <p>The median is (2 + 3)/2 = 2.5</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'CPLUSPLUS'],

PYTHON: `
def median_sorted_arrays(small_arr, large_arr):
    
    if len(large_arr) < len(small_arr):
        return median_sorted_arrays(large_arr, small_arr)
    
    len_small = len(small_arr)
    len_large = len(large_arr)
    
    start = 0
    end = len_small
    
    while start <= end:
        partition_small = (start + end) // 2
        partition_large = ((len_small + len_large + 1) // 2) - partition_small
        
        left_small = small_arr[partition_small - 1]
        if partition_small == 0:
            left_small = float('-inf')

        right_small = small_arr[partition_small]
        if partition_small == len_small:
            right_small = float('inf')

        left_large = large_arr[partition_large - 1]
        if partition_large == 0:
            left_large = float('-inf')
        
        right_large = large_arr[partition_large]
        if partition_large == len_large:
            right_large = float('inf')
        
        if left_small <= right_large and left_large <= right_small:
            break
        elif left_small > right_large:
            end = partition_small - 1
        else:
            start = partition_small + 1
            
    if (len(small_arr) + len(large_arr)) % 2 == 0:
        return (small_arr[partition_small - 1] + large_arr[partition_large]) / 2
    else:
        return max(small_arr[partition_small - 1], large_arr[partition_large - 1])
`,

JAVA: `

`,

CPLUSPLUS: `
#include <vector>

class Solution {
public:

	double FindMedianSortedArrays(std::vector<int>& numsOne, std::vector<int>& numsTwo)
	{
		std::vector<int> mergeNums;
		mergeNums.reserve(numsOne.size() + numsTwo.size());

		Merge(numsOne, numsTwo, mergeNums);

		return Median(mergeNums);
	}

	double Median(const std::vector<int>& vec)
	{
		size_t size = vec.size();
		int halfSize = size / 2;

		if (size % 2) 
		{
			return vec.at(halfSize);
		}
		else
		{
			return (vec.at(halfSize) + vec.at(halfSize - 1)) / 2;
		}
	}

	void Merge(const std::vector<int>& numsOne, const std::vector<int>& numsTwo, std::vector<int>& output)
	{
		auto leftIter = numsOne.cbegin();
		auto rightIter = numsTwo.cbegin();

		while (leftIter != numsOne.cend() && rightIter != numsTwo.cend())
		{
			if (*leftIter < *rightIter)
			{
				output.push_back(*leftIter);
				leftIter++;
			}
			else
			{
				output.push_back(*rightIter);
				rightIter++;
			}
		}

		for (auto begin = leftIter; begin != numsOne.cend(); ++begin)
		{
			output.push_back(*begin);
		}

		for (auto begin = rightIter; begin != numsTwo.cend(); ++begin)
		{
			output.push_back(*begin);
		}
	}
};
`
    
}