export default {

info: `
<div>
    <h1>Knuth Morris Pratt String Search Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def knuth_morris_pratt(ptn, txt):

    result = []
    ptn_len = len(ptn)
    txt_len = len(txt)

    lps = kmp_table(ptn)

    ptn_index = 0
    txt_index = 0

    while txt_index < txt_len:
        if ptn[ptn_index] == txt[txt_index]:
            txt_index += 1
            ptn_index += 1

        if ptn_index == ptn_len:
            result.append((txt_index - ptn_index))
            ptn_index = lps[ptn_index-1]
        elif txt_index < txt_len and ptn[ptn_index] != txt[txt_index]:
            if ptn_index != 0:
                ptn_index = lps[ptn_index - 1]
            else:
                txt_index += 1

    return result

def kmp_table(ptn):

    len_of_prefix = 0
    result = [0 for i in range(len(ptn))]
    ptn_index = 1

    while ptn_index < len(ptn):
        if ptn[ptn_index] == ptn[len_of_prefix]:
            len_of_prefix += 1
            result[ptn_index] = len_of_prefix
            ptn_index += 1
        else:
            if len_of_prefix != 0:
                len_of_prefix = result[len_of_prefix - 1]
            else:
                result[len_of_prefix] = 0
                ptn_index += 1
                
    return result
`,

JAVA: `

`,
    
}