export default {

info: `
<div>
    <h1>Generate All Permutations of a string</h1>
</div>
<div>
    <p>
        Given a string, print all permutations of the 
        string and return an array of them. 
        No duplicates are allowed.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'JAVA'],

PYTHON: `
def find_permutations(string):
    
    results = []
    permute("", string, results)
    return results
    
def permute(prefix, suffix, results):
    
    if len(suffix) == 0:
        results.append(prefix)
    else:
        for i in range(len(suffix)):
            permute(prefix + suffix[i], suffix[0:i] + suffix[i+1:])
`,

JAVA: `
public ArrayList<String> find_permutations(String s) {
    
    ArrayList<String> results = new ArrayList<String>();
    permute("", s, results);

    return results;
}
  
private void permute(String prefix, String suffix, ArrayList<String> results) {

    if (suffix.length() == 0) {
        results.add(prefix);
    } else {
        for (int i = 0; i < suffix.length(); i++) {
            permute(prefix + suffix.charAt(i), suffix.substring(0, i) + suffix.substring(i+1, suffix.length()), results);
        }
    }

}
`,
    
}