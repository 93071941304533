export default {

info: `
<div>
    <h1>Reverse Singly Linked List</h1>
</div>
<div>
    <p>Reverse a singly linked list.</p>
</div>
<div>
    <code>
        Input: 1->2->3->4->5->NULL
        Output: 5->4->3->2->1->NULL  
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'JAVA'],

PYTHON: `
def reverse(head):
    prev_node = None
    curr_node = head
    next_node = None

    while curr_node != None:
        next_node = curr_node.next
        curr_node.next = prev_node
        prev_node = curr_node
        curr_node = next_node

    return prev_node
`,

JAVA: `
public ListNode reverse(ListNode head) {
    ListNode prev = null;
    ListNode curr = head;
    ListNode next = null;

    while(curr != null) {
        next = curr.next;
        curr.next = prev;
        prev = curr;
        curr = next;
    }
    return prev;
}
`,
    
}