import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

// import { AngularFireModule } from 'angularfire2';
// import { AngularFireStorageModule } from 'angularfire2/storage';

import { AppRoutingModule } from './app-routing.module';

import { HomePage } from './page/home.page';
import { DecoderPage } from './page/decoder.page';

import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { CodePaneComponent } from './code-pane/code-pane.component';
import { PaneManagerComponent } from './pane-manager/pane-manager.component';

import { environment } from '../environments/environment';
import { InfoPaneComponent } from './info-pane/info-pane.component';
import { AppIconComponent } from './app-icon/app-icon.component';
import { CardListComponent } from './card-list/card-list.component';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { PseudocoderComponent } from './pseudocoder/pseudocoder.component';

@NgModule({
  declarations: [
    HomePage,
    DecoderPage,
    //
    AppComponent,
    SideNavComponent,
    TopNavComponent,
    CodePaneComponent,
    PaneManagerComponent,
    InfoPaneComponent,
    AppIconComponent,
    CardListComponent,
    SafeHTMLPipe,
    PseudocoderComponent,
  ],
  imports: [
    BrowserModule,
    CodemirrorModule,
    FormsModule,
    HttpClientModule,
    // AngularFireStorageModule,
    NgbAccordionModule,
    // AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
