export default {

info: `
<div>
    <h1>License Key Formatting</h1>
</div>
<div>
    <p>
        You are given a license key represented as a string S which 
        consists only alphanumeric character and dashes. 
        The string is separated into N+1 groups by N dashes.
    </p>
    <p>
        Given a number K, we would want to reformat the strings such 
        that each group contains exactly K characters, except for the 
        first group which could be shorter than K, but still must 
        contain at least one character. Furthermore, there must be a 
        dash inserted between two groups and all lowercase letters 
        should be converted to uppercase.
    </p>
    <p>
        Given a non-empty string S and a number K, format the 
        string according to the rules described above.
    </p>
</div>
<div>
    <code>
        Input: S = "5F3Z-2e-9-w", K = 4

        Output: "5F3Z-2E9W"

        Explanation: The string S has been split into two parts, each part has 4 characters.
        Note that the two extra dashes are not needed and can be removed.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def license_key_formatting(S, K):
    
    result = ""
    
    group_count = 0
    
    char_index = len(S) - 1
    while char_index >= 0:
        
        current_char = S[char_index].upper()
        
        if current_char == '-':
            char_index -= 1
        elif (group_count != 0) and (group_count % K == 0):
            result = '-' + result 
            group_count = 0
        else:
            result = str(current_char) + result
            group_count += 1
            char_index -= 1
        
    return result
`,

JAVA: `

`,
    
}