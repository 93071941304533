export default {

info: `
<div>
    <h1>Sort K Sorted Array</h1>
</div>
<div>
    <p>
        Given an array of n elements, where each element is at 
        most k away from its target position, devise an algorithm 
        that sorts in O(n log k) time. For example, let us consider 
        k is 2, an element at index 7 in the sorted array, can be at 
        indexes 5, 6, 7, 8, 9 in the given array.
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Create a data structure that functions as a min heap. 
            That means the lowest number will always be at the top.
        `,
    },
    {
        key: '2',
        description: `
            Push all the elements up to but not including the kth index into the min heap.
        `,
    },
    {
        key: '3',
        description: `
            Create a variable that will keep track of the index in the array that
            we need to read into the min heap next. Also create a variable that stores
            the sorted index in the array. We have not officaly sorted anything so it
            starts at 0.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [6],
        '2': [9,10],
        '3': [12,13]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import heapq

def k_sorted_array(arr, k):
    
    min_heap = []
    len_arr = len(arr)
    
    for i in range(k):
        heapq.heappush(min_heap, arr[i])
        
    read_index = k
    placement_index = 0
    
    while read_index < len_arr:
        heapq.heappush(min_heap, arr[read_index])
        read_index += 1
        
        arr[placement_index] = heapq.heappop(min_heap)
        placement_index += 1
        
    while len(min_heap) != 0:
        arr[placement_index] = heapq.heappop(min_heap)
        placement_index += 1
    
    return arr
`,

JAVA: `

`,
    
}