export default {

info: `
<div>
    <h1>Battleships in a Board</h1>
</div>
<div>
    <p>
        Given an 2D board, count how many battleships are in it. The battleships 
        are represented with 'X's, empty slots are represented with '.'s. 
        You may assume the following rules:
    </p>
    <ul>
        <li>You receive a valid board, made of only battleships or empty slots.</li>
        <li>
            Battleships can only be placed horizontally or vertically. In other words, 
            they can only be made of the shape 1xN (1 row, N columns) or 
            Nx1 (N rows, 1 column), where N can be of any size.
        </li>
        <li>
            At least one horizontal or vertical cell separates between 
            two battleships - there are no adjacent battleships.
        </li>
    </ul>
</div>
<div>
    <code>
        X..X
        ...X
        ...X
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We create a variable to store our answer. We will increment this whenever we hit
            a new battleship. 
        `,
    },
    {
        key: '2',
        description: `
            We have to search a matrix so we should immediatly start thinking
            about a depth first search. The first for loop will iterate through the rows.
            The second for loop will iterate through the columns in each row.
        `,
    },
    {
        key: '3',
        description: `
            If our current cell (row, col) position is an 'X', we know we hit a battle ship
            and can start the depth first search from here. As mentioned above, we will
            increment our counter as well.
        `,
    },
    {
        key: '4',
        description: `
            Because the depth first search is recursive, its always a good idea to create
            something that will stop the recursive operation. We will create another utility
            method that returns and true or false boolean if a continue exploring or stop
            exploring condition is met.
        `,
    },
    {
        key: '5',
        description: `
            In our utility function, we will check if the row index and column index
            are valid. Then we will check to see if the cuurent cell is part of 
            a battleship ('X') or not. If we hit true at the end of ths utility function,
            we can keep exploring this path.
        `,
    },
    {
        key: '6',
        description: `
            If we are still exploring by this point, we can mark this cell as explored by
            putting any other symbol that does not represent a battleship. This replaces having
            to have some type of other data structure to keep track of where we have gone. We only explore
            'X's. 
        `,
    },
    {
        key: '7',
        description: `
            Then we continue the recursive depth first search exploration, 
            going to the cell directy up, down, right, and left.
            Note how we increment the row and col paramenters to accomplish this.
        `,
    },
    {
        key: '8',
        description: `
            By the time our nested for loop finished we should have sometype of answer
            and can just return our counter variable.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6,7],
        '3': [8,9,10],
        '4': [16,17],
        '5': [26,27,28,29,30,31,32,33,34,35,36],
        '6': [19],
        '7': [21,22,23,24],
        '8': [12]
    },
    JAVA: {}
},

languages: ['PYTHON', 'CPLUSPLUS', 'KOTLIN'],

PYTHON: `
def count_battleships(board):
    
    battleship_count = 0
    
    for row in range(len(board)):
        for col in range(len(board[0])):
            if board[row][col] == 'X':
                battleship_count += 1
                count_util(board, row, col)
                
    return battleship_count
                
def count_util(board, row, col):
    
    if not valid_exploration(board, row, col):
        return
    
    board[row][col] = '.'
    
    count_util(board, row + 1, col)
    count_util(board, row - 1, col)
    count_util(board, row, col + 1)
    count_util(board, row, col - 1)

def valid_exploration(board, row, col):
    if row < 0 or row >= len(board):
        return False
    
    if col < 0 or col >= len(board[0]):
        return False
    
    if board[row][col] != 'X':
        return False
    
    return True
`,

JAVA: `

`,

CPLUSPLUS: `
int CountBattleships(std::vector<std::vector<char>>& board)
{
    int battleShipCount = 0;

    for (int row = 0; row < board.size(); ++row)
    {
        for (int col = 0; col < board[row].size(); ++col)
        {

            if (board[row][col] == 'X')
            {
                battleShipCount++;
                CountUtil(board, row, col);
            }
        }

    }

    return battleShipCount;
}

void CountUtil(std::vector<std::vector<char>>& board, int row, int col)
{

    if (!ValidExploration(board, row, col))
        return;

    board[row][col] = '.';

    CountUtil(board, row + 1, col);
    CountUtil(board, row - 1, col);
    CountUtil(board, row, col + 1);
    CountUtil(board, row, col - 1);

}

bool ValidExploration(std::vector<std::vector<char>>& board, int row, int col)
{
    if (row < 0 || row >= board.size())
        return false;

    if (col < 0 or col >= board[row].size())
        return false;

    if (board[row][col] != 'X')
        return false;

    return true;
}
`,

KOTLIN: `
class Solution {
    
    fun numIslands(area: MutableList<MutableList<Char>>): Int {
        var result: Int = 0;
        
        for ((rowIndex, row) in area.withIndex()) {
            for ((colIndex, cell) in row.withIndex()) {
            	if (cell == 'X') {
                    result += 1
                    depthFirstSearch(area, rowIndex, colIndex)
                }
        	}
        }
        
        return result
    }
    
    fun depthFirstSearch(area: MutableList<MutableList<Char>>, row: Int, col: Int) {
        
        if (!isValid(area, row, col)) {
            return
        }
        
        area[row][col] = '.'
        
        depthFirstSearch(area, row + 1, col)
        depthFirstSearch(area, row - 1, col)
        depthFirstSearch(area, row, col + 1)
        depthFirstSearch(area, row, col + 1)
    }
    
    fun isValid(area: MutableList<MutableList<Char>>, row: Int, col: Int): Boolean {
        
        if (row < 0 || row >= area.size) {
            return false
        }
        
        if (col < 0 || col >= area[row].size) {
            return false
        }
        
        if (area[row][col] != 'X') {
            return false
        }
        
        return true
    }
}
`
    
}