export default {

info: `
<div>
    <h1>Multipy Strings</h1>
</div>
<div>
    <p>
        Given two non-negative integers num1 and num2 represented as strings, 
        return the product of num1 and num2, also represented as a string.
    </p>
    <p>Input: num1 = "2", num2 = "3"</p>
    <p>Output: "6"</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def multiply_strings(num_one, num_two):
    char_to_int = {
        "0": 0,
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
    }
    
    num_one_int = 0
    base = 10 ** (len(num_one) - 1)
    for char in num_one:
        num_one_int += (char_to_int[char] * base)
        base //= 10
        
    num_two_int = 0
    base = 10 ** (len(num_two) - 1)
    for char in num_two:
        num_two_int += (char_to_int[char] * base)
        base //= 10

    return str(num_one_int * num_two_int)
`,

JAVA: `

`,
    
}