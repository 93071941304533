export default {

info: `
<div>
    <h1>Sort Characters By Frequency</h1>
</div>
<div>
    <p>Given a string, sort it in decreasing order based on the frequency of characters.</p>
</div>
<div>
    <code>
        Input:
        "tree"
        
        Output:
        "eert"
        
        Explanation:
        'e' appears twice while 'r' and 't' both appear once.
        So 'e' must appear before both 'r' and 't'. Therefore "eetr" is also a valid answer. 
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import heapq

def frequency_sort(string):

    frequency_map = {}
    for char in string:
        if char in frequency_map:
            frequency_map[char] += 1
        else:
            frequency_map[char] = 1
            
    heap = []
    
    for char in frequency_map:
        heapq.heappush(heap, (-frequency_map[char], char))
    
    result = ""
    
    while heap:
        multiple, char = heapq.heappop(heap)
        
        result += ((-1 * multiple) * char)
    
    return result
`,

JAVA: `

`,
    
}