export default {

info: `
<div>
    <h1>Merge k Sorted Lists</h1>
</div>
<div>
    <p>
        You are given an array of k linked-lists lists, 
        each linked-list is sorted in ascending order.
    </p>
    <p>Merge all the linked-lists into one sorted linked-list and return it.</p>
    <code>
        Input: lists = [[1,4,5],[1,3,4],[2,6]]
        Output: [1,1,2,3,4,4,5,6]
        Explanation: The linked-lists are:
        [
            1->4->5,
            1->3->4,
            2->6
        ]
        merging them into one sorted list:
        1->1->2->3->4->4->5->6
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
# Definition for singly-linked list.
# class ListNode(object):
#     def __init__(self, x):
#         self.val = x
#         self.next = None

class Solution(object):
    def mergeKLists(self, lists):
        
        head = curr = ListNode(0)
           
        heap = []
        for i in range(len(lists)):
            if lists[i]: 
                heapq.heappush(heap, (lists[i].val, i, lists[i]))
                
        while heap:
            node = heapq.heappop(heap)
            idx = node[1]
            curr.next = node[2]  
            curr = curr.next
            
            if curr.next:
                heapq.heappush(heap, (curr.next.val, idx, curr.next))

        return head.next
`,

JAVA: `

`,
    
}