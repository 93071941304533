export default {

info: `
<div>
    <h1>Search Sorted 2D Matrix</h1>
</div>
<div>
    <p>
        Every row is sorted left to right in non-decreasing order. 
        Every column is sorted top to bottom in non-decreasing order. 
        There are no guarantees that the first item of any row or column 
        relates to the last item in the previous row or column.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def search_matrix(matrix, value):
    row = 0
    col = len(matrix[0]) - 1
    while (row < len(matrix)) and col >= 0:
        if matrix[row][col] == value:
            return [row, col]
        elif matrix[row][col] > value:
            col -= 1
        else:
            row += 1
    return None
`,

CPLUSPLUS: `
#include <vector>

class Solution {
public:
    bool searchMatrix(std::vector<std::vector<int>>& matrix, int target) {
        int row = 0;
        int col = matrix[0].size() - 1;
        
        while (row < matrix.size() && col >= 0)
        {
            if (matrix[row][col] == target)
            {
                return std::vector<int>{ row, col };
            }
            else if (matrix[row][col] > target)
            {
                col--;
            }
            else
            {
                row++;
            }
        }
        
        return std::vector<int>{ -1, -1 };
    }
};
`,
    
}