export default {

info: `
<div>
    <h1>Fruit Into Baskets</h1>
</div>
<div>
    <p>In a row of trees, the i-th tree produces fruit with type tree[i].</p>
    <p>You start at any tree of your choice, then repeatedly perform the following steps:</p>
    <p>1. Add one piece of fruit from this tree to your baskets.  If you cannot, stop.</p>
    <p>2. Move to the next tree to the right of the current tree.  If there is no tree to the right, stop.</p>
    <p>
        Note that you do not have any choice after the initial 
        choice of starting tree: you must perform step 1, then step 2, 
        then back to step 1, then step 2, and so on until you stop.
    </p>
    <p>
        You have two baskets, and each basket can carry any 
        quantity of fruit, but you want each basket to only 
        carry one type of fruit each.
    </p>
    <p>What is the total amount of fruit you can collect with this procedure?</p>
</div>
<div>
    <code>
        Input: [1,2,1]
        Output: 3
        Explanation: We can collect [1,2,1].
    </code>
</div>
<div>
    <code>
        Input: [1,2,3,2,2]
        Output: 4
        Explanation: We can collect [2,3,2,2].
        If we started at the first tree, we would only collect [1, 2].
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def fruit_into_baskets(tree):
    
    if not tree or len(tree) == 0:
        return 0

    max_fruits = 1

    fruit_map = {}

    left = 0
    right = 0

    while right < len(tree):

        if len(fruit_map) <= 2:
            fruit_map[tree[right]] = right
            right += 1

        if (len(fruit_map) > 2):
            min_index = len(tree) - 1
            for key in fruit_map:
                min_index = min(min_index, fruit_map[key])

            left = min_index + 1
            del fruit_map[tree[min_index]]

        max_fruits = max(max_fruits, right - left)

    return max_fruits
`,

JAVA: `

`,
    
}