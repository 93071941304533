export default {

info: `
<div>
    <h1>Frog Jump</h1>
</div>
<div>
    <p>
        A frog is crossing a river. The river is divided into x units 
        and at each unit there may or may not exist a stone. The frog 
        can jump on a stone, but it must not jump into the water. 
    </p>
    <p>
        Given a list of stones' positions (in units) in sorted ascending order, 
        determine if the frog is able to cross the river by landing on the 
        last stone. Initially, the frog is on the first stone and assume the 
        first jump must be 1 unit.
    </p>
    <p>
        If the frog's last jump was k units, then its next jump must be 
        either k - 1, k, or k + 1 units. Note that the frog can only 
        jump in the forward direction.
    </p>
    <ul>
        <li>The number of stones is ≥ 2 and is < 1,100.</li>
        <li>Each stone's position will be a non-negative integer < 231.</li>
        <li>The first stone's position is always 0.</li></1>
    </ul>
</div>
<div>
    <code>
        [0,1,3,5,6,8,12,17]

        There are a total of 8 stones.
        The first stone at the 0th unit, second stone at the 1st unit,
        third stone at the 3rd unit, and so on...
        The last stone at the 17th unit.

        Return true. The frog can jump to the last stone by jumping 
        1 unit to the 2nd stone, then 2 units to the 3rd stone, then 
        2 units to the 4th stone, then 3 units to the 6th stone, 
        4 units to the 7th stone, and 5 units to the 8th stone.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def can_cross(stones):
    
    for i in range(3, len(stones)):
        if stones[i] > stones[i - 1] * 2:
            return False
        
    last_stone = stones[-1]
    
    stone_positions = set()
    for stone in stones:
        stone_positions.add(stone)
        
    positions = []
    jumps = []
    
    positions.append(0)
    jumps.append(0)
    
    while positions:
        position = positions.pop()
        jump_distance = jumps.pop()
        
        for i in range(jump_distance - 1, jump_distance + 2):
            if i <= 0:
                continue
                
            next_position = position + i

            if next_position == last_stone:
                return True
            elif next_position in stone_positions:
                positions.append(next_position)
                jumps.append(i)
                
    return False
`,

JAVA: `

`,
    
}