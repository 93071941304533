export default {

info: `
<div>
    <h1>Robot Room Cleaner</h1>
</div>
<div>
    <p>Given a robot cleaner in a room modeled as a grid.</p>
    <p>Each cell in the grid can be empty or blocked.</p>
    <p>
        The robot cleaner with 4 given APIs can move forward, 
        turn left or turn right. Each turn it made is 90 degrees.
    </p>
    <p>
        When it tries to move into a blocked cell, its bumper 
        sensor detects the obstacle and it stays on the current cell.
    </p>
    <p>
        Design an algorithm to clean the entire room using only the 4 given APIs shown below.
    </p>
</div>
<div>
    <code>
        interface Robot {
            // returns true if next cell is open and robot moves into the cell.
            // returns false if next cell is obstacle and robot stays on the current cell.
            boolean move();
            
            // Robot will stay on the same cell after calling turnLeft/turnRight.
            // Each turn will be 90 degrees.
            void turnLeft();
            void turnRight();
            
            // Clean the current cell.
            void clean();
        }
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def clean_room(robot):
    clean_dfs(robot, 0, 0, 0, 1, set())
    
def clean_dfs(robot, x, y, direction_x, direction_y, visited):
    
    robot.clean()
    visited.add((x, y))

    for move_option in range(4):
        
        neighbor_x = x + direction_x
        neighbor_y = y + direction_y
        
        if (neighbor_x, neighbor_y) not in visited and robot.move():
            clean_dfs(robot, neighbor_x, neighbor_y, direction_x, direction_y, visited)
            robot.turnRight()
            robot.turnRight()
            robot.move()
            robot.turnRight()
            robot.turnRight()
            
        robot.turnRight()
        
        direction_x = -direction_y
        direction_y = direction_x
`,

JAVA: `

`,
    
}