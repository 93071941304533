export default {

info: `
<div>
    <h1>Add Numbers Without a Plus</h1>
</div>
<div>
    <p>Add two integer values without using the "+" operator.</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            Start a loop the continues while the second value(b) to be
            added is not equal to 0. In the loop, we will be using
            bitwise operations to combine the second value into the first value.
        `,
    },
    {
        key: '2',
        description: `
            Like adding numbers on paper, we will add these values from right
            to left. When the digits create overflow, we carry the oveflow to next
            digits to the left. When using bitwise operators, a AND operation will give
            us the number to carry to the left.
        `,
    },
    {
        key: '3',
        description: `
            To actually add two numbers using bitwise operators, we use the OR operation.
            It will give us the value at the current index. We already calculated the overflow
            value above using the AND operation.
        `,
    },
    {
        key: '4',
        description: `
            To bring the carry value we calculated one spot to the left, we simply shift
            the carry value 1 bit to the left and assign it the the second value(b)
        `,
    },
    {
        key: '5',
        description: `
            We combined b into a so make sure we return out a.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [6],
        '3': [8],
        '4': [10],
        '5': [12]
    },
    JAVA: {
        '1': [4],
        '2': [6],
        '3': [8],
        '4': [10],
        '5': [12]
    },
    CPLUSPLUS: {
        '1': [4],
        '2': [6],
        '3': [8],
        '4': [10],
        '5': [12]
    },
    JAVASCRIPT: {
        '1': [4],
        '2': [6],
        '3': [7],
        '4': [8],
        '5': [10]
    }
},

languages: ['PYTHON', 'JAVA', 'CPLUSPLUS', 'JAVASCRIPT'],

PYTHON: `
def add_two_numbers(a, b):

    while b != 0:

        carry = a & b

        a = a ^ b

        b = carry << 1

    return a
`,

JAVA: `
public int addTwoNumbers(int a, int b) {

    while (b != 0) { 

        int carry = a & b; 

        a = a ^ b; 

        b = carry << 1; 
    } 
    return a; 
} 
`,

CPLUSPLUS: `
int addTwoNumbers(int a, int b)  
{  
    while (b != 0)  
    {   
        int carry = a & b;  
  
        a = a ^ b;  
  
        b = carry << 1;  
    }  
    return a;  
}  
`,

JAVASCRIPT: `
let addTwoNumbers = function(a, b) 
{
    while (b !== 0)
    {
        let carry = a & b;
        a = a ^ b;
        b = carry << 1;
    }
    return a;
};
`,
    
}