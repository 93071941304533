export default {

info: `
<div>
    <h1>Partition to K Equal Sum Subsets</h1>
</div>
<div>
    <p>
        Given an array of integers nums and a positive integer k, find 
        whether it's possible to divide this array into k non-empty 
        subsets whose sums are all equal.
    </p>
</div>
<div>
    <code>
        Input: nums = [4, 3, 2, 3, 5, 2, 1], k = 4
        Output: True
        Explanation: It's possible to divide it into 4 subsets (5), (1, 4), (2,3), (2,3) with equal sums.
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def can_parition_k_equal_subsets(nums, k):
    
    sum_of_items = sum(nums)
    
    if (k == 0) or (sum_of_items % k != 0):
        return False
    
    return can_partition(0, nums, [False for i in range(20)], k, 0, sum_of_items / k)

def can_partition(iter_start, arr, used, k, progress_bucket_sum, target_bucket_sum):
    if k == 1:
        return True
    
    if progress_bucket_sum == target_bucket_sum:
        return can_partition(0, arr, used, k-1, 0, target_bucket_sum)
    
    for i in range(iter_start, len(arr)):
        if (used[i] == False) and (progress_bucket_sum + arr[i] <= target_bucket_sum):
            used[i] = True
            
            if (can_partition(i + 1, arr, used, k, progress_bucket_sum + arr[i], target_bucket_sum)):
                return True
            
            used[i] = False
            
    return False
`,

JAVA: `

`,
    
}