export default {

info: `
<div>
    <h1>Toggle Nth Bit</h1>
</div>
<div>
    <p>
        Write a program that takes an integer and toggles the n-th bit in the binary
        representation of that integer.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def toggle_nth_bit(num, nth_bit):
    return num ^ (1 << nth_bit)
`,

JAVA: `

`,
    
}