export default {

info: `
<div>
    <h1>Symmetric Tree</h1>
</div>
<div>
    <p>Given a binary tree, check whether it is a mirror of itself (ie, symmetric around its center).</p>
</div>
<div>
    <code>
         1
        / \
       2   2
      / \ / \
     3  4 4  3
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def is_symmetric(root):
    return root == None or is_symmetric_recur(root.left, root.right)

def is_symmetric_recur(left_sub, right_sub):
    if left_sub == None and right_sub == None:
        return True
    elif left_sub != None and right_sub != None:
        return (
            left_sub.value == right_sub.value and
            is_symmetric_recur(left_sub.left, right_sub.right) and
            is_symmetric_recur(left_sub.right, right_sub.left)
        )
    return False
`,

JAVA: `

`,
    
}