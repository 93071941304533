export default {

info: `
<div>
    <h1>Integer to English Words</h1>
</div>
<div>
    <p>
        Convert a non-negative integer to its english words 
        representation. Given input is guaranteed to be less than 231 - 1.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def integer_to_english(num):
    
    result = ""
    groups_map = ["", "Thousand", "Million", "Billion"]
    working_num = num
    
    i = 0
    while True:
        remain = working_num % 1000
        working_num //= 1000
        three_digit_word = three_digits_to_word(remain)
        
        if len(three_digit_word) > 0:
            result = three_digit_word + " " + groups_map[i] + " " + result
        i += 1
        
        if working_num == 0:
            break
            
    result = result.strip()
    
    if len(result) == 0:
        return "Zero"
    
    return result
    
def three_digits_to_word(num):
    digits_map = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", 
                "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", 
                "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"]

    tens_map = ["", "", "Twenty", "Thirty", "Forty", "Fifty", 
                "Sixty", "Seventy", "Eighty", "Ninety"]
    
    
    hundreds = num // 100
    below_hundreds = num % 100
    result = ""
    
    if hundreds > 0:
        result += digits_map[hundreds] + " Hundred "
        
    if num < 20:
        result += digits_map[below_hundreds]
    else:
        tens = below_hundreds // 10
        ones = below_hundreds % 10
        result += tens_map[tens]
        result += " " + digits_map[ones]

    return result.strip()
`,

JAVA: `

`,
    
}