export default {

info: `
<div>
    <h1>BST Delete Node</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Node:

    def __init__(self, value):
        self.value = value
        self.left = None
        self.right = None

def delete(node, value):
    
    if node is None:
        return node

    if value < node.value:

        node.left = delete(node.left, value)

    elif value > node.value:

        node.right = delete(node.right, value)

    else:

        if node.left is None:
            temp = node.right
            node = None
            return temp

        elif node.right is None:
            temp = node.left
            node = None
            return temp

        temp = min_value_node(node.right)

        node.value = temp.value

        node.right = delete(node.right, temp.value)

    return node


def min_value_node(node):
    current_node = node

    while current_node.left is not None:
        current_node = current_node.left

    return current_node
`,

JAVA: `

`,
    
}