export default {

info: `
<div>
    <h1>Two Sum</h1>
</div>
<div>
    <p>Given an array of integers, return indices of the two numbers such that they add up to a specific target.</p>
    <p>You may assume that each input would have exactly one solution, and you may not use the same element twice.</p>
</div>
<div>
    <code>
        Given nums = [2, 7, 11, 15], target = 9,

        Because nums[0] + nums[1] = 2 + 7 = 9,
        return [0, 1].    
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'SWIFT'],

PYTHON: `
class Solution:

    def two_sum(self, nums, target):
            
        num_map = {}
        
        for index, num in enumerate(nums):
            
            complement = target - num
            
            if complement in num_map:
                return [num_map[complement], index]
            
            num_map[num] = index
`,

SWIFT: `
class Solution {

    func twoSum(_ nums: [Int], _ target: Int) -> [Int] {

        var numMap: [Int:Int] = [:]

        for (index, value) in nums.enumerated() {

            let complement = target - value

            if numMap[complement] != nil {
                return [numMap[complement]!, index]
            }
            
            numMap[value] = index
        }

        return []
    }
}
`,

JAVA: `

`,
    
}