export default {

info: `
<div>
    <h1>Decode String</h1>
</div>
<div>
    <p>
        Given an encoded string, return it's decoded string.
    </p>
    <p>
        The encoding rule is: k[encoded_string], where the encoded_string 
        inside the square brackets is being repeated exactly k times. 
        Note that k is guaranteed to be a positive integer.
    </p>
    <p>
        You may assume that the input string is always valid; 
        No extra white spaces, square brackets are well-formed, etc.
    </p>
    <p>
        Furthermore, you may assume that the original data does not contain any 
        digits and that digits are only for those repeat numbers, k. 
        For example, there won't be input like 3a or 2[4].
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            When dealing with operations that have to be grouped by brackets or
            parentheses, we whould start thinking about stacks. The intution here is
            that stacks are prefect for keeping track of nested groupings. To start
            off, lets create our stacks. One to keep track of parentheses and progress
            and one that will hold the answer.
        `,
    },
    {
        key: '2',
        description: `
            Then start a loop that goes through the entire message. Be sure to have
            some way to quickly reference the index that we are at in the message.
            There will be three main operations in this loop depending on if we are
            at a digit, closed backet, or open bracket.
        `,
    },
    {
        key: '3',
        description: `
            If we are at a digit, we we want to find how many consecutive digits there
            are in case our multiplier is in the tens, hundreds, etc. So we will create another
            loop while we see digits and add them them to a varaible that holds the entire multiplier.
        `,
    },
    {
        key: '4',
        description: `
            Once we are finished getting the entire number, we add it to our multiplier stack.
        `,
    },
    {
        key: '5',
        description: `
            If we hit a closed bracket, we know its time to do sometype of operation. So lets
            take the next multiplier off the multiplier stack. Also, lets create a variable
            that will store the pattern that we will want to multiply against the multplier.
        `,
    },
    {
        key: '6',
        description: `
            To start building the current pattern, pop the next character in the string stack
        `,
    },
    {
        key: '7',
        description: `
            We then create a loop that keeps popping out characters from our strick stack
            to see the entire string that we want to muliply by. The string might have more
            than one character so keep going until we hit an open bracket or there are
            no more characters in the stack.
        `,
    },
    {
        key: '8',
        description: `
            Characters get put into the stack in reverse order so be sure to append characters
            to the begginning of the varaible that will store our pattern.
        `,
    },
    {
        key: '9',
        description: `
            Once we know the entire pattern, we can finally mutliply the pattern by the multiplier.
            Once we get this final value, add it back into the string stack. This entire pattern might
            have an outer bracket that will multiply the calculated pattern even more times.
        `,
    },
    {
        key: '10',
        description: `
            Be sure to increment our index to keep the entire algorithm going.
        `,
    },
    {
        key: '11',
        description: `
            If we see an open bracket or character, just add it to our string stack and
            keep the loop going. Again, we only want to do extra stuff if we see
            a closed backet or digit.
        `,
    },
    {
        key: '12',
        description: `
            Now all of our groupings should be in the string stack because we were calculating
            them and adding them back in. Keep popping things out of the string stack and appending
            them to the beginning of the result because we are in reverse order. Once the stack
            is empty we have our answer so just return it out.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4,5],
        '2': [7,8,10,18,33],
        '3': [12,13,14,15],
        '4': [16],
        '5': [20,21],
        '6': [23],
        '7': [24,26],
        '8': [25],
        '9': [28,29],
        '10': [31],
        '11': [35,36],
        '12': [38,39,40,42]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def decode_string(message):
    
    multiplier_stack = []
    string_stack = []
    
    i = 0
    while i < len(message):
        
        if message[i].isdigit():

            multiplier = ''
            while message[i].isdigit():
                multiplier += message[i]
                i += 1
            multiplier_stack.append(int(multiplier))

        elif message[i] == ']':
            
            multiplier = multiplier_stack.pop()
            current_pattern = ''
            
            current_char = string_stack.pop()
            while current_char != '[' and current_char != None:
                current_pattern = current_char + current_pattern
                current_char = string_stack.pop() if len(string_stack) > 0 else None
            
            current_pattern = (current_pattern * multiplier)
            string_stack.append(current_pattern)
            
            i += 1

        else:
            
            string_stack.append(message[i])
            i += 1
    
    result = ''
    while len(string_stack) > 0:
        result = string_stack.pop() + result
        
    return result
`,

JAVA: `

`,
    
}