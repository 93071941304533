export default {

info: `
<div>
    <h1>Maximum Depth of Binary Tree</h1>
</div>
<div>
    <p>
        Given a binary tree, find its maximum depth. 
    </p>
    <p>
        The maximum depth is the number of nodes along the longest 
        path from the root node down to the farthest leaf node.
    </p>
    <p>Given binary tree [3,9,20,null,null,15,7]</p>
    <p>return its depth = 3.</p>
</div>
<div>
    <code>
         3
        / \
       9  20
         /  \
        15   7
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: [],

PYTHON: `
def max_depth(root):

    if not root:
        return 0

    return 1 + (max_depth(root.left), max_depth(root.right))
`,

JAVA: `

`,
    
}