export default {

info: `
<div>
    <h1>Binary Tree Maximum Path Sum</h1>
</div>
<div>
    <p>
        Given a non-empty binary tree, find the maximum path sum.
    </p>
    <p>
        For this problem, a path is defined as any sequence of nodes 
        from some starting node to any node in the tree along the parent-child 
        connections. The path must contain at least one node and does not 
        need to go through the root.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Solution(object):

    def __init__(self):
        self.current_max = float('-inf')

    def max_path_sum(self, root):
        self.max_path_sum_util(root)
        return self.current_max

    def max_path_sum_util(self, root):

        if not root:
            return 0

        left_max = max(self.max_path_sum_util(root.left), 0)
        right_max = max(self.max_path_sum_util(root.right), 0)

        self.current_max = max(left_max + right_max + root.value, self.current_max)

        return max(left_max, right_max) + root.val
`,

JAVA: `

`,
    
}