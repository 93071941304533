export default {

info: `
<div>
    <h1>Subarray Sums Equals K</h1>
</div>
<div>
    <p>
        Given an array of integers and an integer k, you need to find 
        the total number of continuous subarrays whose sum equals to k.
    </p>
</div>
<div>
    <code>
        Input:nums = [1,1,1], k = 2
        Output: 2
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: 'Initialize a counter variable starting at 0. This will be what the function returns',
    },
    {
        key: '2',
        description: `
            Initialize a variable that will keep a running sum as we loop through the array.
        `,
    },
    {
        key: '3',
        description: `
            Create a data structure that will give us constant time access to running sums
            that were previously calculated. We know at the beginning, our sum was 0 so we can
            add that to this data structure with a count of one because we already saw it.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [5],
        '3': [7,8]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def subarray_sum(nums, k):
        
    count = 0
    curr_sum = 0

    sum_map = {}
    sum_map[0] = 1

    for i in range(len(nums)):

        curr_sum += nums[i]
        other_sum = (curr_sum - k)

        if other_sum in sum_map:
            count += sum_map[other_sum]

        if curr_sum in sum_map:
            sum_map[curr_sum] += 1
        else:
            sum_map[curr_sum] = 1

    return count
`,

JAVA: `

`,
    
}