export default {

info: `
<div>
    <h1>Continuous Subarray Sum</h1>
</div>
<div>
    <p>
        Given a list of non-negative numbers and a target integer k, write a 
        function to check if the array has a continuous subarray of size at least 
        2 that sums up to the multiple of k, that is, sums up to n*k where 
        n is also an integer.
    </p>
</div>
<div>
    <code>
        Input: [23, 2, 4, 6, 7],  k=6
        Output: True
        Explanation: Because [2, 4] is a continuous subarray of size 2 and sums up to 6  
    </code>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We will create a variable to store our current sum.
        `,
    },
    {
        key: '2',
        description: `
            Also, lets create some type of hashmap data-structure 
            that keeps track of the current sums we have seen so far
            and their indexes. We can initialize a current sum
            of 0 at the negative index because we have not even started yet.
        `,
    },
    {
        key: '3',
        description: `
            We will start the main algorithm by creating a loop that goes 
            through the list of numbers.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4],
        '2': [5],
        '3': [7]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def continous_subarray_sum(nums, k):
    
    curr_sum = 0
    curr_sum_mod_k_map = { 0: -1 }

    for i in range(len(nums)):
        
        curr_sum += nums[i]
        
        if k != 0:
            curr_sum %= k
            
        if curr_sum in curr_sum_mod_k_map:
            if i - curr_sum_mod_k_map[curr_sum] > 1:
                return True
        else:
            curr_sum_mod_k_map[curr_sum] = i

    return False
`,

JAVA: `

`,
    
}