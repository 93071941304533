import { Component } from '@angular/core';

@Component({
  selector: 'app-decoder-page',
  templateUrl: './decoder.page.html',
  styleUrls: ['./decoder.page.scss']
})
export class DecoderPage {

}
