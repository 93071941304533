export default {

info: `
<div>
    <h1>Product of Array Except Self</h1>
</div>
<div>
    <p>
        Given an array nums of n integers where n > 1,  return an array output 
        such that output[i] is equal to the product of 
        all the elements of nums except nums[i].
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def product_except_self(nums):

    nums_len = len(nums)
    output = [1] * nums_len
    
    product = 1
    for i in range(nums_len):
        output[i] *= product
        product *= nums[i]
                        
    product = 1
    for i in range(nums_len - 1, -1, -1):
        output[i] *= product
        product *= nums[i]               
    
    return output
`,

JAVA: `

`,
    
}