export default {

info: `
<div>
    <h1>Remove Nth Node from End of List</h1>
</div>
<div>
    <p>Given a linked list, remove the n-th node from the end of list and return its head.</p>
    <p>Given linked list: 1->2->3->4->5, and n = 2.</p>
    <p>After removing the second node from the end, the linked list becomes 1->2->3->5.</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def remove_nth_from_end(head, n):

    dummy_node = Node('DUMMY')
    dummy_node.next = head
    first_node = dummy
    second_node = dummy

    for i in range(n + 1):
        first_node = first_node.next

    while first_node is not None:
        first_node = first_node.next
        second_node = second_node.next

    second_node.next = second_node.next.next

    return dummy_node.next
`,

JAVA: `

`,
    
}