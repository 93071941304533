export default {

info: `
<div>
    <h1>Prototype Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import copy


class Prototype:

    def __init__(self):
        # Expensive or complex instantiation
        pass

def main():
    prototype = Prototype()
    prototype_copy = copy.deepcopy(prototype)
`,

JAVA: `

`,
    
}