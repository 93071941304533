export default {

info: `
<div>
    <h1>Binary Search</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def binary_search(arr, target)

    start = 0
    end = len(arr) - 1

    while start <= end:
        
        middle = start + (end - start) // 2

        if arr[middle] == target:
            return middle
        elif arr[middle] < target:
            start = middle + 1
        else:
            end = middle - 1

    return -1
`,

JAVA: `

`,
    
}