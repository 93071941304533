import { LANG } from './lang';

export default [
    {
        title: "A* Pathfinding",
        url: 'a_star',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Bellman Ford Shortest Paths",
        url: 'bellman_ford',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Binary Search",
        url: 'binary_search',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Boruvka's Minimum Spanning Tree",
        url: 'boruvkas_mst',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Bubble Sort",
        url: 'bubble_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Bucket Sort",
        url: 'bucket_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Counting Sort",
        url: 'counting_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'DFS Topological Sort',
        url: 'dfs_topological_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Dijkstra's Shortest Path",
        url: 'dijkstras',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Dinic's Maximum Flow",
        url: 'dinics',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Floyd Warshall Shortest Paths",
        url: 'floyd_warshall',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Heap Sort",
        url: 'heap_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Insertion Sort",
        url: 'insertion_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Kadane's Maximum Subarray",
        url: 'kadanes',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Khan's Topological Sorting",
        url: 'khans',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Knuth Morris Pratt String Search',
        url: 'knuth_morris_pratt',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Kruskal's Minimum Spanning Tree",
        url: 'kruskals_mst',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Merge Sort',
        url: 'merge_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Prim's Minimum Spanning Tree",
        url: 'prims_mst',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Quick Sort',
        url: 'quick_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Radix Sort',
        url: 'radix_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Selection Sort',
        url: 'selection_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Shell Sort',
        url: 'shell_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'SM-2 Spaced Repetition',
        url: 'sm2',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: "Tarjan's Strongly Connected Components",
        url: 'tarjans_sccs',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Tim Sort',
        url: 'tim_sort',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Tree Sort',
        url: 'tree_sort',
        langs: [
            LANG.PYTHON,
        ]
    }
]