export default {

info: `
<div>
    <h1>Bellman Ford Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def bellman_ford(graph, start_node):
    
    node_count = len(graph)
    
    dist_table = {}
    for node in graph:
        dist_table[node] = float('inf')

    dist_table[start_node] = 0
        
    for i in range(node_count):
        for curr_node_key in graph:
            for neighbor in graph[curr_node_key]:
                
                edge_weight_to_neighbor = graph[curr_node_key][neighbor]
                
                if dist_table[curr_node_key] + edge_weight_to_neighbor < dist_table[neighbor]:
                    dist_table[neighbor] = dist_table[curr_node_key] + edge_weight_to_neighbor
                    
    for i in range(node_count):
        for curr_node_key in graph:
            for neighbor in graph[curr_node_key]:
                
                edge_weight_to_neighbor = graph[curr_node_key][neighbor]
                
                if dist_table[curr_node_key] + edge_weight_to_neighbor < dist_table[neighbor]:
                    dist_table[neighbor] = float('-inf')
                    
    return dist_table
`,

JAVA: `

`,
    
}