export default {

info: `
<div>
    <h1>Max Rectangle in Histogram</h1>
</div>
<div>
    <p>
        Given n non-negative integers representing the histogram's bar 
        height where the width of each bar is 1, find the area of largest 
        rectangle in the histogram.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def max_area_histogram(histogram):
    
    max_area = float('-inf')
    height_index_stack = []
    
    index = 0
    while index < len(histogram):
        
        if not height_index_stack or histogram[height_index_stack[-1]] <= histogram[index]:
            height_index_stack.append(index)
            index += 1
        else:
            height = histogram[height_index_stack.pop()]
            width = index
            
            if height_index_stack:
                width = index - height_index_stack[-1] - 1
            
            area = height * width
            max_area = max(max_area, area)
            
    while height_index_stack:
        height = histogram[height_index_stack.pop()]
        width = index

        if height_index_stack:
            width = index - height_index_stack[-1] - 1
            
        area = height * width
        max_area = max(max_area, area)
        
    return max_area
`,

JAVA: `

`,
    
}