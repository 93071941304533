export default {

info: `
<div>
    <h1>Observer Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'JAVA'],

PYTHON: `
class Observable:

    def __init__(self):
        self.observers = []

    def add_observer(self, observer):
        self.observers.append(observer)

    def remove_observer(self, observer):
        self.observers.remove(observer)

    def notify(self):
        for observer in self.observers:
            observer.update()

class Observer:

    def update(self):
        pass
`,

JAVA: `
import java.util.List;
import java.util.ArrayList;

public class Observable {

    private List<Observable> observers = new ArrayList<>();

    public void addObserver(Channel channel) {
        this.observers.add(channel);
    }

    public void removeObserver(Channel channel) {
        this.observers.remove(channel);
    }

    public void notify() {
        for (Observer observer : this.observers) {
            observer.update();
        }
    }
}

public class Observer {

    public void update() {
        
    }
}
`,
    
}