export default {

info: `
<div>
    <h1>Regualr Expression Matching</h1>
</div>
<div>
    <p>
        Given an input string (s) and a pattern (p), implement 
        regular expression matching with support for '.' and '*'.
    </p>
    <p>'.' Matches any single character.</p>
    <p>'*' Matches zero or more of the preceding element.</p>
    <p>s could be empty and contains only lowercase letters a-z.</p>
    <p>p could be empty and contains only lowercase letters a-z, and characters like . or *.</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            For conveniance, put the lengths of the text and the pattern
            into seperate variables. These will be used several times
            later.
        `,
    },
    {
        key: '2',
        description: `
            Create a table that will store the results of the sub problems.
            We are using the tabulation method in dynamic programming (bottom-up approach).
            It will have as many columns as the text length plus one to account for an empty
            text character. It will have as many rows as the pattern length plus one to account
            for an empty pattern character.
        `,
    },
    {
        key: '3',
        description: `
            There is at least one match when there is an empty pattern character and empty
            text character.
        `,
    },
    {
        key: '4',
        description: `
            Based on the question, we know that '*' characters mean that we will accept
            any character or even no characters before it. That means we can just copy
            the previous answer that is one spot above any '*' characters. So if the
            cell one spot above (dp[row - 1][0]) the current cell (dp[row][0]) is 
            true and the current pattern character (pattern[row - 1]) is a '*', 
            we know that his current cell is true.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4,5],
        '2': [7],
        '3': [9],
        '4': [11, 12]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def regular_expression_matching(text, pattern):
    
    pattern_len = len(pattern)
    text_len = len(text)

    dp = [[False] * (text_len + 1) for row in range(pattern_len + 1)]

    dp[0][0] = True

    for row in range(2, pattern_len + 1):
        dp[row][0] = dp[row - 2][0] and pattern[row - 1] == '*'
        

    for row in range(1, pattern_len + 1):
        for col in range(1, text_len + 1):
            
            if pattern[row - 1] != "*":

                dp[row][col] = (dp[row - 1][col - 1] and 
                    (pattern[row - 1] == text[col - 1] or pattern[row - 1] == '.'))
                
            else:

                dp[row][col] = dp[row - 2][col] or dp[row - 1][col]

                if pattern[row - 2] == text[col - 1] or pattern[row - 2] == '.':
                    dp[row][col] = dp[row][col] or dp[row][col - 1]

    return dp[-1][-1]
`,

JAVA: `

`,
    
}