export default {

info: `
<div>
    <h1>Peak Element</h1>
</div>
<div>
    <p>A peak element is an element that is greater than its neighbors.</p>
    <p>
        Given an input array nums, where nums[i] ≠ nums[i+1], 
        find a peak element and return its index.
    </p>
    <p>
        The array may contain multiple peaks, in that case 
        return the index to any one of the peaks is fine.
    </p>
    <p>
        You may imagine that nums[-1] = nums[n] = -∞.
    </p>
</div>
<div>
    <p>Input: nums = [1,2,3,1]</p>
    <p>Output: 2</p>
    <p>Explanation: 3 is a peak element and your function should return the index number 2.</p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We can just do a binary search on this so initialize a variable that represents the
            start index and another variable that holds the end index (length of array minus one).
        `,
    },
],

templates: {
    PYTHON: {
        '1': [3,4]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def find_peak_element(nums):
    start = 0
    end = len(nums) - 1
    
    while start < end:
            
        middle = start + (end - start) // 2 
        
        if nums[middle] < nums[middle + 1]:
            start = middle + 1
        else:
            end = middle
            
    return start
`,

JAVA: `

`,
    
}