export default {

info: `
<div>
    <h1>Flatten Nested List Iterator</h1>
</div>
<div>
    <p>Given a nested list of integers, implement an iterator to flatten it.</p>
    <p>Each element is either an integer, or a list -- whose elements may also be integers or other lists.</p>
    <p>Input: [[1,1],2,[1,1]]</p>
    <p>Output: [1,1,2,1,1]</p>
</div>
<div>
    <code>
        """
        This is the interface that allows for creating nested lists.
        You should not implement it, or speculate about its implementation
        """
        class NestedInteger(object):
            def isInteger(self):
                """
                @return True if this NestedInteger holds a single integer, rather than a nested list.
                :rtype bool
                """
        
            def getInteger(self):
                """
                @return the single integer that this NestedInteger holds, if it holds a single integer
                Return None if this NestedInteger holds a nested list
                :rtype int
                """
        
            def getList(self):
                """
                @return the nested list that this NestedInteger holds, if it holds a nested list
                Return None if this NestedInteger holds a single integer
                :rtype List[NestedInteger]
                """
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class NestedIterator(object):

    def __init__(self, nestedList):
        self.stack = [[nestedList, 0]]

    def next(self):
        self.hasNext()
        nestedList, i = self.stack[-1]
        self.stack[-1][1] += 1
        return nestedList[i].getInteger()
            
    def hasNext(self):
        s = self.stack
        while s:
            nestedList, i = s[-1]
            if i == len(nestedList):
                s.pop()
            else:
                x = nestedList[i]
                if x.isInteger():
                    return True
                s[-1][1] += 1
                s.append([x.getList(), 0])
        return False
`,

JAVA: `

`,
    
}