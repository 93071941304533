export default {

info: `
<div>
    <h1>Proxy Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import abc


class Subject(metaclass=abc.ABCMeta):

    @abc.abstractmethod
    def request(self):
        pass


class Proxy(Subject):

    def __init__(self, real_subject):
        self._real_subject = real_subject

    def request(self):
        self._real_subject.request()

        
class ConcreteSubject(Subject):

    def request(self):
        pass


def main():
    concrete_subject = ConcreteSubject()
    proxy = Proxy(concrete_subject)
    proxy.request()
`,

JAVA: `

`,
    
}