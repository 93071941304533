export default {

info: `
<div>
    <h1>Loop through array/list with index</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON', 'CPLUSPLUS', 'SWIFT', 'JAVA', 'CSHARP', 'KOTLIN', 'GO', 'C'],

PYTHON: `
sequence = [1, 2, 3]

for index, value in enumerate(sequence):
    print(f'index {index} value {value}')
`,

JAVA: `
int[] sequence = {1, 2, 3};

for (int i = 0; i < sequence.size(); i++) {
    System.out.println("index: " + i + " value: " + sequence[i]);
}
`,

KOTLIN: `
val sequence = listOf(1, 2, 3)

for ((index, value) in sequence.withIndex()) {
    println("index {index} value {value}")
}
`,

SWIFT: `
let sequence = [1, 2, 3]

for (index, value) in sequence.enumerated() {
    print("index \\(index) value \\(value)")
}
`,

CSHARP: `
List<int> sequence = new List<int> {1, 2, 3};

foreach (var item in sequence.Select((value, index) => (value, index)))
{
    Console.WriteLine($"index {item.index} value {item.value}");
}
`,

GO: `
import (
    "fmt"
)
 
func main() {
 
    var items []int = []int{1, 2, 3, 4, 5}
    for i, v := range items {
        fmt.Println(i, v)
    }
 
}
`
    
}