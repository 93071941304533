export default {

info: `
<div>
    <h1>Walls and Gates</h1>
</div>
<div>
    <p>You are given a m x n 2D grid initialized with these three possible values.</p>
    <p>1 - A wall or an obstacle.</p>
    <p>0 - A gate.</p>
    <p>
        INF - Infinity means an empty room. We use the value 231 - 1 = 2147483647 to 
        represent INF as you may assume that the distance to a gate is less than 2147483647.
    </p>
</div>
<div>
    <code>
        INF  -1  0  INF
        INF INF INF  -1
        INF  -1 INF  -1
        0  -1 INF INF
    </code>
</div>
<div>
    <code>
        3  -1   0   1
        2   2   1  -1
        1  -1   2  -1
        0  -1   3   4
    </code>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def walls_and_gates(rooms):
    
    for row in range(len(rooms)):
        for col in range(len(rooms[0])):
            if rooms[row][col] == 0:
                walls_dfs(rooms, 0, row, col)
                
    return rooms

def walls_dfs(rooms, dist, row, col):
    
    if not valid_exploration(rooms, dist, row, col):
        return
    
    rooms[row][col] = dist
    
    walls_dfs(rooms, dist + 1, row + 1, col)
    walls_dfs(rooms, dist + 1, row - 1, col)
    walls_dfs(rooms, dist + 1, row, col + 1)
    walls_dfs(rooms, dist + 1, row, col - 1)
    
def valid_exploration(rooms, dist, row, col):
    if row < 0 or row >= len(rooms):
        return False
    if col < 0 or col >= len(rooms[0]):
        return False
    if rooms[row][col] < dist:
        return False
    
    return True
`,

JAVA: `

`,
    
}