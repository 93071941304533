import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LANG_MAP } from '../constants/lang';
import PROBLEM_SAMPLES from '../problem-samples';
import ALGORITHM_SAMPLES from '../algorithm-samples';
import DP_SAMPLES from '../dp-samples';
import DS_SAMPLES from '../ds-samples';
import TASK_SAMPLES from '../task-samples';

@Component({
  selector: 'app-pane-manager',
  templateUrl: './pane-manager.component.html',
  styleUrls: ['./pane-manager.component.scss']
})
export class PaneManagerComponent implements OnInit {

    subtask: string = '';
    step: string = '';
    stepList: any[] = [];
    langs: any;
    panes: any[] = [];
    group:string = '';
    overviewOpen: boolean = true;
    samples: any;
    info: any;
    openPanes: any = {};

    explorerClass: string = 'pane-manager__explorer';
    explorerButtonClass: string = 'pane-manager__explorerButton';
    pseudocoderClass: string = 'pane-manager__pseudocoder';
    pseudocoderButtonClass: string = 'pane-manager__pseudocoderButton';
    scrollBoxWidth: any = { width: 'calc(100% - 600px)' };

    constructor(
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {

        this.subtask = this.route.snapshot.params.task;
        this.group = this.route.snapshot.params.group;

        switch (this.group) {
            case 'data-structures':
                this.samples = DS_SAMPLES;
                break;
            case 'algorithms':
                this.samples = ALGORITHM_SAMPLES
                break;
            case 'problems':
                this.samples = PROBLEM_SAMPLES;
                break;
            case 'design-patterns':
                this.samples = DP_SAMPLES;
                break;
            case 'tasks':
                this.samples = TASK_SAMPLES;
                break;
            default:
                return
        }

        this.stepList = this.samples[this.subtask].stepList;
        this.info = this.samples[this.subtask].info;
        this.langs = this.buildLanguageOptions(this.samples[this.subtask].languages);
    }



    buildLanguageOptions(languages: string[]): any[] {
        return languages.map((language) => {
            return { key: language, label: LANG_MAP[language].LABEL  }
        })
    }

    backwards() {
        const currentIndex = this.stepList.findIndex(o => o.key === this.step);
        if (currentIndex > 0) {
            this.step = this.stepList[currentIndex - 1].key;
        } 
    }

    forwards() {
        const currentIndex = this.stepList.findIndex(o => o.key === this.step);
        if (currentIndex < (this.stepList.length - 1)) {
            this.step = this.stepList[currentIndex + 1].key;
        } 
    }

    moveToStep(step: string) {
        const currentIndex = this.stepList.findIndex(o => o.key === step);
        if (currentIndex > -1) {
            this.step = this.stepList[currentIndex].key;
        } else {
            this.step = ''
        }
    }

    isStepListEmpty() {
        console.log(this.stepList.length > 0)
        return this.stepList.length > 0
    }

    toggleOverview() {
        if (this.overviewOpen) {
            this.overviewOpen = false;
        } else {
            this.overviewOpen = true;
        }
    }

    toggleExplorer() {
        if (this.explorerClass === 'pane-manager__explorer') {
            this.explorerClass = 'pane-manager__explorer--hidden';
            this.explorerButtonClass = 'pane-manager__explorerButton--alt';
        } else {
            this.explorerClass = 'pane-manager__explorer';
            this.explorerButtonClass = 'pane-manager__explorerButton';
        }
        this.checkSrollBoxWidth();
    }

    togglePseudocoder() {
        if (this.pseudocoderClass === 'pane-manager__pseudocoder') {
            this.pseudocoderClass = 'pane-manager__pseudocoder--hidden';
            this.pseudocoderButtonClass = 'pane-manager__pseudocoderButton--alt';
        } else {
            this.pseudocoderClass = 'pane-manager__pseudocoder';
            this.pseudocoderButtonClass = 'pane-manager__pseudocoderButton';
        }
        this.checkSrollBoxWidth();
    }

    checkSrollBoxWidth() {
        let width = 0;
        if (this.pseudocoderClass === 'pane-manager__pseudocoder') {
            width += 500;
        }
        if (this.explorerClass === 'pane-manager__explorer') {
            width += 100;
        }
        this.scrollBoxWidth = { width: `calc(100% - ${width}px)` };

    }

    launchCodePane(lang: any) {
        
        if (this.openPanes[lang.key]) {
            let index = this.panes.findIndex(o => o.key == lang.key);
            this.panes.splice(index, 1);

            delete this.openPanes[lang.key];
        } else {
            this.panes.push({
                key:  lang.key,
                editor: LANG_MAP[lang.key].EDITOR, 
                label: LANG_MAP[lang.key].LABEL,
                content: this.samples[this.subtask][lang.key],
                templates: this.samples[this.subtask].templates
            });
    
            this.openPanes[lang.key] = true;
        }
    }

    deletePane(event: any) {
        if (event.key === 'OVERVIEW') {
            this.toggleOverview();
            return
        }
        let index = this.panes.findIndex(o => o.key == event.key);
        this.panes.splice(index, 1);

        delete this.openPanes[event.key];
    }

}

