export default {

info: `
<div>
    <h1>Binary Search Tree Iterator</h1>
</div>
<div>
    <p>
        Implement an iterator over a binary search tree (BST). 
        Your iterator will be initialized with the root node of a BST.
    </p>
    <p>
        Calling next() will return the next smallest number in the BST.
    </p>
</div>
`,

stepList: [
    {
        key: '1',
        description: `
            We will create a class with a constructor that takes in the
            BST's root node. Two key parts in the constructor is initializing
            a class attribute that functions as a stack and calling a class 
            utility method with the root node. 
        `,
    },
    {
        key: '2',
        description: `
            The purpose of the stack utility method is to always push the left
            (smaller) nodes onto the stack. Note that it is recursive and always
            receives left nodes. Append the current node onto the stack and 
            reassign the current node to the current node's left pointer.
        `,
    },
    {
        key: '3',
        description: `
            Iterators always have an implementation of the "next" method that returns
            the next element in the iterator.
        `,
    },
    {
        key: '4',
        description: `
            We will pop the top element from the stack and put it inside a current node
            variable. 
        `,
    },
    {
        key: '5',
        description: `
            To ensure that the stack always has the smallest element at the top, if the current
            node has a right child node, we want all of its smaller nodes inside the stack.
        `,
    },
    {
        key: '6',
        description: `
            Then we just return out the current node which is currently the smallest.
        `,
    },
],

templates: {
    PYTHON: {
        '1': [4,5,6],
        '2': [16,17,18,19],
        '3': [11],
        '4': [12],
        '5': [13],
        '6': [14]
    },
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class BSTIterator:

    def __init__(self, root):
        self.stack = []
        self.push_all(root)

    def has_next(self):
        return self.stack

    def next(self):
        current_node = self.stack.pop()
        self.push_all(current_node.right)
        return current_node.value
        
    def push_all(self, node):
        while node is not None:
            self.stack.append(node)
            node = node.left
`,

JAVA: `

`,
    
}