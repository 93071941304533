export default {

info: `
<div>
    <h1>Heap Sort</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def heap_sort(arr):
    arr_len = len(arr)
    
    for i in range(arr_len, -1, -1):
        heapify(arr, arr_len, i)
        
    for i in range(arr_len - 1, 0, -1):
        swap(arr, 0, i)
        heapify(arr, i, 0)
    
    return arr

def heapify(arr, n, i): 
    largest = i
    left_child_index = (2 * i) + 1
    right_child_index = (2 * i) + 2

    if left_child_index < n and arr[i] < arr[left_child_index]: 
        largest = left_child_index 

    if right_child_index < n and arr[largest] < arr[right_child_index]: 
        largest = right_child_index 

    if largest != i:
        swap(arr, largest, i)

        heapify(arr, n, largest)

def swap(arr, index_one, index_two):
    arr[index_one], arr[index_two] = arr[index_two], arr[index_one]
`,

JAVA: `

`,
    
}