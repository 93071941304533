export default {

info: `
<div>
    <h1>Kadane's Algorithm</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def kadanes(nums):
    max_so_far = nums[0]
    max_ending_here = nums[0]
    
    current_start = 0
    max_region_start = -1
    max_region_end = -1
    
    for i in range(1, len(nums)):
        
        max_ending_here += nums[i]
        
        if max_ending_here < 0:
            max_ending_here = 0
            current_start = i + 1
            
        if max_ending_here > max_so_far:
            max_region_start = current_start
            max_region_end = i
            max_so_far = max_ending_here

    # max_region_start
    # max_region_end
    
    return max_so_far
`,

JAVA: `

`,
    
}