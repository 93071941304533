export default {

info: `
<div>
    <h1>Two Missing Numbers</h1>
</div>
<div>
    <p>
        Given an array containing all the numbers from 1 
        to n except two, find the two missing numbers.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
def two_missing(arr):
    
    arr_len = len(arr)
    complete_len = arr_len + 2
    
    total_sum = complete_len * (complete_len + 1) // 2
    
    arr_sum = 0
    
    for val in arr:
        arr_sum += val
        
    pivot = (total_sum - arr_sum) // 2
    
    total_left_xor = 0
    arr_left_xor = 0
    total_right_xor = 0
    arr_right_xor = 0
    
    for i in range(1, pivot + 1):
        total_left_xor ^= i
        
    for i in range(pivot + 1, complete_len + 1):
        total_right_xor ^= i
        
    for val in arr:
        if val <= pivot:
            arr_left_xor ^= val
        else:
            arr_right_xor ^= val
            
    return [total_left_xor ^ arr_left_xor, total_right_xor ^ arr_right_xor]
`,

JAVA: `

`,
    
}