export default {

info: `
<div>
    <h1>Number of Islands</h1>
</div>
<div>
    <p>
        Given a 2d grid map of '1's (land) and '0's (water), count 
        the number of islands. An island is surrounded by water and 
        is formed by connecting adjacent lands horizontally or vertically. 
        You may assume all four edges of the grid are all surrounded by water.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['CPLUSPLUS', 'GO'],

PYTHON: `

`,

JAVA: `

`,

CPLUSPLUS: `
#include <vector>

class Solution {
public:

    int NumIslands(std::vector<std::vector<char>>& area)
    {
        int result = 0;

        for (int row = 0; row < area.size(); ++row)
        {
            for (int col = 0; col < area[row].size(); ++col)
            {

                if (area[row][col] == '1')
                {
                    result++;
                    Explore(area, row, col);
                }
            }

        }

        return result;
    }

    void Explore(std::vector<std::vector<char>>& area, int row, int col)
    {

        if (!ValidExploration(area, row, col))
            return;

        area[row][col] = 'X';

        Explore(area, row + 1, col);
        Explore(area, row - 1, col);
        Explore(area, row, col + 1);
        Explore(area, row, col - 1);

    }

    bool ValidExploration(std::vector<std::vector<char>>& board, int row, int col)
    {
        if (row < 0 || row >= board.size())
            return false;

        if (col < 0 or col >= board[row].size())
            return false;

        if (board[row][col] != '1')
            return false;

        return true;
    }
}
`,

GO: `
func numIslands(grid [][]byte) int {
    
    result := 0
    
    for row := 0; row < len(grid); row++ {
        for col := 0; col < len(grid[row]); col++ {
            if grid[row][col] == '1' {
                result += 1
                depthFirstSearch(row, col, grid)
            }   
        }
    }
    
    return result
}

func depthFirstSearch(row int, col int, grid [][]byte) {
    
    
    if !isValid(row, col, grid) {
        return
    }
    
    grid[row][col] = '.'
    
    depthFirstSearch(row + 1, col, grid)
    depthFirstSearch(row - 1, col, grid)
    depthFirstSearch(row, col + 1, grid)
    depthFirstSearch(row, col - 1, grid)
    
}

func isValid(row int, col int, grid [][]byte) bool {
    
    if row < 0 || row >= len(grid) {
        return false
    }
    
    if col < 0 || col >= len(grid[row]) {
        return false
    }
    
    if grid[row][col] != '1' {
        return false
    }
    
    return true
}
`
    
}