export default {

info: `
<div>
    <h1>Flyweight Design Pattern</h1>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class FlyweightFactory:

    def __init__(self):
        self.flyweight_cache = {}

    def get_flyweight(self, key):
        if key in self.flyweight_cache:
            flyweight = self.flyweight_cache[key]
        else:
            flyweight = Flyweight()
            self.flyweight_cache[key] = flyweight

        return flyweight

class Flyweight:

    def __init__(self):
        self.immutable_intrinsic_state = None

    def operation(self, extrinsic_state):
        pass
`,

JAVA: `

`,
    
}