

import { LANG } from './lang';

export default [
    {
        title: 'Add Binary',
        url: 'add_binary',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Add Numbers Without a Plus',
        url: 'add_numbers_without_plus',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS,
            LANG.JAVA,
            LANG.JAVASCRIPT
        ]
    },
    {
        title: 'Alien Dictionary',
        url: 'alien_dictionary',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'All Nodes Distance K BST',
        url: 'all_nodes_distance_k_bst',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Backspace String Compare',
        url: 'backspace_string_compare',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS
        ]
    },
    {
        title: 'Battleships in a Board',
        url: 'battleships_in_a_board',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS,
            LANG.KOTLIN
        ]
    },
    {
        title: 'Best Time to Buy and Sell Stock',
        url: 'best_time_to_buy_and_sell_stock',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS
        ]
    },
    {
        title: 'Binary Search Tree Iterator',
        url: 'binary_search_tree_iterator',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Binary Tree Longest Consecutive Sequence',
        url: 'binary_tree_longest_consecutive_sequence',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Binary Tree Maximum Path Sum',
        url: 'binary_tree_maximum_path_sum',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Clone a Linked List',
        url: 'clone_a_linked_list',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Clone Graph',
        url: 'clone_graph',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Combination Sum II',
        url: 'combination_sum_II',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Combination Sum',
        url: 'combination_sum',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS
        ]
    },
    {
        title: 'Compute Valid IPs From String',
        url: 'compute_valid_ips_from_string',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Container With Most Water',
        url: 'container_with_most_water',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Continuous Subarray Sum',
        url: 'continuous_subarray_sum',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Convert BST to Sorted Doubly Linked List',
        url: 'convert_bst_to_sorted_doubly_linked_list',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Decode String',
        url: 'decode_string',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Decode Ways',
        url: 'decode_ways',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Divide Two Integers',
        url: 'divide_two_integers',
        langs: [
            LANG.PYTHON
        ]
    },
    {
        title: 'Edit Distance',
        url: 'edit_distance',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Expression Add Operators',
        url: 'expression_add_operators',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Fewest Coins to Make Change',
        url: 'fewest_coins_to_make_change',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Find First and Last Position of Element in Sorted Array',
        url: 'find_frst_and_last_position',
        langs: [
            LANG.CPLUSPLUS,
            LANG.SCALA
        ]
    },
    {
        title: 'Find Missing',
        url: 'find_missing',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'First Bad Version',
        url: 'first_bad_version',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Flatten Nested List Iterator',
        url: 'flatten_nested_list_iterator',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Frog Jump',
        url: 'frog_jump',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Fruit Into Baskets',
        url: 'fruit_into_baskets',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Generate All Permutations of an Array',
        url: 'generate_all_permutations_array',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Generate All Permutations of a String',
        url: 'generate_all_permutations_string',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Generate Parantheses',
        url: 'generate_parentheses',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Group Anagrams',
        url: 'group_anagrams',
        langs: [
            LANG.PYTHON,
            LANG.CSHARP
        ]
    },
    {
        title: 'House Robber',
        url: 'house_robber',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Integer to English Words',
        url: 'integer_to_english_words',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Invalid Transactions',
        url: 'invalid_transactions',
        langs: [
            LANG.CPLUSPLUS
        ]
    },
    {
        title: 'Is Nth Bit Set',
        url: 'is_nth_bit_set',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Is Number Even or Odd',
        url: 'is_number_even_or_odd',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: '0/1 Knapsack Problem',
        url: 'knapsack_problem',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Kth Largest or Smallest Element',
        url: 'kth_largest_or_smallest_element',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'K Closest Points',
        url: 'k_closest_points',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'K Sum',
        url: 'k_sum',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Letter Combinations',
        url: 'letter_combinations',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Level Order Tree Traversal',
        url: 'level_order_tree_traversal',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'License Key Formatting',
        url: 'license_key_formatting',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Linked List Cycle',
        url: 'linked_list_cycle',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Longest Common Sequence',
        url: 'longest_common_sequence',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Longest Increasing Subsequence',
        url: 'longest_increasing_subsequence',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Longest Palindromic Substring',
        url: 'longest_palindromic_substring',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Longest Substring Without Repeating Characters',
        url: 'longest_substring_without_repeating_characters',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Lowest Common Ancestor',
        url: 'lowest_common_ancestor',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'LRU Cache',
        url: 'lru_cache',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Maximal Rectangle',
        url: 'maximal_rectangle',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Maximum Depth of Binary Tree',
        url: 'maximum_depth_of_binary_tree',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Max Rectangle in Histogram',
        url: 'max_rectangle_in_histogram',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Max Sub Array',
        url: 'max_sub_array',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Max Sum Rectangle',
        url: 'max_sum_rectangle',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Median of Two Sorted Arrays',
        url: 'median_of_two_sorted_arrays',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS
        ]
    },
    {
        title: 'Meeting Rooms 2',
        url: 'meeting_rooms_2',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Merge k Sorted Lists',
        url: 'merge_k_sorted_lists',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Minimum Window Substring',
        url: 'minimum_window_substring',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Monotonic Array',
        url: 'monotonic_array',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Move Zeroes',
        url: 'move_zeroes',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Multiply Strings',
        url: 'multiply_strings',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Next Permutation',
        url: 'next_permutation',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Number of Islands',
        url: 'number_of_islands',
        langs: [
            LANG.CPLUSPLUS,
            LANG.GO
        ]
    },
    {
        title: 'N Queens',
        url: 'n_queens',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Next Closest Time',
        url: 'next_closest_time',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Nth Highest Salary',
        url: 'nth_highest_salary',
        langs: [
            LANG.SQL_ORACLE,
            LANG.SQL_MS,
            LANG.SQL_MYSQL
        ]
    },
    {
        title: 'Odd Even Jump',
        url: 'odd_even_jump',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Palindrome Partitioning',
        url: 'palindrome_partitioning',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Partition Labels',
        url: 'partition_labels',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Partition to K Equal Sum Subsets',
        url: 'partition_to_k_equal_sum_subsets',
        langs: [
            LANG.PYTHON
        ]
    },
    {
        title: 'Path Sum II',
        url: 'path_sum_II',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Pattern Matching',
        url: 'pattern_matching',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Peak Element',
        url: 'peak_element',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Pow(x,n)',
        url: 'pow_xn',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Product of Array Except Self',
        url: 'product_of_array_except_self',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Ransom Note',
        url: 'ransom_note',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Read N Characters Given read4',
        url: 'read_n_characters_given_read4',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Regular Expression Matching',
        url: 'regular_expression_matching',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Remove Duplicates of Sorted Array',
        url: 'remove_duplicates_of_sorted_array',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Remove Invalid Parantheses',
        url: 'remove_invalid_parantheses',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Remove Nth Node From End of List',
        url: 'remove_nth_node_from_end_of_list',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Reverse Integer',
        url: 'reverse_integer',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Reverse Singly Linked List',
        url: 'reverse_singly_linked_list',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Robot Room Cleaner',
        url: 'robot_room_cleaner',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Rotate Image',
        url: 'rotate_image',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Search in Rotated Sorted Array',
        url: 'search_in_rotated_sorted_array',
        langs: [
            LANG.PYTHON,
            LANG.GO,
            LANG.SCALA
        ]
    },
    {
        title: 'Search in Sorted 2D Matrix',
        url: 'search_sorted_2d_matrix',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Serialize / Deserialize BST',
        url: 'serialize_deserialize_bst',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Shortest Distance From All Buildings',
        url: 'shortest_distance_from_all_buildings',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Skyline',
        url: 'skyline',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Sort Characters by Frequency',
        url: 'sort_characters_by_frequency',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Sorted Merge',
        url: 'sorted_merge',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS
        ]
    },
    {
        title: 'Sort K Sorted Array',
        url: 'sort_k_sorted_array',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Split Array Largest Sum',
        url: 'split_array_largest_sum',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Subarray Sum Equals K',
        url: 'subarray_sum_equals_k',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Subarray Sums Divisible by K',
        url: 'subarray_sums_divisible_by_k',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Subsets',
        url: 'subsets',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Sudoku Solver',
        url: 'sudoku_solver',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Symmetric Tree',
        url: 'symmetric_tree',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Task Scheduler',
        url: 'task_scheduler',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Three Sum',
        url: 'three_sum',
        langs: [
            LANG.PYTHON,
            LANG.CPLUSPLUS,
            LANG.CSHARP
        ]
    },
    {
        title: 'Toggle Nth Bit',
        url: 'toggle_nth_bit',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Total Occurances of K in Sorted Array',
        url: 'total_occurances_of_k_in_sorted_array',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Total Unique Ways to Make Change',
        url: 'total_unique_ways_to_make_change',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Trapping Rain Water',
        url: 'trapping_rain_water',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Two Missing Numbers',
        url: 'two_missing_numbers',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Two Sum',
        url: 'two_sum',
        langs: [
            LANG.PYTHON,
            LANG.SWIFT
        ]
    },
    {
        title: 'Unique Paths',
        url: 'unique_paths',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Valid IP Address',
        url: 'valid_ip_address',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Valid Parantheses',
        url: 'valid_parentheses',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Walls and Gates',
        url: 'walls_and_gates',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Word Search',
        url: 'word_search',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Zero Sum',
        url: 'zero_sum',
        langs: [
            LANG.PYTHON,
        ]
    },
    {
        title: 'Zig-Zag Conversion',
        url: 'zigzag_conversion',
        langs: [
            LANG.PYTHON,
        ]
    },
]