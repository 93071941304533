export default {

info: `
<div>
    <h1>Max Sum Rectangle</h1>
</div>
<div>
    <p>
        Given a non-empty 2D matrix matrix and an integer k, find the max 
        sum of a rectangle in the matrix such that its sum is no larger than k.
    </p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
class Rectangle:
    
    def __init__(self, matrix):
        self.matrix = matrix
        self.max_sum = 0
        self.left_border_index = 0
        self.right_border_index = 0
        self.top_border_index = 0
        self.bottom_border_index = 0
    
    def set_state(self, max_sum, left, right, top, bottom):
        self.max_sum = max_sum
        self.left_border_index = left
        self.right_border_index = right
        self.top_border_index = top
        self.bottom_border_index = bottom
        
    def result(self):
        result = []
        for row in range(self.top_border_index, self.bottom_border_index + 1):
            row_result = []
            for col in range(self.left_border_index, self.right_border_index + 1):
                row_result.append(self.matrix[row][col])
            result.append(row_result)
        return result

def max_sum_rectangle(matrix):
    
    row_count = len(matrix)
    col_count = len(matrix[0])
    
    running_row_sums = [0 for i in range(0, row_count)]
    rectangle = Rectangle(matrix)
    
    for left_bound in range(0, col_count):
        
        for i in range(0, row_count):
            running_row_sums[i] = 0
            
        for right_bound in range(left_bound, col_count):
            
            for i in range(row_count):
                running_row_sums[i] += matrix[i][right_bound]
            
            max_sum, top_index, bottom_index = kadane_result(running_row_sums)
            
            if max_sum > rectangle.max_sum:
                rectangle.set_state(
                    max_sum=max_sum, 
                    left=left_bound, 
                    right=right_bound, 
                    top=top_index, 
                    bottom=bottom_index
                )
                
    return rectangle.result()
    
def kadane_result(nums):
    max_so_far = nums[0]
    max_ending_here = nums[0]
    
    current_start = 0
    max_region_start = -1
    max_region_end = -1
    
    for i in range(1, len(nums)):
        
        max_ending_here += nums[i]
        
        if max_ending_here < 0:
            max_ending_here = 0
            current_start = i + 1
            
        if max_ending_here > max_so_far:
            max_region_start = current_start
            max_region_end = i
            max_so_far = max_ending_here
    
    return (max_so_far, max_region_start, max_region_end)
`,

JAVA: `

`,
    
}