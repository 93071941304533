<div class="pseudocoder">
    <div class="pseudocoder__stepContainer">
        <div 
            class="pseudocoder__step" 
            *ngFor="let group of stepList"
            (mouseenter)="onHoverMoveToStep(group.key)"
            (mouseleave)="onHoverMoveToStep('')"
            (click)="onMoveToStep(group.key)">
            <span class="dot" *ngIf="step === group.key"></span>
            <span>
                {{ group.description }}
            </span>
        </div>
    </div>
</div>

