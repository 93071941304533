export default {

info: `
<div>
    <h1>Meeting Rooms 2</h1>
</div>
<div>
    <p>
        Given an array of meeting time intervals consisting of 
        start and end times [[s1,e1],[s2,e2],...] (si < ei), find the 
        minimum number of conference rooms required.
    </p>
    <p>Input: [[0, 30],[5, 10],[15, 20]]</p>
    <p>Output: 2</p>
</div>
`,

stepList: [],

templates: {
    PYTHON: {},
    JAVA: {}
},

languages: ['PYTHON'],

PYTHON: `
import heapq

def meeting_rooms(times):
    
    if not times:
        return 0
    
    free_rooms = []

    sorted_times = sorted(times, key = lambda x: x[0])

    heapq.heappush(free_rooms, sorted_times[0][1])

    for block in sorted_times[1:]:
        if free_rooms[0] <= block[0]:
            heapq.heappop(free_rooms)

        heapq.heappush(free_rooms, block[1])

    return len(free_rooms)
`,

JAVA: `

`,
    
}